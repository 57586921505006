import React from 'react';
// import TShirt from './core/components/TShirt';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import axios from 'axios';
import {useCart, addToCart } from '../contexts/OldCartContext.js';
import SelectProductQuantity from './SelectProductQuantity.js';
import AddToCartModal from './AddToCartModal.js';
import Alert from 'react-bootstrap/Alert';
import './ProductCard.css';
import { useLocale } from '../contexts/LocaleContext.js';
import {FormattedNumber} from 'react-intl';
import { useShop } from '../contexts/ShopContext.js';
import { changeCart } from '../contexts/ShopContext.js';
import { Link } from 'react-router-dom';
import { CustomProduct } from './CustomProducts.js';


export function ProductCard(props) {
    const shopContext = useShop();
    const item = props.item;
    const allProducts = shopContext.shop.productById;
    const product = allProducts[item.id];

    const customProduct = product.colour;
    const title = product.title || "My OilOnShirt";  // TODO pull from base?

    const { currency } = useLocale();

    // const base = product.base;
    const price = product.price * currency.rate;

    const handleChange = (newQty) => changeCart(shopContext, item.id, item.colour, item.size, newQty);

    const handleRemove = () => handleChange(0);

    return (
        <Container fluid>
            <Card bg='transparent' className='my-2' style={{border:"none"}}>
                <Row>
                    <Col lg={6} md={12}>
                        {customProduct ? <CustomProduct product={product} colour={item.colour} />
                                : <CatalogProduct product={product} colour={item.colour} />}
                    </Col>
                    <Col lg={6} md={12}>
                        <Card.Body>
                            <Card.Title className='ProductCard-text'>{title}</Card.Title>
                            <Card.Text className='ProductCard-text'>
                                Price: <FormattedNumber
                                        value={price}
                                        style="currency"
                                        currencyDisplay="symbol"
                                        currency={currency.symbol}
                                        /><br/>
                                Size: {item.size}
                            </Card.Text>
                            <SelectProductQuantity qty={item.qty} onChange={handleChange} onRemove={handleRemove}/>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
};

function CatalogProduct(props) {
    const product = props.product;
    const colour = props.colour;
    const image = product.colours[colour][0];  // TODO handle backprint
    return (
        <Link to={`/product/${product.slug}?colour=${colour}`}>
            <img className="hover-zoom" style={{ width: "100%" }} src={image} />
        </Link>
    )
}

export function ProductCardOrder(props) {
    const item = props.item;
    const product = props.product;  // require local state

    const customProduct = product.colour;
    const title = product.title || "My OilOnShirt";  // TODO pull from base?

    const { currency } = useLocale();

    // const base = product.base;
    const price = product.price * currency.rate;

    return (
        <Container fluid>
            <Card bg='transparent' className='my-2' style={{border:"none"}}>
                <Row>
                    <Col lg={6} md={12}>
                        {customProduct ? <CustomProduct product={product} colour={item.colour} />
                            : <CatalogProduct product={product} colour={item.colour} />}
                    </Col>
                    <Col lg={6} md={12}>
                        <Card.Body>
                            <Card.Title className='ProductCard-text'>{title}</Card.Title>
                            <Card.Text className='ProductCard-text'>
                                Price: <FormattedNumber
                                        value={price}
                                        style="currency"
                                        currencyDisplay="symbol"
                                        currency={currency.symbol}
                                        /><br/>
                                Size: {item.size}<br />
                                Quantity: {item.qty}
                            </Card.Text>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </Container>
    )
};

export function ProductCardFav(props) {
    const {dispatch} = useCart();
    const {currency} = useLocale();

    const product = props.product;
    const base = product.base;
    const price = base.price * currency.rate;

    const [showModal, setShowModal] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleRemove = () => axios({
        method: "delete",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/products/${product.id}`,
    })
    .then((res) => {
        console.log(res);
        dispatch({type: 'REMOVE_PRODUCT', payload: res.data});
    })
        .catch((res) => console.log(res))

    const handleAdd = (sz) => addToCart(dispatch, sz, product);

    return (
        <>
            <Container fluid className='my-2 ProductCard-tabs'>
                <Col>
                    {/* <TShirt product={base}>
                        <img className={`Canvas-${base.canvas}`} src={product.image}/>
                    </TShirt> */}
                </Col>
                <Col>
                    <Card.Body>
                        <Card.Title className='ProductCard-text'>{base.title}</Card.Title>
                        <Card.Text className='ProductCard-text-white'>Price: <FormattedNumber
                            value={price}
                            style="currency"
                            currencyDisplay="symbol"
                            currency={currency.symbol}/>
                        </Card.Text>
                        <Button
                            variant='outline-light'
                            onClick={handleShow}
                            className="my-4 buttonStyle-lavender">
                            Add To Basket
                        </Button>
                        <br />
                        {!showAlert && <Button onClick={() => setShowAlert(true)}variant="outline-danger" className='buttonStyle-lavender'>Delete</Button>}
                    </Card.Body>

                    <Alert show={showAlert} variant="danger" className='my-3 mx-2 ProductAlert'>
                        <Alert.Heading>Are you sure?</Alert.Heading>
                        <p>
                        Deleting from your favourites will also delete it from your cart.
                        </p>
                        <hr />
                        <div className="d-flex justify-content-center">
                        <Button
                            onClick={handleRemove}
                            size="sm"
                            variant='outline-danger'
                            className='my-2 mx-1 buttonStyle-black'>
                            Yes, delete.
                        </Button>
                        <Button
                            onClick={() => setShowAlert(false)}
                            size="sm"
                            variant="outline"
                            className='my-2 mx-1 buttonStyle-black'>
                            Cancel
                        </Button>
                        </div>
                    </Alert>
                </Col>
                <AddToCartModal product={base} show={showModal} onSave={handleAdd} onClose={handleClose}/>
            </Container>
        </>
    )
};