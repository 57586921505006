import React from 'react';
import './PaintingLoader.css';
import './AiTool.css';

export default function PaintingLoader() {
    return (
        <div className="mx-4 my-2 loading loading01">
            <span className="">P</span>
            <span className="">A</span>
            <span className="">I</span>
            <span className="">N</span>
            <span className="">T</span>
            <span className="">I</span>
            <span className="">N</span>
            <span className="">G</span>
        </div>
    )
};
