import { useState, useRef, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Row, Col, Button } from "react-bootstrap";
import "./ScrollInEffect.css";
import Values from "./Values";
import NavigationOffcanvas from './NavigationOffCanvas';

export default function Home() {

    const navigate = useNavigate();

    const ITEMS = [
        {ref: useRef(null), intersecting: useState(false), threshold:"0.5"},
        {ref: useRef(null), intersecting: useState(false), threshold:"0.7"},
        {ref: useRef(null), intersecting: useState(false), threshold:"0.7"},
    ];


    useEffect(() => {
        const observers = ITEMS.map((item) => {
            const observer = new IntersectionObserver(
                ([entry]) => {
                    item.intersecting[1](entry.isIntersecting);
                },
                { threshold : item.threshold }
                // { rootMargin: item.rootMargin }
            );
            observer.observe(item.ref.current);
            return observer;
        });
        return () => observers.map((obs) => obs.disconnect());
    }, []);

    return (
        <>
            <div style={{height:"1vh"}}/>
            <Container>
                <Row style={{backgroundColor:"#dcd6f750", borderRadius: "1rem", padding:"1.5%"}}>
                    <Col md={4}>
                        <Link to="/collection/men">
                            <section role="contentinfo" aria-label="Organic cotton clothing for men with custom prints" id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"/banner_cliff_standing.jpeg"}`}}>
                                <Container className="Home-background-color">
                                    <Row className="Home-column-height-m align-items-start justify-content-end">
                                        <Button variant="" onClick={() => navigate("/collection/men")} className='textEmboss' style={{margin:"3%", width:"max-content", fontWeight:"600", fontFamily:"Aladin", fontSize:"calc(10px + 2vw)", overflow:"hidden", color:"#8d6e63"}}>MEN</Button>
                                    </Row>
                                </Container>
                            </section>
                        </Link>
                    </Col>
                    <Col md={4}>
                        <Link to="/collection/women">
                            <section role="contentinfo" aria-label="Organic cotton clothing for women with custom prints" id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"/women_sweater_backprint.jpeg"}`}}>
                                <Container className="Home-background-color">
                                    <Row className="Home-column-height-m align-items-start justify-content-end">
                                        <Button variant="" onClick={() => navigate("/collection/women")} className='textEmboss' style={{margin:"3%", width:"max-content", fontWeight:"600", fontFamily:"Aladin", fontSize:"calc(10px + 2vw)", overflow:"hidden", color:"#8d6e63"}}>WOMEN</Button>
                                    </Row>
                                </Container>
                            </section>
                        </Link>
                    </Col>
                    <Col md={4}>
                        <Link to="/collection/kids">
                            <section role="contentinfo" aria-label="Organic cotton clothing for kids with custom prints" id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"/kids_sweater.jpeg"}`}}>
                                <Container className="Home-background-color">
                                    <Row className="Home-column-height-m align-items-start justify-content-end">
                                        <Button variant="" onClick={() => navigate("/collection/kids")} className='textEmboss' style={{margin:"3%", width:"max-content", fontWeight:"600", fontFamily:"Aladin", fontSize:"calc(10px + 2vw)", overflow:"hidden",color:"#8d6e63"}}>KIDS</Button>
                                    </Row>
                                </Container>
                            </section>
                        </Link>
                    </Col>
                </Row>
            </Container>

            <div style={{height:"5vh"}}/>

            <Link to="/studio">
                <section role="contentinfo" aria-label="Custom ai art clothing with nature prints" ref={ITEMS[0].ref} id="HomeSection0" className="" style={{backgroundImage: `url(${"/yosemite_landscape_banner.webp"}`}}>
                        <div className={ITEMS[0].intersecting[0] ? "slide-down" : "slide-up"}>
                            <Button variant="" className="HeroImage-design-button2" style={{color:"#ffeadb"}}>
                                <span style={{fontSize:"calc(10px + 3vw)", fontWeight:"600", fontFamily:"Aladin"}}>n<span className="textOutline">ai</span>ture</span>
                                <br />
                                <span style={{fontSize:"calc(10px + 2vw)", fontWeight:"600"}}>is calling</span>
                                {/* <div className="textOutline-dark" style={{fontFamily:"Aladin", fontSize:"calc(10px + 4.5vw)"}}>n<span style={{color:"#8d6e63"}}>ai</span>ture</div>
                                <div style={{fontSize:"calc(10px + 2vw)", fontWeight:"500"}}>is calling</div> */}
                            </Button>
                            {/* <div className="textOutline-dark" style={{fontFamily:"Aladin", fontSize:"calc(10px + 4.5vw)"}}>naiture is calling</div> */}
                        </div>
                </section>
            </Link>

            <Col xs={{span: 8, offset: 2}} sm={{span: 4, offset: 4}} ref={ITEMS[1].ref}>
                <div className={ITEMS[1].intersecting[0] ? "slide-in my-4" : "slide-out my-4"}>
                    <h1 style={{color:"#8d6e63", fontSize:"calc(10px + 3vw)", fontWeight:"600"}}>Imagine your Print</h1>
                    {/* <h1 className="my-4 textOutline" style={{color:"#8d6e63",fontFamily:"Aladin", fontSize:"calc(30px + 4vw)"}}>Imagine your prints.</h1> */}
                    <h2>With Text to Image AI</h2>
                </div>
            </Col>
            <section role="contentinfo" aria-label="Organic cotton clothing with ai art print" id="HomeSection0" style={{backgroundImage: `url(${"banner_cliff_standing.jpeg"}`}}>
                <Container className="my-2 Home-background-color" />
            </section>

            <section role="contentinfo" aria-label="Organic cotton clothing with ai art print" id="HomeSection0" style={{backgroundImage: `url(${"women_sweater_backprint.jpeg"}`}}>
                <Container className="my-2 Home-background-color" />
            </section>
            <Col md={{span: 8, offset: 2}} ref={ITEMS[2].ref}>
                <h1 className={ITEMS[2].intersecting[0] ? "slide-in my-4" : "slide-out my-4"} style={{ transform: "translate(-100vw)", color:"#8d6e63", fontSize:"calc(10px + 3vw)", fontWeight:"600"}}>Organic, Custom, Unique.</h1>
            </Col>
        </>
    )
};