import React, { useState } from 'react';
import { productsSVG, bagSVG} from './SVGs.js';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
// import SelectProduct from './SelectProduct';
import { TabContent, TabNavItem } from './TabContent';
import { Col, Row } from 'react-bootstrap';
import './SelectProductType.css';
import { useCart } from '../contexts/OldCartContext.js';
import Sizing from './Sizing.js';
// import { MEN_PRODUCTS, WOMEN_PRODUCTS, KIDS_PRODUCTS } from '../const.js';
import { useStudio } from '../contexts/StudioContext.js';
import { useShop } from '../contexts/ShopContext';

export default function SelectProductType(props) {
    const { show, setShow } = props;
    const { dispatch } = useStudio();
    const { shop } = useShop();

    const basics = shop.basics;

    const MEN_PRODUCTS = basics.filter(p => p.gender == 'male' && p.agegroup == 'adults');
    const WOMEN_PRODUCTS = basics.filter(p => p.gender == 'female' && p.agegroup == 'adults');
    const KIDS_PRODUCTS = basics.filter(p => p.agegroup == 'kids');

    const handleSelect = (base) => {
        dispatch({ type: 'CHANGE_BASE', payload: base });
        setShow(false);
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [activeTab, setActiveTab] = useState("men");

    const mapProduct = (product) =>
        <Col key={product.base} xs={6}>
            <Button onClick={() => handleSelect(product.base)} variant=''>
                <img id="image" className="hover-zoom-light Collection-images" src={product.images[0]} alt={product.title}/>
                <div className="Collection-catalogue-text">{product.title} | £{product.price}</div>
            </Button>
        </Col>;

    return (
        <>
            <Button variant="" onClick={handleShow} className="my-2 mx-1 buttonStyle-accent">{productsSVG} Products</Button>
            <Offcanvas show={show} onHide={handleClose} style={{background:"", width:"60vw"}}>
                <Offcanvas.Header closeButton style={{color: "#8d6e63"}}>
                    {productsSVG}
                    <Offcanvas.Title>Products</Offcanvas.Title>
                    <Sizing />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className='SelectProductType-text'>All products are made from GOTS certified Organic Cotton.</div>
                    <div className='SelectProductType-text'>Cost for printing £5</div>
                    <div className="SelectProductType-tabs">
                        <ul className="SelectProductType-nav">
                            <TabNavItem title="MEN" id="men" activeTab={activeTab} setActiveTab={setActiveTab}/>
                            <TabNavItem title="WOMEN" id="women" activeTab={activeTab} setActiveTab={setActiveTab}/>
                            <TabNavItem title="KIDS" id="kids" activeTab={activeTab} setActiveTab={setActiveTab}/>
                            {/* <TabNavItem title="ART PRINT" id="art" activeTab={activeTab} setActiveTab={setActiveTab}/> */}
                        </ul>
                        <div className='my-4'>
                            <TabContent id="men" activeTab={activeTab}>
                                <Row style={{width: "58vw"}}>
                                    {MEN_PRODUCTS.map(mapProduct)}
                                </Row>
                            </TabContent>

                            <TabContent id="women" activeTab={activeTab}>
                                <Row style={{width: "58vw"}}>
                                    {WOMEN_PRODUCTS.map(mapProduct)}
                                </Row>
                            </TabContent>

                            <TabContent id="kids" activeTab={activeTab}>
                                <Row style={{width: "58vw"}}>
                                    {KIDS_PRODUCTS.map(mapProduct)}
                                </Row>
                            </TabContent>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

