import { useShop } from '../contexts/ShopContext';
import { ProductDetails } from '../components/ProductDetails';
import { useLocation, useParams } from 'react-router-dom';
import PageTitle from "@oos/core/components/PageTitle";


export default function ProductDetailsPage() {
    const { shop } = useShop();
    const { slug } = useParams();
    const { search } = useLocation();

    const query = new URLSearchParams(search);
    const colour = query.get('colour');

    const product = shop.productBySlug && (
        shop.productBySlug[slug] || shop.productById[slug]
    );

    const title = product ? (product.title || "My OilOnShirt") : "Organic Cotton Product";

    return (
        <PageTitle title={title}>
            <div className='pageHeight my-4'>
                {product ? <ProductDetails product={product} colour={colour} />
                    : <p>Product not found</p>}
            </div>
        </PageTitle>
    )
}