import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";
import './CheckoutPage.css';
import axios from 'axios';
import { Button } from "react-bootstrap";
import CheckoutAddress from '../components/CheckoutAddress'
import CheckoutPayment from '../components/CheckoutPayment'
import OrderConfirmation from '../components/OrderConfirmation'

export default function CheckoutPage() {
    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const paymentIntent = query.get('payment_intent');
    const paymentStatus = query.get('redirect_status');

    const redirectTab = paymentIntent && (paymentStatus.match(/^failed|requires_payment_method$/) ? "payment" : "confirmation") || "address";

    const [activeTab, setActiveTab] = useState(redirectTab);
    const [order, setOrder] = useState(null);

    const changeTab = (tab) => {
        setActiveTab(tab);
        window.scrollTo({top:0, behavior: "smooth"});
    }

    const fetchOrder = () => axios({
        method: "get",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/orders?pi=${paymentIntent}`,
    })

    const createOrder = () => axios({
        method: "post",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/orders`,
    })

    useEffect(() => {
        const req = paymentIntent ? fetchOrder() : createOrder();
        req.then(res => setOrder(res.data)).catch(e => console.log(e));
    }, []);

    const handleOrderDetails = (details) => {
        setOrder({...order, ...details});
        changeTab("payment");
    };

    const handleChangeAddress = () => changeTab("address");

    return (
        <div className="pageHeight Checkout-tabs">
            <h1 className="my-4 Checkout-headline">SECURE CHECKOUT</h1>
            <ul className="Checkout-nav">
                {activeTab==="address" && <>
                    <Button  variant="" className="active buttonStyle-lavender">1. ADDRESS</Button>
                    <li className="disabled"></li>
                    <li className="disabled"></li>
                </>}
                {activeTab==="payment" && <>
                    <li className="buttonStyle-lavender" onClick={handleChangeAddress}>1. ADDRESS</li>
                    <li className="active buttonStyle-lavender">2. PAYMENT</li>
                    <li className="disabled"></li>
                </>}
                {activeTab==="confirmation" && <>
                    <li className="disabled">1. ADDRESS</li>
                    <li className="disabled">2. PAYMENT</li>
                    <li className="active buttonStyle-lavender">3. CONFIRMATION</li>
                </>}
            </ul>
            <Container fluid>
                <Row>
                    {order ? <>
                        {activeTab === "address" && <CheckoutAddress order={order} onUpdate={handleOrderDetails}/>}
                        {activeTab === "payment" && <CheckoutPayment order={order}/>}
                        {activeTab === "confirmation" && <OrderConfirmation order={order}/>}
                    </> : <p>Loading</p>}
                </Row>
            </Container>
        </div>
    )
}