import React from 'react';
import _ from "lodash";
import axios from 'axios';


const ShopContext = React.createContext()


function shopReducer(state, action) {
    // console.log(`executing ${action.type}`);
    switch (action.type) {
        case 'RESET_CART':
            return {
                ...state,
                cart: action.payload,
            }
        case 'RESET_SHOP': {
            const { catalog, ...shop } = action.payload;
            return {
                ...state,
                ...shop,
                ...filterCatalog(catalog),
                productById: Object.fromEntries(shop.custom.concat(catalog).map(p => [p.id, p])),
                productBySlug: Object.fromEntries(catalog.map(p => [p.slug, p])),
            }
        }
        case 'ADD_PRODUCT': {
            const product = action.payload;
            // TODO consider whether ADD should check for duplicates
            const exists = state.custom.findIndex((p) => p.id == product.id);
            return {
                ...state,
                custom: exists < 0 ? [...state.custom, product] : [
                    ...state.custom.slice(0, exists),
                    product,
                    ...state.custom.slice(exists+1),
                ],
                productById: {...state.productById, [product.id]: product}
            }
        }
        case 'REMOVE_PRODUCT':
            return removeProducts(state, new Set([action.payload]));
        case 'REMOVE_PRODUCTS':
            return removeProducts(state, action.payload);
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
}


const removeProducts = (state, pids) => ({
    ...state,
    custom: state.custom.filter((p) => !pids.has(p.id)),
    productById: _.omit(state.productById, pids),
    cart: state.cart.filter((p) => !pids.has(p.id)),
});


export function ShopProvider({children}) {
    const [state, dispatch] = React.useReducer(shopReducer, {});

    React.useEffect(() => {
        // TODO merge requests/reset
        fetch(`${process.env.REACT_APP_BACKEND_URL}/products`, {credentials: 'include'})
            .then(res => res.json())
            .then(data => dispatch({ type: 'RESET_SHOP', payload: data }));
        fetch(`${process.env.REACT_APP_BACKEND_URL}/cart`, {credentials: 'include'})
            .then(res => res.json())
            .then(data => dispatch({type: 'RESET_CART', payload: data}));
    }, []);

    const value = {shop: state, dispatch}
    return <ShopContext.Provider value={value}>{children}</ShopContext.Provider>
}


export function useShop() {
    const context = React.useContext(ShopContext)
    if (context === undefined) {
        throw new Error('useShop must be used within a CartProvider')
    }
    return context
}


function filterCatalog(products) {
    const basics = products.filter(p => p.basic);
    return {
        bases:  Object.fromEntries(basics.map(p => [p.base, p])),
        basics,
        prints: products.filter(p => !p.basic),
    }
}


function postCart(dispatch, change) {
    return axios({
        method: "post",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/cart`,
        data: JSON.stringify({items: change}),
        headers: { "Content-Type": "application/json" },
    })
        .then((res) => {
            console.log(res);
            dispatch({ type: 'RESET_CART', payload: change });
        })
}


export const clearCart = (dispatch) => postCart(dispatch, []);


export function changeCart(context, id, colour, size, qty) {
    const { shop, dispatch } = context;
    const cart = shop.cart;
    const match = (item) => item.id == id && item.colour == colour && item['size'] == size;
    const change = qty > 0 ? cart.map(item => match(item) ? ({ ...item, qty: qty }) : item)
        : cart.filter(item => !match(item));
    return postCart(dispatch, change)
}

export function addToCart(context, id, colour, size, qty = 1) {
    const { shop, dispatch } = context;
    const cart = shop.cart;
    const match = (item) => item.id == id && item.colour == colour && item['size'] == size;
    const exists = cart.filter(item => match(item));
    if (exists.length) {
        return changeCart(context, id, colour, size, exists[0].qty + qty)
    }
    const change = [...cart, { id, colour, size, qty }];
    return postCart(dispatch, change)
}

const itemPrice = (shop, itemId) => shop.productById[itemId].price;

export const summarizeCart = (shop) => (shop.cart && shop.productById) && shop.cart.reduce((acc, item) => ({
    qty: acc.qty + item.qty,
    total: acc.total + item.qty * itemPrice(shop, item.id),
}), { qty: 0, total: 0 });
