import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Card } from 'react-bootstrap';
import './About.css';
import Image from "react-bootstrap/esm/Image";
import Button from 'react-bootstrap/esm/Button';
import HomeArt from '../HomeArt';
import EMailSignup from '../EmailSignup';
import BlogBanner from './BlogBanner';

export default function OrganicCotton () {

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">Why buy ORGANIC Cotton?</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">Less water, free from pesticides and harmful chemicals</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/blog/sustainability">What is GOTS Certified Organic?</Button>
            </div>

            <BlogBanner image="/sustainability_circular_shirt.webp" link="/blog/sustainability" title="Benefits of Organic Cotton over Conventional Cotton"/>

            <h1 className='About-heading'>Organic vs Conventional Cotton</h1>
            <h2 className='About-heading-sm'>3 Reasons to buy organic</h2>


            <p className="About-text">I remember when I was a teenager starting to buy my own clothes. My mum would say to best buy cotton clothing. Yes, generally speaking cotton is a good choice. It is a natural, biodegradable resource whereas conventional polyester is made from finite resources, i.e. fossil fuel based chemicals, and is not considered biodegradable. It is estimated that polyester can take hundreds of years to degrade completely. <a href="#resources"><span>[4, 5]</span></a></p>

            <h2 className='display-6 my-5 Home-title'>BUT, we have to go one important step further.</h2>
            <h2 className='About-heading-sm'>Today’s recommendation needs to be:</h2>
            <p>Choose Organic Cotton over Conventional Cotton.</p>

            <p className="About-text">Cotton is a thirsty crop - it requires 10,000 - 20,000 litres of water to produce one kg of cotton <a href="#resources"><span>[1.2, p.2]</span></a>. Organic cotton uses significantly less water, is free from pesticides and other harmful chemicals and supports safe farming.</p>

            <div className="About-tabs">
                <Link to="/blog/sustainability">
                    <Card style={{border:"none", background:"transparent"}}>
                        <Card.ImgOverlay className='About-text' style={{color:"#ffffff", marginLeft:"70%"}}>
                            <Card.Title>Source: Teemill</Card.Title>
                            <Card.Text>Organic Cotton Farming</Card.Text>
                        </Card.ImgOverlay>
                        <Image src="/teemill_farming.webp" className="my-2 About-tabs-img" fluid alt="benefits of organic cotton farming"></Image>
                    </Card>
                </Link>
                <Button size="lg" variant='' className="mb-5 buttonStyle-accent" as={Link} to="/blog/sustainability">About GOTS Certified Cotton</Button>
            </div>

            <p style={{marginLeft:"15%", marginRight:"15%"}} className='About-text'>"Being a natural fibre, this crop looks so innocent. Isn't it organic anyway? Also, why does it have to be organic in the first place? After all, we don't eat cotton..."</p>

            <p className="About-text">No, we don’t eat cotton, but farmers are harvesting and processing it with their hands, earth is growing it on its soil and people are wearing it on their skin. Conventional cotton farming has detrimental effects that we should be aware of before making purchase decision. So, good that you are here!</p>

            <p className="About-text">The benefits of organic cotton are not necessarily visible to the end user. This makes it easier to ignore - but, let’s shed some light into the dark. Here are three of the main reasons to choose organic cotton clothing over conventional cotton.</p>

            <p className="Home-title">1. Reduced Water Footprint</p>
            <p className="About-text">We know that water scarcity is one of the top global risks. We also know that cotton is a thirsty crop that requires more than 10,000 litres of water to produce one kilogram of conventional cotton. Or, to put it in t-shirt terms, a conventional shirt requires 2,700 litres of water to be made <a href="#resources"><span>[1.1, p.10]</span></a>. Organic cotton on the other hand uses techniques that save significant amounts of water.</p>

            <p style={{margin:"5%"}}>"Organic cotton production practices can reduce water consumption by as much as 91%" <a href="#resources"><span>[2]</span></a>.</p>

            <p className="About-text">Still, organic cotton requires a lot of water. This is why it matters where it is grown. For example our manufacturing partner Teemill has organic cotton fields in the North of India, where farmers benefit from the monsoons that fill reservoirs that supply almost all the water needed. Being rainfed, not irrigated, means that organic cotton isn’t competing with local water resources.</p>

            <p className="Home-title">2. Safer Conditions for Farmers and Garment Workers</p>
            <p className="About-text"> Working with nature instead against it allows organic farmers to use natural techniques to grow healthy crops. Teemill farms for example use co planting and insect traps along with a special ingredient - cow poo. There is no use of toxic hazardous pesticides or artificial fertilisers.</p>

            <p style={{margin:"5%"}}>“Organic cotton benefits human health by eliminating hazardous toxic pesticides from water, soil and air, reducing incidences of poisoning, and illnesses arising from chronic exposure, particularly in low-income countries where access to healthcare is often limited.” <a href="#resources"><span>[1.2, p.2]</span></a></p>

            <p className="About-text">In fact, every year 200,000 tonnes of pesticides and 8 million tonnes of synthetic fertilisers are needed to produce conventional cotton.</p>

            <p style={{margin:"5%"}}>“Long-term or chronic exposure to some of the chemicals and dyes used in the manufacture of cotton have been found to cause cancer and disrupt hormones in both humans and animals.” <a href="#resources"><span>[1]</span></a></p>

            <p className="About-text">However, organic cotton products certified to meet the Global Organic Textiles Standard (GOTS) ensure that only low impact chemicals are used. Toxic heavy metals, formaldehyde, aromatic solvents, nano-particles and anything that’s been genetically modified is banned <a href="#resources"><span>[1, p. 17]</span></a>.  Also, wastewater has to be treated properly before being released. At Teemill, our manufacturing partner, 95% of wastewater is actually recirculated and recovered. The filtered water is crystal clear and in fact in a drinkable condition, before being reused for the next batch.</p>

            <p className="Home-title">3. Be an Advocate for a Healthier Planet and Life</p>

            <p className="About-text">Organic cotton might be on average a bit more expensive than conventionally grown cotton. However, with this choice you invest in water conservation, better soil and fair and safe working conditions for the garment industry. In the UK there are two independent organic certification standards: the Global Organic Textile Standard (GOTS) and the Organic Content Standard (OCS). Look out for certificates such as GOTS, Organic Soil Association or OCS. With the first two, you are guaranteed to buy items made from organic textiles AND under ecologically and socially responsible conditions. The OCS standard verifies that the item is made with organic fibres.</p>
            <p className="About-text">For your reference, you’ll find our manufacturing partner Teemill Tech Ltd. listed here on the GOTS public database. <a href="#resources"><span>[3]</span></a></p>

            <p className="display-5">Summary</p>

            <p className="About-text">Organic cotton requires significantly less water than conventional cotton, is free from harmful chemicals, contributes to healthy soil, as well as safe working conditions. 
            <br />
            Remember that you are the kings and queens of this economy. Demand what is good for the planet, and you’ll get it. We also need a mindset shift. Stop calling or seeing yourself as a “consumer”. Regarding yourself as such is exactly part of the problem. Acknowledge that your values have an impact. Do not consume your clothes, choose them wisely, love them and care for them like a piece of art.</p>
            <p className="About-text">Yours,
            <br />
            OilOnShirt</p>

            <a id="resources"><p className="About-text">Resources</p></a>
            <p className="About-text">
                <p>[1] <a href="https://www.soilassociation.org/thirsty-for-fashion/" target="_blank" rel="noopener noreferrer">soilassociation.org/thirsty-for-fashion/</a></p>
                <p>[1.1] <a href="https://www.soilassociation.org/media/11662/coolcotton.pdf" target="_blank" rel="noopener noreferrer">soilassociation.org/media/11662/coolcotton.pdf</a></p>
                <p>[1.2] <a href="https://catalogue.unccd.int/1352_thirsty-for-fashion-soil-association-report.pdf" target="_blank" rel="noopener noreferrer">catalogue.unccd.int/1352_thirsty-for-fashion-soil-association-report.pdf</a></p>
                <p>[2] <a href="https://www.cambridge.org/core/journals/renewable-agriculture-and-food-systems/article/organic-cotton-production-may-alleviate-the-environmental-impacts-of-intensive-conventional-cotton-production/011DB7C5487BB768DE953A8BBDE658E2" target="_blank" rel="noopener noreferrer">cambridge.org/core/journals/renewable-agriculture-and-food-systems</a></p>
                <p>[3] <a href="https://www.global-standard.org/public-database/search/database/search_result/19000.html" target="_blank" rel="noopener noreferrer">global-standard.org/public-database/search/database/search_result/19000.html</a></p>
                <p>[4] <a href="https://textileexchange.org/polyester/" target="_blank" rel="noopener noreferrer">textileexchange.org/polyester/</a></p>
                <p>[5] <a href="https://www.fabricsight.com/en-gb/blogs/posts/unexpected-sustainability-recycled-and-biodegradable-polyester-and-polyamide" target="_blank" rel="noopener noreferrer">fabricsight.com/unexpected-sustainability-recycled-and-biodegradable-polyester-and-polyamide</a></p>
            </p>

            <div className="About-tabs">
                <h1 className="display-5 my-3">Fresh content to your inbox</h1>

                <Row>
                    <Col md={6}>
                        <div className='About-text' style={{marginBottom:"0px"}}>We continuously enhance our AI design tool for you. As we are in development stage, new stuff is happening on a daily basis. Want to get notified about the big leaps? Hit the subscribe button now.</div>
                        <EMailSignup />
                    </Col>
                    <Col>
                        <Image src="/samples_11.webp" style={{width:"50%"}}className="About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </div>
        </>
    )
}