import { useShop } from '../contexts/ShopContext';
import { useParams } from 'react-router-dom';
import CollectionAll from '../components/CollectionAll';
import PageTitle from "@oos/core/components/PageTitle";

const TITLE = {
    all: "Organic Cotton Clothing",
    men: "Men's Organic Cotton Clothing",
    women: "Women's Organic Cotton Clothing",
    kids: "Kids' Organic Cotton Clothing",
};

export default function CollectionPage() {
    const { shop } = useShop();
    const { slug } = useParams();

    const title = TITLE[slug] || TITLE.all;

    return (
        <PageTitle title={title}>
            <div className='pageHeight'>{shop.basics && <CollectionAll collection={slug} />}</div>
        </PageTitle>
    )
}