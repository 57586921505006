import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';
import { Link } from "react-router-dom";
import FormSelect from 'react-bootstrap/esm/FormSelect';
import { COLOUR_CODE } from '../const';

export default function AddToCartModal(props) {
    const product = props.product;
    const colour = props.colour;

    const [added, setAdded] = useState(false);
    const [requested, setRequested] = useState(false);
    const [size, setSize] = useState("");

    const handleAddToCart = () => {
        setRequested(true);
        props.onSave(size)
            .then(() => setAdded(true))
            .catch((e) => console.log(e));  // TODO show failed state?

        window.dataLayer.push({
            'event': 'AddToCart',
        });
    };

    const handleClose = () => {
        setRequested(false);
        setAdded(false);
        props.onClose()
    };

    const handleSelect = (e) => setSize(e.target.value);

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add to Basket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>{product.title}, Colour: {colour}</div><div style={{background:`${COLOUR_CODE[colour]}`, borderRadius:"2rem", textAlign:"center", width:"100%", height:"15px"}}></div>
                {added ? "Yeeh, added!"
                : <FormSelect
                    size="sm"
                    variant=""
                    className='my-2 buttonStyle-brown'
                    value={size}
                    onChange={handleSelect}>
                    <option value={""}>Select Size</option>
                    {product.sizes.map((sz) =>
                        <option key={sz} value={sz}>{sz}</option>
                    )}
                </FormSelect>}
            </Modal.Body>
            <Modal.Footer className="me-auto">
                {!added && <Button className="buttonStyle-brown" variant="" onClick={handleAddToCart} disabled={!size || requested}>
                    {requested ? "Adding..." : "Add to Cart"}
                </Button>}
                {added && <Button className="buttonStyle-brown" variant="" as={Link} to="/basket">
                    To Checkout
                </Button>}
            </Modal.Footer>
        </Modal>
    )
}