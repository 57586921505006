import { useState } from "react";
import './MagnifyHover.css';

export default function MagnifyHover (props) {

const [[x, y], setXY] = useState([0, 0]);
const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
const [showMagnifier, setShowMagnifier] = useState(false);

const magnifierHeight = "1000";
const magnifierWidth = "1000";
const zoomLevel = 2;
const src = props.image;
const alt = props.imageAlt;

return (
	<div className="MagnifyImageContainer">
		<img src={src} alt={alt} className="MagnifyImage"

			onMouseEnter={(e) => {
			// update image size and turn-on magnifier
			const elem = e.currentTarget;
			const { width, height } = elem.getBoundingClientRect();
			setSize([width, height]);
			setShowMagnifier(true);
			}}

			onMouseMove={(e) => {
			// update cursor position
			const elem = e.currentTarget;
			const { top, left } = elem.getBoundingClientRect();

			// calculate cursor position on the image
			const x = e.pageX - left - window.pageXOffset;
			const y = e.pageY - top - window.pageYOffset;
			setXY([x, y]);
			}}

			onMouseLeave={() => {
			setShowMagnifier(false);
			}}
		/>

		<div className="MagnifyBGImage"
			style={{display: showMagnifier ? "" : "none",
			// prevent magnifier blocks the mousemove event of img
			// set magnifier size
			height: `${magnifierHeight}px`,
			width: `${magnifierWidth}px`,
			// move element center to cursor pos
			top: `${y - magnifierHeight / 2}px`,
			left: `${x - magnifierWidth / 2}px`,
			backgroundImage: `url('${src}')`,

			//calculate zoomed image size
			backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,

			//calculate position of zoomed image
			backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
			backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`
			}}
		></div>
	</div>
);
}