import './Home.css';
import { HeroImageMove, HeroImageVideo } from '../components/HeroImage';
import { Link } from "react-router-dom";
import Home from '../components/Home';

export default function HomePage() {
    return (
        <>
            <Link to="/studio">
                <HeroImageVideo />
            </Link>
            <Home />
            <Link to="/studio">
                <HeroImageMove />
            </Link>
        </>
    );
}