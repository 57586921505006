import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./ReturnsModal.css";

export default function ReturnsModal(props) {
    return(
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="ReturnModal-h1">Returns and Exchanges</Modal.Title>
            </Modal.Header>
            <Modal.Body className="ReturnModal-text">
                <div style={{marginTop:"10px", marginBottom:"30px"}}>Please email HELLO@OILONSHIRT.COM including your order ID, and we will process your request as quickly as possible.</div>
                <div className="ReturnModal-h1">Plain Products</div>
                <div style={{fontSize:"calc(5px + 0.8vw)", marginTop:"5px"}}>FREE UK returns. You can exchange or return your item at any time, up until the later of 14 days after you receive the products or 28 days after we dispatch them for delivery to you.</div>
                <div className="ReturnModal-h1">Custom Products</div>
                <div style={{marginTop:"5px"}}>Unless they are defective, or not according to our high quality standard, we cannot accept returns of custom products, sample orders, or any products that have been washed or worn.</div>
                <div style={{marginTop:"20px"}}>We want you to be happy, so if in doubt, please reach out to us any time.</div>

                <div style={{marginTop:"20px"}}>UK Returns: For UK orders we offer a FREE returns label which you will received after submitting your returns request via email at hello@oilonshirt.com.</div>
                <div style={{marginTop:"20px"}}>EU/Rest of the World Returns: Unfortunately, we don't offer free returns for orders outside the UK. The cost of returning a parcel from outside the UK will vary by country.</div>
            </Modal.Body>
            <Modal.Footer className="ReturnModal-text me-auto">
                <p>We will respond within 24 hours.</p>
            </Modal.Footer>
        </Modal>
    )
}