import './Typewriter.css';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "./Typewriter.css";

export default function TextEffect() {

    // const TYPETEXT = [
    //     {text: "Your Design", id: "2"},
    // ]

    return (
        <>
            <div className="Typewriter loading02">
                <span>y</span>
                <span>o</span>
                <span>u</span>
                <span>r</span>
                <div style={{height: "10px"}} />
                <span>d</span>
                <span>e</span>
                <span>s</span>
                <span>i</span>
                <span>g</span>
                <span>n</span>
                {/* {TYPETEXT.map((txt) => (
                    <Col key={txt.id}>
                        <div className="Typewriter-text">{txt.text}</div>
                    </Col>
                ))} */}
            </div>
        </>
    )
}

export function TypewriterText() {
    return (
        <div className="Typewriter-text">
            <div style={{width: "30vw"}}>SUPERWOMAN FLIES THROUGH NEW YORK CITY CARRYING A CAT THAT SHE JUST RESCUED FROM A ROOF</div>
        </div>
    )
}


export function Typewriter(props) {
    const images = props.images;
    const textFix = props.textFix;
    const imageClass = props.imageClass || "Typewriter-img";

    const [textIndex, setTextIndex] = useState(0);
    const [text, setText] = useState('');
    const [image, setImage] = useState('');

    const typeText = (textToType) => {
        let index = -1;
        const intervalId = setInterval(() => {
        index++;
        setText((prevText) => {
        if (index < textToType.length) {
            return prevText + textToType[index];
        }
        return prevText;
            });
            if (index === textToType.length) {
                clearInterval(intervalId);
                setImage(images[textIndex].image);
            }
        }, 100);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        const textToType = images[textIndex].text;
        setText('');
        return typeText(textToType);  // clean up inside
    }, [textIndex]);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(intervalId);
        }, []);

    return (
        <Row>
            <Col xs={12}>
                { image ? <img src={image} alt="Typewriter" className={imageClass}/> :
                <img src="/logo_512.png" className={imageClass} /> }
            </Col>
            <Col>
                <div className="Typewriter-text">
                <span style={{color:"#21565a"}}>{textFix}</span>
                <span> {text}</span>
                <span className="Typewriter-cursor"> | </span>
                </div>
            </Col>
        </Row>
    );
};