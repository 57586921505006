import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Blog.css';

export default function BlogContent () {

    const POSTS = [
        {
            title: "Sustainability",
            subtitle: "GOTS certified organic cotton | Circular by Design",
            image: "sustainability_sustainability.webp",
            slug: "sustainability",
        },
        {
            title: "Why buy ORGANIC Cotton?",
            subtitle: "Less water, free from pesticides and harmful chemicals",
            image: "sustainability_circular_shirt.webp",
            slug: "why-buy-organic-cotton-clothing",
        },
        {
            title: "Ai infused organic Clothes",
            subtitle: "Organic Cotton meets Ai Art",
            image: "about_ai_cotton1.webp",
            slug: "about-ai-print-on-demand",
        },
        {
            title: "How to design my AI print shirt",
            subtitle: "Text to image Ai art clothing",
            image: "blog/oos_sunset_preview.png",
            slug: "how-to-design-my-own-ai-print-tshirt",
        },
        {
            title: "Design Your Own Christmas Sweater",
            subtitle: "Custom Ai Print Designs",
            image: "blog/oos_blog_christmas_sweater.jpeg",
            slug: "custom-christmas-sweater-how-to-design-my-own-christmas-sweater-with-ai",
        },
    ];

    const mapBlogContent = (post) =>
        <Col xs={12} md={6} className="my-2" key={post.slug}>
            <section className="Blog-layout" style={{backgroundImage: `url(${`"/${post.image}"`}`}}>
                <Container fluid>
                    <div style={{height:"calc(10px + 4vh)"}} />
                    <Row>
                        <Col xs={{span: 8, offset: 2}}>
                            <Button variant="" as={Link} to={`/blog/${post.slug}`}>
                                <div className="Blog-overlay">
                                    <div style={{height:"2vh"}} />
                                    <h1 className="Blog-title">{post.title}</h1>
                                    <hr style={{color:"#ffeadb", width:"80%", margin:"auto", marginTop:"20px", marginBottom:"20px"}}/>
                                    <h2 className="Blog-subtitle">{post.subtitle}</h2>
                                    <div style={{height:"2vh"}} />
                                </div>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Col>;

    return (
        <>
            <div style={{height:"2vw"}} />
            <Row style={{width:"90vw", margin:"auto"}}>
                {POSTS.map(mapBlogContent)}
            </Row>
        </>
    )
}