import React, { useEffect } from "react";
import "./ProgressBar.css";
import { useState } from "react";

export default function ProgressBar(props) {
    const [completed, setCompleted] = useState(0);
    const progress = Math.min(99, Math.round(completed/5)*5);

    const incCompletion = () => {
        const complete = props.complete || 0.9;
        const tick = props.tick || 2000;
        const duration = props.duration * 1000 || tick;
        const alpha = 1-(1-complete)**(tick / duration);
        setCompleted(completed + alpha * (100 - completed));
        // console.log(`inc ${completed}`)
    }

    useEffect(() => {
        if (completed < 99) {
            setTimeout(incCompletion, props.tick);
        }
    }, [completed]);

    return(
        <div className="LoaderContainer">
            <div className="LoaderFillerStyle" style={{width: `${progress}%`}}>
                <div className="LoaderLabelStyle">
                    {`${progress}%`}
                </div>
            </div>
        </div>
    )
}