import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import "./Studio.css";
import { useShop } from '../contexts/ShopContext';
import { BASES } from '../const';
import { heartSVG } from '../components/SVGs';

export default function StudioPage() {
    const { shop } = useShop();

    const ready = shop.bases && shop.bases[BASES[0].id];

    return (
        <>
            <Container fluid className='Studio-container'>
                <div style={{height: "1vh"}}/>
                <div className="Navigation-tagline Home-tagline">BETA <span className='Home-title'>{heartSVG}</span> NOW LIVE</div>
                <div style={{height: "1vh"}}/>
                {ready && <Outlet />}
            </Container>
        </>
    )
}