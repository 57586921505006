import { energySVG, magicSVG, organicSVG, circularSVG } from "./SVGs";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Values() {

    return (
        <Row style={{marginLeft:"5vw", marginRight:"5vw", marginTop:"2vw", marginBottom:"2vw", borderRadius: "1rem", padding:"0.5%"}} className="Navigation-colorise">
            <Col xs={6} sm={3}>
                <Button as={Link} to="/blog/sustainability" variant="" className="">
                    {organicSVG}<div>Organic</div>
                </Button>
            </Col>
            <Col xs={6} sm={3}>
                <Button as={Link} to="/blog/about-ai-print-on-demand" variant="" className="">
                    {magicSVG}<div>Custom</div>
                </Button>
            </Col>
            <Col xs={6} sm={3}>
                <Button as={Link} to="/blog/sustainability" variant="" className="">
                    {energySVG}<div>Renewables</div>
                </Button>
            </Col>
            <Col xs={6} sm={3}>
                <Button as={Link} to="/blog/sustainability" variant="" className="">
                    {circularSVG}<div>Circular</div>
                </Button>
            </Col>
        </Row>
    )
}
