import React from "react";
import { useState, useEffect } from "react";
import './AddressForm.css';
import Button from 'react-bootstrap/Button';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import { TabContent, TabNavItem } from "./TabContent";
import { useCart } from "../contexts/OldCartContext";
import { useLocale } from '../contexts/LocaleContext';
import { useIntl } from 'react-intl';


const INITADDRESS = {
    firstName: '',
    lastName: '',
    street1: '',
    street2: '',
    country: 'United Kingdom',
    state: '',
    city: '',
    postcode: '',
}

const EU_COUNTRIES = [
    "Austria", "Belgium", "Bulgaria", "Croatia", "Czech Republic", "Denmark",
    "Estonia", "Finland", "France", "Germany", "Greece", "Hungary", "Ireland", "Italy", "Latvia",
    "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Romania", "Slovakia",
    "Slovenia", "Spain", "Sweden"
]

const EU_SHIPPING = ["EU Delivery", "EU Tracked & Signed Delivery"];
const UK_SHIPPING = ["UK Standard Delivery", "UK Royal Mail 1st Class", "UK Priority"];
const US_SHIPPING = ["US International Delivery", "US International Tracked & Signed Delivery"];

const COUNTRIES = [
    ...EU_COUNTRIES,
    "United Kingdom", "United States",
].sort();

const SHIPPING_OPTIONS = {
    ['United Kingdom']: UK_SHIPPING,
    ['United States']: US_SHIPPING,
    ...Object.fromEntries(EU_COUNTRIES.map(c => [c, EU_SHIPPING]))
}


export default function AddressForm(props) {
    const details = props.details;
    const {currency} = useLocale();
    const intl = useIntl();

    const [activeTab, setActiveTab] = useState(details && details.address.shipping && "differentShipping" || "sameShipping");
    const [validated, setValidated] = useState(false);
    const [billing, setBilling] = useState(details && details.address.billing || {...INITADDRESS, email: ''});
    const [shipping, setShipping] = useState(details && details.address.shipping || INITADDRESS);

    const shippingCost = props.shipping.cost;
    const shippingMethod = props.shipping.method;
    const sameShipping = activeTab === "sameShipping";
    const shippingCountry = sameShipping ? billing.country : shipping.country;
    const shippingOptions = SHIPPING_OPTIONS[shippingCountry];

    useEffect(() => {
        if (!shippingOptions.includes(shippingMethod)) {
            props.setShipping(shippingOptions[0]);
        }
    }, [shippingCountry]);

    const changeBilling = (field) => ({target:{value}}) => setBilling({...billing, [field]: value});
    const changeShipping = (field) => ({target:{value}}) => setShipping({...shipping, [field]: value});

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        const valid = form.checkValidity();
        setValidated(true);

        if (valid) {
            const addressData = {billing, shipping: sameShipping ? null : shipping};
            props.onAddress(addressData)
        }
    };

    if (!shippingCost) {
        return <p>Loading</p>
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="AddressForm-layout">
            <h3 className="my-2">BILLING</h3>

            <Form.Group className="mb-3" controlId="formEmail">
                {/* <Form.Label>Email address</Form.Label> */}
                <Form.Control
                    required
                    type="email"
                    placeholder="Email"
                    onChange={changeBilling("email")}
                    value={billing.email}/>
                <Form.Text className="OrderSummary-text">
                    You'll receive the invoice via email.
                </Form.Text>
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="formFirstName">
                    {/* <Form.Label>First name</Form.Label> */}
                    <Form.Control
                        required
                        type="text"
                        placeholder="First name"
                        onChange={changeBilling("firstName")}
                        value={billing.firstName}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formLastName">
                    {/* <Form.Label>Last name</Form.Label> */}
                    <Form.Control
                        required
                        type="text"
                        placeholder="Last name"
                        onChange={changeBilling("lastName")}
                        value={billing.lastName}/>
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" controlId="formStreet1">
                {/* <Form.Label>Address</Form.Label> */}
                <Form.Control
                    required
                    type="text"
                    placeholder="House Number and Street"
                    onChange={changeBilling("street1")}
                    value={billing.street1}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formStreet2">
                {/* <Form.Label>Address 2</Form.Label> */}
                <Form.Control
                    type="text"
                    placeholder="Apartment, studio, or floor"
                    onChange={changeBilling("street2")}
                    value={billing.street2}/>
            </Form.Group>

            <Row className="mb-3">
                <Form.Group hasvalidation="true" as={Col} controlId="formCountry">
                    {/* <Form.Label>Country</Form.Label> */}
                    {/* TODO add validation, e.g. isValid?*/}
                    <Form.Select
                        onChange={changeBilling("country")}
                        value={billing.country}>
                        <>
                            {COUNTRIES.map((c) => <option key={c} value={c}>{c}</option>)}
                        </>
                    </Form.Select>
                </Form.Group>

                <Form.Group as={Col} controlId="formCity">
                    {/* <Form.Label>Town/City</Form.Label> */}
                    <Form.Control
                        required
                        type="text"
                        placeholder="Town/City"
                        onChange={changeBilling("city")}
                        value={billing.city}/>
                </Form.Group>
            </Row>

            <Row className="mb-3">
                <Form.Group as={Col} controlId="formState">
                    {/* <Form.Label>County/State</Form.Label> */}
                    <Form.Control
                        type="text"
                        placeholder="County/State (Optional)"
                        onChange={changeBilling("state")}
                        value={billing.state}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formPostCode">
                    {/* <Form.Label>Postcode</Form.Label> */}
                    <Form.Control
                        required
                        type="text"
                        placeholder="Postcode"
                        onChange={changeBilling("postcode")}
                        value={billing.postcode}/>
                </Form.Group>
            </Row>

            <h3 className="my-4">Shipping</h3>

            <div className='AddressForm-tabs'>
                <ul className="AddressForm-nav">
                    <TabNavItem title="Send to billing address." id="sameShipping" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Send to different address." id="differentShipping" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>

                <div>
                    <TabContent id="sameShipping" activeTab={activeTab} />
                    <TabContent id="differentShipping" activeTab={activeTab}>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formShipFirstName">
                                {/* <Form.Label>First name</Form.Label> */}
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="First name"
                                    onChange={changeShipping("firstName")}
                                    value={shipping.firstName}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formShipLastName">
                                {/* <Form.Label>Last name</Form.Label> */}
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Last name"
                                    onChange={changeShipping("lastName")}
                                    value={shipping.lastName}/>
                            </Form.Group>
                        </Row>

                        <Form.Group className="mb-3" controlId="formShipStreet1">
                            {/* <Form.Label>Address</Form.Label> */}
                            <Form.Control
                                required
                                type="text"
                                placeholder="House Number and Street"
                                onChange={changeShipping("street1")}
                                value={shipping.street1}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formShipStreet2">
                            {/* <Form.Label>Address 2</Form.Label> */}
                            <Form.Control
                                type="text"
                                placeholder="Apartment, studio, or floor"
                                onChange={changeShipping("street2")}
                                value={shipping.street2}/>
                        </Form.Group>

                        <Row className="mb-3">
                            <Form.Group hasvalidation="true" as={Col} controlId="formShipCountry">
                                {/* <Form.Label>Country</Form.Label> */}
                                {/* TODO add validation, e.g. isValid?*/}
                                <Form.Select
                                    onChange={changeShipping("country")}
                                    value={shipping.country}>
                                    <>
                                        {COUNTRIES.map((c) => <option key={c} value={c}>{c}</option>)}
                                    </>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formShipCity">
                                {/* <Form.Label>Town/City</Form.Label> */}
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Town/City"
                                    onChange={changeShipping("city")}
                                    value={shipping.city}/>
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formShipState">
                                {/* <Form.Label>County/State</Form.Label> */}
                                <Form.Control
                                    type="text"
                                    placeholder="County/State (Optional)"
                                    onChange={changeShipping("state")}
                                    value={shipping.state}/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formShipPostCode">
                                {/* <Form.Label>Postcode</Form.Label> */}
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Postcode"
                                    onChange={changeShipping("postcode")}
                                    value={shipping.postcode}/>
                            </Form.Group>
                        </Row>
                    </TabContent>
                </div>
            </div>

            <h3 className="my-4">Shipping Method</h3>
            <Form.Select
                size="sm"
                className='my-2'
                onChange={({target:{value}}) => props.setShipping(value)}
                value={shippingMethod}>
                <>
                    {shippingOptions.map((c) => <option key={c} value={c}>
                        {c + ' ' + intl.formatNumber((currency.rate * shippingCost[c]).toFixed(2), {style: 'currency', currency: currency.symbol})}
                        </option>)}
                </>
            </Form.Select>

            <Button
                className="my-4 px-5 buttonStyle-accent"
                variant=""
                type="submit">
                Next
            </Button>
        </Form>
     )
}