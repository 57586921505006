import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { arrowRightSVG } from './SVGs';

export default function EMailSignup() {
  return (
    <Form>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label></Form.Label>
        <Form.Control type="email" placeholder="Enter email" />
        <div style={{fontSize:"calc(3px + 0.6vw)", margin:"10px"}}>
          We'll never share your email with anyone else. By clicking the signup button I consent to OilOnShirt processing my email for product highlights and updates, promotions and environmental projects.
        </div>
      </Form.Group>

      <Button variant="" className='buttonStyle-lavender' type="submit">
        Sign me up
      </Button>
    </Form>
  );
}

export function EMailSignupPlain() {
  return (
    <Form>
          <Form.Group className="mt-2 mb-2" controlId="formEmail">
            <Form.Label style={{fontSize:"calc(6px + 0.6vw)"}}>Get Updates</Form.Label>
              <Row>
                <Col xs={8}>
                  <Form.Control type="email" placeholder="Enter email" />
                </Col>
                <Col>
                  <Button variant="" className='buttonStyle-brown-solid' type="submit" style={{width:"auto"}}>{arrowRightSVG}</Button>
                </Col>
              </Row>
          </Form.Group>
    </Form>
  );
}