import React from "react";
import { useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TabContent, TabNavItem } from './TabContent';
import Table from 'react-bootstrap/Table';
import "./SizingModal.css";


export default function SizingModal(props) {
    const [activeTab, setActiveTab] = useState("inches");
    const [ showA, setShowA ] = useState(true);
    const [ showK, setShowK ] = useState(false);

    const handleShowAdult = () => {
        setShowA(true);
        setShowK(false);
    }
    const handleShowKids = () => {
        setShowK(true);
        setShowA(false);
    }

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title className="SizingModal-h1">Sizing</Modal.Title>
                <br />
                <Button variant="" className="mx-2" style={{textDecoration:"underline"}} onClick={handleShowAdult}>Adults</Button>
                <Button variant="" className="mx-2" style={{textDecoration:"underline"}} onClick={handleShowKids}>Kids</Button>
            </Modal.Header>
            <div className="SizingModal-tabs">
                <ul className="SizingModal-nav">
                    <TabNavItem title="Inches" id="inches" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Centimeters" id="cm" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>
                { showA && <>
                <TabContent id="inches" activeTab={activeTab}>
                    <Table striped hover className="SizingModalTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>XS</th>
                                <th>S</th>
                                <th>M</th>
                                <th>L</th>
                                <th>XL</th>
                                <th>XXL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Height</td>
                                <td>5'6"</td>
                                <td>5'8"</td>
                                <td>5'10"</td>
                                <td>6'</td>
                                <td>6'2"</td>
                                <td>6'4"</td>
                            </tr>
                            <tr>
                                <td>Chest</td>
                                <td>34"</td>
                                <td>37"</td>
                                <td>39"</td>
                                <td>41"</td>
                                <td>46"</td>
                                <td>51"</td>
                            </tr>
                            <tr>
                                <td>Waist</td>
                                <td>28"</td>
                                <td>30"</td>
                                <td>32"</td>
                                <td>35"</td>
                                <td>39"</td>
                                <td>44"</td>
                            </tr>
                        </tbody>
                    </Table>
                </TabContent>
                <TabContent id="cm" activeTab={activeTab}>
                    <Table striped hover className="SizingModalTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>XS</th>
                                <th>S</th>
                                <th>M</th>
                                <th>L</th>
                                <th>XL</th>
                                <th>XXL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Height</td>
                                <td>171</td>
                                <td>175</td>
                                <td>179</td>
                                <td>183</td>
                                <td>189</td>
                                <td>195</td>
                            </tr>
                            <tr>
                                <td>Chest</td>
                                <td>86</td>
                                <td>93</td>
                                <td>99</td>
                                <td>104</td>
                                <td>116</td>
                                <td>129</td>
                            </tr>
                            <tr>
                                <td>Waist</td>
                                <td>71</td>
                                <td>76</td>
                                <td>81</td>
                                <td>89</td>
                                <td>99</td>
                                <td>112</td>
                            </tr>
                        </tbody>
                    </Table>
                </TabContent>
                </>}
                { showK && <>
                <TabContent id="inches" activeTab={activeTab}>
                    <Table striped hover className="SizingModalTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>3-4yrs</th>
                                <th>5-6yrs</th>
                                <th>7-8yrs</th>
                                <th>9-10yrs</th>
                                <th>11-12yrs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Height</td>
                                <td>39"</td>
                                <td>44"</td>
                                <td>49"</td>
                                <td>54"</td>
                                <td>58"</td>
                            </tr>
                            <tr>
                                <td>Chest</td>
                                <td>24"</td>
                                <td>26"</td>
                                <td>28"</td>
                                <td>30"</td>
                                <td>33"</td>
                            </tr>
                        </tbody>
                    </Table>
                </TabContent>
                <TabContent id="cm" activeTab={activeTab}>
                    <Table striped hover className="SizingModalTable">
                        <thead>
                            <tr>
                                <th></th>
                                <th>3-4yrs</th>
                                <th>5-6yrs</th>
                                <th>7-8yrs</th>
                                <th>9-10yrs</th>
                                <th>11-12yrs</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Height</td>
                                <td>98</td>
                                <td>112</td>
                                <td>125</td>
                                <td>136</td>
                                <td>147</td>
                            </tr>
                            <tr>
                                <td>Chest</td>
                                <td>60</td>
                                <td>66</td>
                                <td>70</td>
                                <td>75</td>
                                <td>84</td>
                            </tr>
                        </tbody>
                    </Table>
                </TabContent> </>}
                <p style={{fontWeight:"600"}}>Select your size at checkout.</p>
                <Modal.Body className="SizingModalText">
                    <h1 className="SizingModal-h1">How To Measure</h1>
                    <br />
                    <h2 className="SizingModal-h2">Chest</h2>
                    <p>Measure all around your chest just below your armpits.</p>
                    <h2 className="SizingModal-h2">Waist</h2>
                    <p>Measure round your waistline, where your trousers would button up.</p>
                    <h2 className="SizingModal-h2">Height</h2>
                    <p>Your natural height.</p>
                </Modal.Body>
                <Modal.Footer className="me-auto">
                    {/* <p>Psst: For plain basics, we guarantee simple returns & exchanges, with freepost returns for all UK orders.</p> */}
                </Modal.Footer>
            </div>
        </Modal>
    )
}