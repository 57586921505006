import './ColourPicker.css';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Row, Col } from 'react-bootstrap';
import { mapColours, COLOURS} from '../const';
import { checkSVG, checkSVG2 } from '../components/SVGs';

export function ColourPicker(props) {
    // ~radio boxes; 1 out of n
    const colours = mapColours(props.colours);
    const selected = props.selected;

    return (
        <>
            {colours.map ((c) =>
                <OverlayTrigger
                    key={c.code}
                    placement='top'
                    delay={{show: 205, hide: 400 }}
                    overlay={<Tooltip>{c.name}</Tooltip>}>
                    <Button variant="" style={{background: c.code}}
                            className={c.code == selected.code ? 'Colour-picker-active' : 'Colour-picker'}
                            onClick={() => props.onSelect(c.name)}></Button>
                </OverlayTrigger>
            )}
        </>
    )
}

export function ColourDisplay(props) {
    const colours = mapColours(props.colours);

    return (
        <>
            { colours.slice(0, 5).map ((c) =>
                <OverlayTrigger
                    key={c.name}
                    placement='top'
                    delay={{show: 205, hide: 400 }}
                    overlay={<Tooltip>{c.name}</Tooltip>}>
                     <Button variant="" className="float-start my-2" style={{background: c.code, width:"1vw", height:"1vw", margin:"2px"}}></Button>
                </OverlayTrigger>)}
            { colours.length > 5 ? <div style={{textAlign:"left"}}>+</div> : <br /> }
        </>
    )
}

export function ColourFilter(props) {
    // ~check boxes; k out of n
    const selected = props.selected;
    const colours = props.colours ? mapColours(props.colours) : COLOURS;

    return (
        <>
            <Row xs={12}>
                {colours.map((c) =>
                    <Col lg={4} xl={3} className="my-1" key={c.code}>
                        <OverlayTrigger
                            key={c.code}
                            placement='top'
                            delay={{show: 205, hide: 400 }}
                            overlay={<Tooltip>{c.name}</Tooltip>}>
                            <button className={selected[c.name] ? "Colour-filter-checked" : "Colour-filter"}
                                    onClick={() => props.onSelect(c.name)}
                                    selected={props.selected[c.name]}>
                                <div className="Colour-filter-col" style={{background: c.code}}>
                                { selected[c.name] && <div style={{display:"inline-flex", color:"#ffeadb"}}>{checkSVG2}</div>}
                                </div>
                            </button>
                        </OverlayTrigger>
                    </Col>
                )}
            </Row>
        </>
    )
}