import Button from 'react-bootstrap/esm/Button';
import './CartSummary.css';
import { Row, Col } from 'react-bootstrap';
import { TabContent, TabNavItem } from './TabContent';
import { useState } from "react";
import { useLocale } from '../contexts/LocaleContext';
import {FormattedNumber} from 'react-intl';
import { Link } from "react-router-dom";
import ReturnsModal from './ReturnsModal';
import { useShop, summarizeCart } from '../contexts/ShopContext';
import { boxSVG } from './SVGs';


const two_product_discount = (summary) => summary.qty > 1 && { qty: summary.total * 0.1 };


export default function CartSummary() {
    const { shop } = useShop();

    const { currency } = useLocale();
    
    const [activeTab, setActiveTab] = useState("returns");
    const [showReturn, setShowReturn] = useState(false);
    
    const summary = summarizeCart(shop);
    const canCheckout = summary && summary.total > 0;
    const subtotal = canCheckout && summary.total;
    const discount = subtotal ? { ...two_product_discount(summary) } : {};

    const freeShipping = shop.freeShipping && shop.freeShipping.threshold[currency.symbol];
    const shippingCost = subtotal * currency.rate >= freeShipping ? 0 : 4;  // TODO get dynamic shipping cost

    const totalDiscount = Object.values(discount || {}).reduce((a, b) => a + b, 0);
    const total = subtotal + shippingCost - totalDiscount;

    return (
        <div className="">
            <h1 className="">CHECKOUT</h1>
            {summary && <p className='CartSummary-text-accent'>Total Items: {summary.qty}</p>}
            <hr />
            {subtotal &&
            <>
            <Row>
                <Col xs={8}>
                    <p className='CartSummary-text-total'>Subtotal</p>
                </Col>
                <Col className='CartSummary-numbers'>
                    <FormattedNumber
                    value={subtotal * currency.rate}
                    style="currency"
                    currencyDisplay="symbol"
                    currency={currency.symbol}/>
                </Col>
            </Row>
            <Row>
            <Col xs={8}>
                <p className='CartSummary-text-accent'>Estimated Shipping</p>
            </Col>
            <Col className='CartSummary-numbers'>
            { shippingCost == 0 ? <div>FREE</div> :
                <FormattedNumber
                value={shippingCost * currency.rate}
                style="currency"
                currencyDisplay="symbol"
                currency={currency.symbol}/>}
            </Col>
            </Row>
            {discount.qty &&
            <Row>
                <Col xs={8}>
                    <p className='CartSummary-text-accent'>Multi Product Offer: 10% Off</p>
                </Col>
                <Col className='CartSummary-numbers'>
                    <FormattedNumber
                    value={- discount.qty * currency.rate}
                    style="currency"
                    currencyDisplay="symbol"
                    currency={currency.symbol}/>
                </Col>
            </Row>}
            <hr />
            <Row>
                <Col xs={8}>
                    <p className='CartSummary-text-total'>Total</p>
                </Col>
                <Col className='CartSummary-numbers'>
                    <FormattedNumber
                    value={total * currency.rate}
                    style="currency"
                    currencyDisplay="symbol"
                    currency={currency.symbol}/>
                </Col>
            </Row>
            </>}
            {canCheckout &&
            <Button className="my-3 buttonStyle-brown-solid" variant='' as={Link} to="/checkout">To Checkout</Button>
            }
            <br />
            <p className='CartSummary-text-accent'>Orders from <FormattedNumber
                value={freeShipping}
                style="currency"
                currencyDisplay="symbol"
                currency={currency.symbol}
                /> qualify for Free Shipping {boxSVG}</p>
            <div className='my-5 CartSummary-tabs-container'>
                <ul className="CartSummary-nav">
                    <TabNavItem title="Returns" id="returns" activeTab={activeTab} setActiveTab={setActiveTab}/>

                    <TabNavItem title="Quality" id="quality" activeTab={activeTab} setActiveTab={setActiveTab}/>

                    <TabNavItem title="Privacy" id="privacy" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>

                <div className='CartSummary-text'>
                    <TabContent id="returns" activeTab={activeTab}>
                        FREE UK returns for <span style={{textDecoration:"underline"}}>plain basics</span>. Unfortunately, we don't offer out of the box returns for <span style={{textDecoration:"underline"}}>custom</span> products, unless your item has arrived damaged or does not fulfill highest quality standards.
                        <br />
                        <Button className="my-3 buttonStyle-brown" variant='' onClick={() => setShowReturn(true)}>More Info</Button>
                    </TabContent>

                    <TabContent id="quality" activeTab={activeTab}>
                        Highest standards in creating and recycling products. Products are made from GOTS certified organic cotton. Renewable energy is used throughout our supply chain.
                    </TabContent>

                    <TabContent id="privacy" activeTab={activeTab}>
                        We only use your details to fulfill the requested service to you. Our privacy policy can be found <a href="/privacy-policy">here</a>.
                    </TabContent>
                    <ReturnsModal show={showReturn} onClose={() => setShowReturn(false)}></ReturnsModal>
                </div>
            </div>
        </div>
    )
}