import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import './About.css';
import Image from "react-bootstrap/esm/Image";
import Button from 'react-bootstrap/esm/Button';
import EMailSignup from '../EmailSignup';
import BlogBanner from './BlogBanner';
import { Typewriter } from '../Typewriter';

const TYPEWRITER = [
    { text: 'Cute cat face surrounded by flowers', image: '/samples_1.webp' },
    { text: "Elegant woman's head seen from the side wearing a hat", image: '/samples_4.webp' },
    { text: 'Sitting cat cleaning its paw', image: '/samples_7.webp' },
    { text: 'Yosemite mountain', image: '/samples_10.webp' },
    { text: 'Portrait of a bull with colourful feathers', image: '/samples_11.webp' },
];

export default function HowToPrint() {

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">How to design my AI print shirt</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">Text to image Ai art clothing</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/studio">Design Now</Button>
            </div>

            {/* <BlogBanner image="/about_ai_cotton_banner.webp" title="Ai Art for Custom Print Clothes" link="/studio"/> */}
            <section id="HomeSection3" style={{backgroundImage: `url(${"/sweater_banner.png"}`}}>
                <Container className="my-4 Home-background-color">
                    <Row className="Home-column-height-l align-items-center">
                        <Col>
                            <div className="">
                                <div style={{height:"3vh"}} />
                                <Typewriter images = {TYPEWRITER} imageClass="Typewriter-img"/>
                                <div style={{height:"2vh"}} />
                                {/* <div className="Home-text">Wear Your Story!</div>
                                <Button size="lg" variant='' className="buttonStyle-accent" as={Link} to="/studio/AiTool">Design Now</Button> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <div className='Home-title my-4'>How To Guide</div>
            <div className="About-heading-sm">01100001 Ai 01110010 01110100 Art</div> */}
            <div style={{height: "calc(15px + 10vh)"}} />

            <h2 className='About-heading'>Design your own print clothes in 3 Steps</h2>
            <h3 className='About-heading-sm'>with Text to Image Ai</h3>

            <div style={{height:"5vh"}}/>

            <h4 className="About-heading">1. Describe your print</h4>
            <Link to="/studio">
                <Image src="/blog/oos_prompt5_sunset.png" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
            </Link>
            <p className="About-text">If you can describe it in words, you can create it. You can say it with only a few words, or be super elaborate in your text prompt. The key is to use words very intentionally. Every word will be given special attention in the creation process.</p>
            <p className="About-text">While keeping your text prompt the same, experiment with different styles to generate your desired output. We currently offer black line art styles and colour styles for print. No two image outputs will ever be the same!</p>
            <Link to="/studio">
                <Image src="/blog/oos_sunset_preview.png" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
            </Link>

            <div style={{height:"10vh"}}/>

            <h4 className="About-heading">2. Choose your product</h4>
            <Link to="/studio">
                <p className="About-text">All our products are made from GOTS certified organic cotton and are printed on demand in the UK as you order.</p>
                <Image src="/blog/oos_sunset_studio.png" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
                <Row style={{width:"90%", margin:"auto"}}>
                    <Col xs={6}>
                        <Image src="/blog/oos_sunset_resize.png" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                    <Col xs={6}>
                        <p className="About-text">You can resize the images or add other images from your favourites library. The maximum print area is 30cm x 40cm. Make sure you save your creations, so you can revisit them later on. If you want to save the same design on a different product, click "Save Copy".</p>
                        <p className="About-text">You can view all your creations in your favourites.</p>
                    </Col>
                </Row>
            </Link>
            <Link to="/favourites">
                <Row style={{width:"90%", margin:"auto"}}>
                    <Col xs={6}>
                        <Image src="/blog/oos_mydesigns.webp" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                    <Col xs={6}>
                        <Image src="/blog/oos_myproducts.webp" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </Link>

            <div style={{height:"10vh"}}/>

            <h4 className="About-heading">3. Order</h4>
            <p className="About-text">You can order your creation directly within the studio or from your favourites section "My Products". Select "View" to open the product preview. Select your preferred colour and click "Add to Basket" to select your desired size and quantity.</p>
            <Link to="/product/women-relaxed-fit-tee">
                <Image src="/blog/oos_viewproduct.png" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
            </Link>

            <div style={{height:"10vh"}}/>

            <div className="About-tabs">
                <h2 className='About-heading-sm Home-title'>Still wearing off the shelf?</h2>
                <h2 className='About-heading-sm'>Wear what you care about. Happy creating!</h2>
                <Button size="lg" variant='' className="mb-5 mt-5 buttonStyle-accent" as={Link} to="/studio">Design Now</Button>
            </div>

            <div className="About-tabs">
                <h1 className="display-5 my-3">JOIN THE AI ART COMMUNITY</h1>
                <Row>
                    <Col md={6}>
                        <div className='About-text' style={{marginBottom:"0px"}}>We continuously enhance our AI design tool for you. As we are in development stage, new stuff is happening on a daily basis. Want to get notified about the big leaps? Hit the subscribe button now.</div>
                        <EMailSignup />
                    </Col>
                    <Col>
                        <Image src="/samples_11.webp" style={{width:"50%"}}className="About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </div>
        </>
    )
}
