import React from 'react';
import './Collection.css';
import { Button, Col, Row, Container } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CollectionFeature from './CollectionFeature';

export default function BasicsCollection (props) {
    const navigate = useNavigate();
    
    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">All Organic Cotton Clothing</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">GOTS certified | Circular by Design</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/collection/all">Shop All</Button>
            </div>

            {/* <div style={{height:"2vh"}}/>
            <Row style={{width:"90vw", margin:"auto"}}>
                <Col md={4}>
                    <Link to="/collection/men">
                        <section id="HomeSection2-local" className="my-2" style={{backgroundImage: `url(${"/carousel_men_basic_tshirt_catalogue2_ls.jpeg"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-end">
                                    <Col md={{span: 8, offset: 2}}>
                                        <Button variant="" onClick={() => navigate("/collection/men")} className='buttonStyle-accent' style={{marginBottom:"5%"}}>Men</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Link>
                </Col>
                <Col md={4}>
                    <Link to="/collection/women">
                        <section id="HomeSection2-local" className="my-2" style={{backgroundImage: `url(${"/banner_teemill_blank_backprints.png"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-end">
                                    <Col md={{span: 8, offset: 2}}>
                                        <Button variant="" onClick={() => navigate("/collection/women")} className='buttonStyle-accent' style={{marginBottom:"5%"}}>Women</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Link>
                </Col>
                <Col md={4}>
                    <Link to="/collection/kids">
                        <section id="HomeSection2-local" className="my-2" style={{backgroundImage: `url(${"/kids_longsleeve.jpeg"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-end">
                                    <Col md={{span: 8, offset: 2}}>
                                        <Button variant="" onClick={() => navigate("/collection/kids")} className='buttonStyle-accent' style={{marginBottom:"5%"}}>Kids</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Link>
                </Col>
            </Row> */}

            <div style={{height:"4vh"}}/>

            <CollectionFeature />

            <Row className="Sustainability-tabs">
                <h5>Sustainability</h5>
                <h3>Circular Concept | ORGANIC COTTON SOFTNESS</h3>
                <div style={{height:"50px"}}/>
                <Col xs={12} md={6}>
                    <img style={{width:"100%", borderRadius:"1rem"}}src="/teemill_supply_chain.jpeg" />
                </Col>
                <Col className='Collection-text'>
                    <div>All our products are printed on demand and are made from organic cotton only. We use renewable energy throughout our supply chain. Most importantly, our products are designed to be recycled and to be remade into new products. Click below to find out more about our awesome supplier Teemill!</div>
                    <Button size="lg" variant='' className="my-4 buttonStyle-lavender Home-title" as={Link} to="/sustainability">Sustainability</Button>
                </Col>
            </Row>
            
            <div style={{height:"50px"}}/>

            {/* <h5>You Might also Like</h5>
            <div className="display-5">WEAR YOUR ART</div>
            <div className="Home-text">Transform your imagination into wearable art prints</div>
            <div style={{height:"2vh"}} />
            <Button size="lg" variant='' className="my-2 buttonStyle-brown Home-title" as={Link} to="/studio">Create Now</Button> */}
               {/* <h1 className="display-5">CREATE CUSTOM ORGANIC PRINT CLOTHES</h1> */}
            {/* <h2 className='Home-title'>STORY ART CLOTHING</h2> */}
            {/* <p className="Home-text Home-title Home-title-headline">Still buying off the shelf?</p> */}
            {/* <Button size="lg" variant='' className="buttonStyle-lavender Home-text" href="https://shop.oilonshirt.com/art-graphic-clothes/" target="_blank" rel="noreferrer">Shop Pre-Designed</Button> */}
        </>
    )
}
