import React, { useState } from 'react';
import { bagSVG } from './SVGs.js';
import Button from 'react-bootstrap/Button';
import AddToCartModal from './AddToCartModal.js';

export default function AddToCartButton(props) {
    const local = useState(false);
    const { show, setShow } = props.show ? props : { show: local[0], setShow: local[1] };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button
                className='mx-1 my-2 buttonStyle-accent'
                variant=""
                onClick={handleShow}>
                {bagSVG} Add to Basket
            </Button>
            <AddToCartModal product={props.product} colour={props.colour} show={show} onSave={props.onSave} onClose={handleClose}/>
        </>
    )
}