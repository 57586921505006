import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from 'react-bootstrap/Form';
import './Collection.css';
import Button from 'react-bootstrap/esm/Button';
import { certifiedSVG, closeRoundSVG } from '../components/SVGs';
import { useNavigate } from "react-router-dom";
import { useShop } from '../contexts/ShopContext';
import { CustomProduct } from './CustomProducts';
import { FlipContainerSwap } from './FlipContainer';
import { ColourDisplay, ColourFilter } from './ColourPicker';
import { COLOURS } from '../const';

const CHECKED_DEFAULT = {
    basics: true,
    prints: true,
    mydesigns: true,
};

const PATHS = [
    {collection:"all", title:"All", id:1},
    {collection:"men", title:"Men", id:2},
    {collection:"women", title:"Women", id:3},
    {collection:"kids", title:"Kids", id:4},
    {collection:"mydesigns", title:"My Designs", id:5},
];

export function CollectionMenu(props) {
    const inputs = props.inputs;

    return (
        <div>
            {inputs.map((nav) => (
                <div className="Collection-nav" key={nav.id}>
                    <label htmlFor={nav.collection}>
                        <input
                            className="CollectionMenu-radio-hidden"
                            type='radio'
                            name='paths'
                            id={nav.collection}
                            value={nav.collection}
                            onChange={(e) => props.onChange(e.target.value)}
                            checked={props.value === nav.collection}
                            readOnly
                        />
                        <div className="CollectionMenu-overlay">
                            {nav.title}
                        </div>
                    </label>
                </div>
            ))}
        </div>
    )
};

export function BasicsPrintsFilter(props) {

    return (
        <>
        <Form style={{display:"inline-flex"}}>
            <Form.Check
                className="mx-2"
                checked={props.checked.basics}
                type="switch"
                id="custom-switch"
                label="Basics"
                onChange={()=> props.onChange("basics")}
            />
            <Form.Check
                className="mx-2"
                checked={props.checked.prints}
                type="switch"
                id="custom-switch"
                label="Prints"
                onChange={()=> props.onChange("prints")}
            />
        </Form>
    </>
    )
}

export default function CollectionAll(props) {
    const { shop } = useShop();
    const [colours, setColours] = useState(Object.fromEntries(COLOURS.map(c => [c.name, false])));
    const allColours = allOrNone(Object.values(colours));

    const filterCollection = (products) => {
        switch(props.collection) {
            case 'men':
                return products.filter(p => p.gender == 'male' && p.agegroup == 'adults');
            case 'women':
                return products.filter(p => p.gender == 'female' && p.agegroup == 'adults');
            case 'kids':
                return products.filter(p => p.agegroup == 'kids');
            case 'mydesigns':
                return [];
            default:
                return products;  // TODO sort products
        }
    };

    const coloursSelected = (cs) => cs.find((c) => colours[c]);
    const filterByColour = (products) => allColours ? products : products.filter(p => coloursSelected(Object.keys(p.colours)));

    const basics = filterByColour(filterCollection(shop.basics));
    const prints = filterByColour(filterCollection(shop.prints));
    const custom = (props.collection === "all" || props.collection === "mydesigns") ? shop.custom : [];

    const allProducts = basics.length + prints.length + custom.length;
    const allShop = shop.basics.length + shop.prints.length + shop.custom.length;

    const navigate = useNavigate();

    // const [ showFilter, setShowFilter ] = useState(true);

    const [ checked, setChecked ] = useState(CHECKED_DEFAULT);

    const [ selected, setSelected ] = useState(PATHS[0]);

    const handleChecked = (filter) => setChecked({...checked, [filter]: !checked[filter]});

    const handleCollection = (collection) => {
        navigate(`/collection/${collection}`);
        setChecked(CHECKED_DEFAULT);
        // setShowFilter(true);
        setSelected(PATHS.find(e => e.collection === collection));
    }

    const handleColourSelect = (name) => setColours({...colours, [name]: !colours[name]});
    const handleColourReset = () => setColours(Object.fromEntries(COLOURS.map(c => [c.name, false])));

    const mapProduct = (p) => mapCatalogProduct(p, allColours);

    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        function checkTablet() {
            setIsTablet(window.innerWidth < 992); /* breakpoint large */
        }
        checkTablet();
        window.addEventListener('resize', checkTablet);
        return () => window.removeEventListener('resize', checkTablet);
    }, []);

    return (
        <>
            <Row className="Collection-tabs">
                <span>GOTS certified organic {certifiedSVG}</span>
                <div className='Collection-heading'>
                    Customisable organic cotton t-shirts, sweaters and hoodies. Add a unqiue print or enjoy them as plain basics.
                </div>
                <hr style={{margin:"10px", filter:"blur(1px)", backgroundColor:"#8d6e63", height:"3px"}}/>

                { !isTablet &&
                <Col lg={2} style={{marginRight:"3vw"}}>
                    <div className="Collection-sticky">
                        <div style={{fontSize:"calc(8px + 0.5vw)", fontWeight:"600", color:"#21565a", textTransform:"uppercase", marginTop:"auto"}}>
                            <BasicsPrintsFilter onChange={handleChecked} checked={checked}/>
                        </div>
                        <hr style={{filter:"blur(1px)", backgroundColor:"#8d6e63", height:"3px"}}/>
                        <ColourFilter onSelect={handleColourSelect} selected={colours} />
                        { !allColours &&
                        <Button variant='' onClick={handleColourReset}>{closeRoundSVG} Reset</Button>}
                    </div>
                </Col>}

                <Col lg={9}>
                    <div className="Collection-sticky">
                        <Row style={{fontSize:"calc(8px + 0.5vw)", fontWeight:"600", color:"#21565a", textTransform:"uppercase", marginTop:"auto"}}>
                            <Col xs={12} md={6} className='d-flex justify-content-start'>
                                <CollectionMenu inputs={PATHS} value={selected.collection} onChange={handleCollection}/>
                            </Col>
                            { isTablet &&
                            <>
                                <hr style={{filter:"blur(1px)", backgroundColor:"#8d6e63", height:"3px", marginTop:"15px"}}/>
                                <Col xs={6} className='d-flex justify-content-start'>
                                    <BasicsPrintsFilter onChange={handleChecked} checked={checked}/>
                                </Col>
                            </>}
                            <Col xs={6} className='d-flex justify-content-end'>
                                <div>( {allProducts} of {allShop} )</div>
                            </Col>
                        </Row>
                        <hr style={{filter:"blur(1px)", backgroundColor:"#8d6e63", height:"3px"}}/>
                    </div>
                    <Row>
                        { checked.basics &&
                        <>
                            {basics.map(mapProduct)}
                        </>}
                        { checked.prints &&
                        <>
                            {prints.map(mapProduct)}
                        </>}
                        <>
                            {custom.map(mapCustomProduct)}
                        </>
                        { props.collection === "mydesigns" && custom.length == 0 &&  <div>
                            <div>You have no custom products yet.</div>
                            <Button variant="" className="mx-2 my-4 buttonStyle-brown Home-title ImageUploadCloud" as={Link} to="/studio">Create Now</Button>
                        </div>}
                    </Row>
                </Col>
            </Row>
        </>
    )
};

const mapCatalogProduct = (product, display) => (
    <Col xs={12} sm={6} xl={4} key={product.id} className="hidden">
        <Link to={`/product/${product.slug}`}>
            <FlipContainerSwap imageBefore={product.images[0]} imageAfter={product.images[1]}/>
            {/* <img id="image" className="hover-zoom-light Collection-images" src={product.images[0]} alt={product.title}/> // alternative collection display with only one image */}
            {display && <ColourDisplay colours={Object.keys(product.colours)} />}
            <Button variant="" className="Collection-catalogue-text float-start">{product.title} | £{product.price}</Button>
        </Link>
    </Col>
);

const mapCustomProduct = (product) =>
<Col xs={12} sm={6} xl={4} key={product.id}>
    {product && <>
        <CustomProduct product={product}/>
        <div className="Collection-catalogue-text">My OilOnShirt -| £{product.price}</div>
    </>}
</Col>; // TODO fix title {product.base}

const allOrNone = (bools) => bools.every((b) => b == bools[0]);

{/* <Row className="hidden" style={styles.hidden}>test</Row> */} //TODO check whether useful in other components
// const [hideProducts, setHideProducts] = useState(false);

// const styles = {
//     hidden:{
//         display: hideProducts ? "none": "flex",
//         opacity: hideProducts ? "0" : "1",
//       }
// };