import React from 'react';
import { BASES, BASES_BY_ID } from '../const.js';


const StudioContext = React.createContext()

const DESIGN_WIDTH = 1024;

function studioReducer(state, action) {
    console.log(`executing ${action.type}`);
    switch (action.type) {
        case 'RESET': {
            const reset = action.payload;
            return {
                id: null,  // always reset id, to force initial copy save
                base: reset.base,
                front: reset.front || [],
                back: reset.back || [],
                colour: reset.colour,
            }
        }
        case 'SET_ID': {
            const id = action.payload;
            return { ...state, id }  // overwrite?
        }
        case 'ADD_DESIGN': {
            const design = action.payload;
            const side = state.backPrint ? 'back' : 'front';
            const base = BASES_BY_ID[state.base];
            const layer = {
                scale: 1,
                pos: [
                    (base.front.width - DESIGN_WIDTH) * 0.5,
                    base.front.height * 0.04,
                ],
                ...design
            }
            return {
                ...state,
                [side]: [...state[side], layer]
            }
        }
        case 'REMOVE_DESIGN': {
            const id = action.payload;
            return {
                ...state,
                front: state.front.filter((d) => d.id !== id),
                back: state.back.filter((d) => d.id !== id),
            }
        }
        case 'REMOVE_LAYER': {
            const { side, layer } = action.payload;
            return {
                ...state,
                [side]: state[side].filter((_, i) => i !== layer),
            }
        }
        case 'CHANGE_LAYER': {
            const { side, layer, ...change } = action.payload;
            return {
                ...state,
                [side]: state[side].map((l, i) => i == layer ? {
                    ...l, ...change
                } : l)
            }
        }
        case 'CHANGE_BASE': {
            const base = action.payload;
            const colour = null;
            // const base = BASES_BY_ID[baseId];
            return { ...state, base, colour }
        }
        case 'CHANGE_COLOUR': {
            const colour = action.payload;
            return { ...state, colour }
        }
        case 'FLIP_SIDE': {
            return { ...state, backPrint: !state.backPrint }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}


export function StudioProvider({children}) {
    const [state, dispatch] = React.useReducer(studioReducer, {
        front: [],
        back: [],
        base: BASES[0].id,
        colour: "White",  // TODO optional default
    });
    const value = { studio: state, dispatch };
    return <StudioContext.Provider value={value}>{children}</StudioContext.Provider>
}


export function useStudio() {
    const context = React.useContext(StudioContext)
    if (context === undefined) {
        throw new Error('useStudio must be used within a StudioProvider')
    }
    return context
}