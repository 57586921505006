import React from "react";
import "./AddressSummary.css";

export default function Address(props) {
    const { firstName, lastName, street1, street2, state, city, postcode, country } = props.address;

    return (
        <div>
            <div>{firstName} {lastName} </div>
            <div>{street1}, {street2}</div>
            <p>{city}, {postcode}</p>
            <div>{state}</div>
            <p>{country}</p>
        </div>
    )
}