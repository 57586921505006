import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Card } from 'react-bootstrap';
import './About.css';
import Image from "react-bootstrap/esm/Image";
import Button from 'react-bootstrap/esm/Button';
import HomeArt from '../HomeArt';
import EMailSignup from '../EmailSignup';
import BlogBanner from './BlogBanner';
import Values from '../Values';

export default function About() {

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">Ai infused organic Clothes</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">Organic Cotton meets Ai Art</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/studio/AiTool">Design Now</Button>
            </div>

            <BlogBanner image="/about_ai_cotton2.webp" title="The Power of Art" link="/studio"/>

            <div className='Home-title my-4'>Express what matters to you</div>
            <div className="About-heading-sm">01100001 Ai 01110010 01110100 Art</div>
            <div style={{height: "calc(15px + 5vh)"}} />

            <h1 className='About-heading'>Organic Print On Demand with Ai Art</h1>
            <h2 className='About-heading-sm'>How to use Ai image generation to create unique on demand print clothing</h2>

            <Values />

            <p className="About-text">Whilst aiming to create the most unique print clothes in the world, we realised the secret lies within the creative potential of each individual. With OilOnShirt we want to bring you a new state of the art and sustainable print clothing alternative. Made possible through "The world's first open-access circular supply chain" by Teemill Tech Ltd, open source Ai models and your creativity.</p>

            <h2 className='About-heading-sm Home-title'>Still wearing off the shelf?</h2>
            <h2 className='About-heading-sm'>Wear what you care about.</h2>

            <img src="/logo_text.png" style={{width:"50vw"}}/>
            <p className="About-text">We want to make clothing personal, meaningful and hence lovable. OilOnShirt is not about seasons, nor are We the brand. The product is what You make of it. None of our items have branding or logos, so it is your blank canvas. Create your personalised clothing experience with circular, high quality clothing. No need to compromise. You get fully recyclable, on demand printed, organic cotton clothes with a one of a kind print design. Whilst leveraging the latest technologies, OilOnShirt helps you to wear what You care about. Create meaningful print designs to raise awareness for causes that speak to You and your community.</p>

            {/* <p>AI Art Clothes. OilOnShirt | Where Organic Cotton Meets Ai Art.</p> */}

            {/* <Row className="Home-column-height-m align-items-center">
                <div className="HeroImage-title">
                    <Button variant='' className="my-4 HeroImage-design-button" as={Link} to="/design">
                        <Card.Title className="HeroImage-text-inset" style={{fontFamily: 'Yeseva One'}}>Organic</Card.Title>
                        <h1 className='HeroImage-title-text-sm Home-title-headline Home-title'>Story Art Clothing Create your one of a kind garment. The brand is You.</h1>
                    </Button>
                    <br />
                    <Button variant='' className="my-4 HeroImage-design-button" as={Link} to="/design">Organic Cotton meets Ai Art</Button>
                    <Button variant='' className="my-4 HeroImage-design-button" as={Link} to="/design">Super Natural Clothing</Button>
                </div>
            </Row> */}

            {/* <p className="About-text">We live in a new era, on so many levels: </p>
            <p className="About-text">
                <li>Mental shift from cosuming to valuing</li>
                <li>Common understanding what it means to protect the world we live in</li>
                <li>Showing your unique identity instead of flowing with the masses</li>
                <li>Technologial disruption</li>
            </p> */}
            {/* <Button variant='' className="my-4 HeroImage-design-button2" as={Link} to="/studio"></Button> */}


            <div className="About-tabs">

                {/* <span className='About-heading-sm'>"Elegant woman's head seen from the side, wearing a hat" - in 3 styles</span>
                <Row>
                    <Col xl={4} md={4}>
                        <section id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"samples_4.webp"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-center">
                                    <Col md={{span: 8, offset: 2}}>
                                        <div className="About-tabs-overlay About-bg-col">
                                            <div style={{fontSize: "15px"}}>Strong Line Art</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Col>
                    <Col xl={4} md={4}>
                        <section id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"samples1_womenhead_lineart.png"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-center">
                                    <Col md={{span: 8, offset: 2}}>
                                        <div className="About-tabs-overlay About-bg-col">
                                            <div style={{fontSize: "15px"}}>Single Line Art</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Col>
                    <Col xl={4} md={4}>
                        <section id="HomeSection2-local" className="my-2 About-tabs-img" style={{backgroundImage: `url(${"samples2_womenhead_colour.png"}`}}>
                            <Container className="Home-background-color">
                                <Row className="Home-column-height-m align-items-center">
                                    <Col md={{span: 8, offset: 2}}>
                                        <div className="About-tabs-overlay About-bg-col">
                                            <div style={{fontSize: "15px"}}>Minimalist Colours</div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </Col>
                </Row> */}

                <Button size="lg" variant='' className="mb-5 buttonStyle-accent" as={Link} to="/studio/AiTool">Try it Now</Button>

                <HomeArt />

                <Link to="/studio">
                    <Card style={{border:"none", background:"transparent"}}>
                        <Card.ImgOverlay className='About-text'>
                            <Card.Title>OilOnShirt</Card.Title>
                            <Card.Text className="display-6">Line Art</Card.Text>
                            <Card.Text>Organic Cotton Sweater</Card.Text>
                        </Card.ImgOverlay>
                        <Image src="/banner_product_women_sweater.jpeg" className="my-2 About-tabs-img" fluid></Image>
                    </Card>
                </Link>
            </div>

            <div className="About-tabs">
                <h1 className="display-5 my-3">JOIN THE AI ART COMMUNITY</h1>

                <Row>
                    <Col md={6}>
                        <div className='About-text' style={{marginBottom:"0px"}}>We continuously enhance our AI design tool for you. As we are in development stage, new stuff is happening on a daily basis. Want to get notified about the big leaps? Hit the subscribe button now.</div>
                        <EMailSignup />
                    </Col>
                    <Col>
                        <Image src="/samples_11.webp" style={{width:"50%"}}className="About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </div>
        </>
    )
}