import React from 'react';
import {Col, Row } from 'react-bootstrap';
import './Cart.css';
import { ProductCard } from './ProductCard';
import { useShop } from '../contexts/ShopContext';

export default function Cart() {
    const {shop} = useShop();
    const cart = shop.cart;

    const itemId = (item) => item.id + item.colour + item.size;

    const loaded = shop.cart && shop.productById;

    return (
        <>
            {loaded ?
                (
                cart.length ? (
                    <div className="Cart-tabs">
                        <h1 className="">YOUR BAG</h1>
                        <p className='CartSummary-text-accent'>Review your items</p>
                        <hr></hr>
                        <Row>
                            {cart.map((p) => <Col key={itemId(p)} xxl={6}>
                                <ProductCard item={p} />
                            </Col>)}
                        </Row>
                    </div>
                )
                : <h2 className="my-6 Cart-tabs">Your cart is empty</h2>
                )
                : <p>Loading</p>
            }
        </>
    )
}
