import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function BlogBanner(props) {

    return (
        <div className='Home-tabs'>
            <Row>
                <h2 className='Home-title my-4'>{props.title}</h2>
                <Col xs={12}>
                    <Link to={props.link}>
                        <img className="Home-img my-2" alt="organic print on demand with ai image generator" src={props.image}/>
                    </Link>
                </Col>
            </Row>
        </div>
    )
} 