import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { bagLargeSVG, productsSVG } from './SVGs.js';
import { Button } from 'react-bootstrap';
import { RingCircle } from '@oos/core/masks';
// import './TeemillButton.css';
import TShirt from '@oos/core/components/TShirt';
import MaskedImage from '@oos/core/components/MaskedImage';
import { useEffect, useState } from 'react';
import { useStudio } from '../contexts/StudioContext';
import { canvas_url } from '../const.js';

const TSHIRT_CANVAS = [1575, 2100]
const TSHIRT_MASK = new RingCircle(0.5*TSHIRT_CANVAS[0], 0.36175*TSHIRT_CANVAS[1], 0.738*TSHIRT_CANVAS[0]);

export function EditInStudio(props) {
    const {dispatch} = useStudio();

    const design = props.design;
    const image = design.image;
    const base = props.base;
    const canvas = base.front;
    const canvasImage = canvas_url(base.id, 'White', false);
    // console.log(canvasImage);
    const mask = TSHIRT_MASK;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function checkMobile() {
            setIsMobile(window.innerWidth < 768);
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);


    const canvasRef = React.useRef(null);

    const navigate = useNavigate();

    const handleStudio = () => {
        dispatch({ type: 'ADD_DESIGN', payload: design });
        navigate("/studio");
    }

    return (
        <Button
            variant=''
            className='mx-2 px-1 buttonStyle-accent ImageUploadCloud cursorPointer'
            onClick={handleStudio}
            >
            <div
                className="cursorPointer"
                style={{width: "auto", fontSize: "calc(10px + 1vmin)"}}
                >
                Edit in Studio
            </div>
            { !isMobile &&
                <TShirt image={canvasImage} canvas={canvas}>
                    <MaskedImage src={image} mask={mask} canvas={canvas} canvasRef={canvasRef} />
            </TShirt>
            }
        </Button>
    )
}

const PRODUCT_NAME = "MyOilOnShirt";
const PRODUCT_DESC = "Get This Design On Any Product. To open the product catalogue and editor, select any item from the product preview below. We offer a wide range of organic cotton jumpers, hoodies, t-shirts, tote bags and more! Create your own art prints, stickers and even custom puzzles.";

const PRODUCT_PREVIEW = {
    name: PRODUCT_NAME,
    description: PRODUCT_DESC,
    colours: "White,Black",
    // price: 20,  // TODO may require string
    item_code: "RNA1",
}
