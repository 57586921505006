import React, { useEffect, useState, useRef } from 'react';
import './StudioCanvas.css';
import { design_url } from '../const';

export const THEME = {
    light: {
        border: '#000000',
        center: '#0a5d00',
    },
    dark: {
        border: '#c0c0c0',
        center: '#1fc600',
    },
};

const ICON_SIZE = {
    rel: 0.08,
    min: 75,  // TODO scale with screen size?
    pad: 0.15,
};

const DX_SNAP_CENTRE = 0.025;

export default function StudioCanvas(props) {
    const canvasRef = props.canvasRef;
    const canvas = props.canvas;
    const layers = props.layers;
    const active = props.active;
    const theme = props.theme || THEME.light;

    const cWidth = canvas.width;
    const cHeight = canvas.height;

    const [images, setImages] = useState([]);
    const [dragging, setDragging] = useState({});
    const [lastLoaded, setLastLoaded] = useState(null);
    const [svg, setSVG] = useState({});
    const isDragging = dragging.prev;
    const isScaling = isDragging && dragging.scale;

    const loaded = (images.length === layers.length) && images.every(i => i.complete);
    const layerHash = layers.map((l) => l.id).join('');

    const resetDragging = (layer) => setDragging({ layer });

    const minShow = 0.2;
    const maxHide = 1 - minShow;
    const minScale = 0.3;
    const maxScale = 2.;

    const currentPos = (i) => {
        let [dx, dy] = layers[i].pos;
        if (isDragging && dragging.layer == i && !dragging.scale) {
            const image = images[i];
            const scale = layers[i].scale;
            const dW = scale * image.width;
            const dH = scale * image.height;
            dx = Math.min(cWidth - minShow * dW, Math.max(-maxHide * dW, dx + dragging.delta[0]));
            dy = Math.min(cHeight - minShow * dH, Math.max(-maxHide * dH, dy + dragging.delta[1]));
            const centre = (cWidth - dW) / 2;
            dx = Math.abs(centre - dx) < DX_SNAP_CENTRE * cWidth ? centre : dx;
        }
        return [dx, dy]
    }

    const currentScale = (i) => {
        const scale = layers[i].scale;
        if (!isScaling || dragging.layer != i) { return scale }
        const image = images[i];
        return Math.min(maxScale, Math.max(minScale, scale + Math.max(
            dragging.delta[0] / image.width,
            dragging.delta[1] / image.height,
        )));
    }

    const getIconSize = (dW) => Math.max(ICON_SIZE.min, ICON_SIZE.rel * dW);
    const getIconParams = (dx, dy, dW, dH) => {
        const size = getIconSize(dW);
        const dxR = dx + dW - (1 + ICON_SIZE.pad) * size;
        const dyT = dy + ICON_SIZE.pad * size;
        const dyB = dy + dH - (1 + ICON_SIZE.pad) * size;
        return { size, dxR, dyT, dyB };
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        context.clearRect(0, 0, cWidth, cHeight);

        if (!loaded) { return }

        const getImageParams = (i) => {
            const image = images[i];
            const scale = currentScale(i);
            const [dx, dy] = currentPos(i);
            const dW = scale * image.width;
            const dH = scale * image.height;
            return [dx, dy, dW, dH];
        };
        for (let i = 0; i < images.length; i++) {
            context.drawImage(images[i], ...getImageParams(i));
        }
        if (dragging.layer >= 0) {
            const [dx, dy, dW, dH] = getImageParams(dragging.layer);
            // draw center line
            if (isDragging) {
                context.beginPath();
                context.setLineDash([0]);
                context.strokeStyle = theme.center;
                context.lineWidth = 4;
                context.moveTo(cWidth / 2, 0);
                context.lineTo(cWidth / 2, cHeight);
                // context.moveTo(0, cHeight / 2);
                // context.lineTo(cWidth, cHeight / 2);
                context.stroke();
            }
            // draw border
            context.beginPath();
            context.setLineDash([15, 15]);
            context.strokeStyle = theme.border;
            context.lineWidth = 6;
            context.strokeRect(dx, dy, dW, dH);
            // draw icons
            const icon = getIconParams(dx, dy, dW, dH);
            if (svg.trash) {
                context.drawImage(
                    svg.trash, icon.dxR, icon.dyT, icon.size, icon.size);
            }
            if (svg.scale) {
                context.drawImage(svg.scale, icon.dxR, icon.dyB, icon.size, icon.size);
            }
        }
    }, [layers, lastLoaded, dragging]);

    useEffect(() => {
        const trash = new Image();
        trash.src = "./studio_trash.svg";
        const scale = new Image();
        scale.src = "./studio_expand.svg";
        setSVG({ trash, scale });
    }, []);

    useEffect(() => {
        resetDragging();
        const images = layers.map(l => {
            const image = new Image();
            image.src = l.image || design_url(l.id);
            image.onload = () => setLastLoaded(l.id);
            image.onerror = () => setImages([]);
            return image
        });
        setImages(images);
    }, [layerHash]);

    const dxScale = canvasRef.current && cWidth / canvasRef.current.offsetWidth;
    const dyScale = canvasRef.current && cHeight / canvasRef.current.offsetHeight;

    const startDrag = (e) => {
        const bcr = e.target.getBoundingClientRect();
        const offsetX = e.offsetX || (e.clientX - bcr.x);
        const offsetY = e.offsetY || (e.clientY - bcr.y);
        const [px, py] = [offsetX * dxScale, offsetY * dyScale];
        const overlaps = (layer, idx) => {
            const [dx, dy] = layer.pos;
            if (px < dx || py < dy) { return false }
            const image = images[idx];
            const scale = layer.scale;
            const dW = scale * image.width;
            const dH = scale * image.height;
            return image && (px < dx + dW) && (py < dy + dH);
        }
        const layer = layers.findLastIndex((l, i) => overlaps(l, i));
        if (layer === dragging.layer) {
            // check for icon clicks on selected layer
            const [dx, dy] = layers[layer].pos;
            const scale = layers[layer].scale;
            const image = images[layer];
            const dW = scale * image.width;
            const dH = scale * image.height;

            const icon = getIconParams(dx, dy, dW, dH);
            if (px > icon.dxR) {
                if (py < icon.dyT + icon.size) {
                    // upper right corner
                    return deleteLayer(layer);
                }
            }
            if (px > icon.dxR) {
                if (py > icon.dyB) {
                    // lower right corner
                    return setDragging({
                        layer,
                        prev: [e.pageX, e.pageY],
                        delta: [0, 0],
                        scale: true,
                    });
                }
            }
        }
        if (layer >= 0) {
            setDragging({
                layer,
                prev: [e.pageX, e.pageY],
                delta: [0, 0],
            });
        } else {
            resetDragging();
        }
    }

    const drag = (e) => {
        const dx = dxScale * (e.pageX - dragging.prev[0]);
        const dy = dyScale * (e.pageY - dragging.prev[1]);

        setDragging({
            ...dragging,
            delta: [dx, dy],
        })
    };

    const touchDrag = (e) => {
        if (e.touches.length > 1) {
            resetDragging();
        } else {
            drag(e.targetTouches[0]);
        }
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 1) {
            startDrag(e.targetTouches[0]);
        }
    };

    useEffect(() => {
        if (dragging.prev && active) {
            window.addEventListener("mousemove", drag);
            window.addEventListener("touchmove", touchDrag);
            return () => {
                window.removeEventListener("mousemove", drag);
                window.removeEventListener("touchmove", touchDrag);
            };
        }
    }, [dragging]);

    const stopDrag = () => {
        if (dragging.prev) {
            props.onChange({
                layer: dragging.layer,
                pos: currentPos(dragging.layer),
                scale: currentScale(dragging.layer),
            });
            resetDragging(dragging.layer);
        }
    };

    useEffect(() => {
        if (!active) { return }
        window.addEventListener("mousedown", startDrag);
        window.addEventListener("touchstart", handleTouchStart);
        window.addEventListener("mouseup", stopDrag);
        window.addEventListener("touchend", stopDrag);
        window.addEventListener("touchcancel", stopDrag);
        return () => {
            window.removeEventListener("mousedown", startDrag);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("mouseup", stopDrag);
            window.removeEventListener("touchend", stopDrag);
            window.removeEventListener("touchcancel", stopDrag);
        };
    }, [dragging, active]);

    const deleteLayer = (i) => {
        if (dragging.layer >= 0) {
            props.onDelete(dragging.layer);
            resetDragging();
        }
    };

    const handleKeyDown = (e) => {
        if (e.key == "Delete") {
            deleteLayer(dragging.layer);
        }
    };

    return <canvas ref={canvasRef} className="Canvas" width={cWidth} height={cHeight} onKeyDown={handleKeyDown} tabIndex="0" />
}