import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import axios from 'axios';
import Alert from 'react-bootstrap/Alert';
// import TeemillButton from './TeemillButton';
import './ProductCard.css';
import './DesignCard.css';
import { useDesigns } from '@oos/core/components/DesignsContext';
import { stackSVG, magicSVG } from './SVGs';
import { downloadImage } from '../download.js';
import { useStudio } from '../contexts/StudioContext.js';
import { useShop } from '../contexts/ShopContext.js';

export default function DesignCard(props) {
    const designsContext = useDesigns();
    const studioContext = useStudio();
    const shopContext = useShop();

    const design = props.design;

    const [showAlert, setShowAlert] = React.useState(false);

    const removeFromStudio = (products) => products.has(studioContext.studio.id) && studioContext.dispatch(
        { type: 'SET_ID', payload: null }
    );

    const handleRemove = () => axios({
        method: "delete",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/designs/${design.id}`,
    })
        .then((res) => {
            const data = res.data;
            const products = new Set(data.products);
            removeFromStudio(products);
            shopContext.dispatch({ type: 'REMOVE_PRODUCTS', payload: products });
            designsContext.dispatch({ type: 'REMOVE_DESIGN', payload: data.design });
            studioContext.dispatch({ type: 'REMOVE_DESIGN', payload: data.design });
        })
        .catch((res) => console.log(res));
    
    const handleSelect = () => {
        studioContext.dispatch({ type: 'ADD_DESIGN', payload: design });
        props.onSelect();
    };

    return (
        <>
            <Container fluid className='my-2 ProductCard-tabs'>
                <Col className='mb-4'>
                    <div className='DesignCard-Container'>
                        <img className='DesignCard-image' id="design" src={design.image}></img>

                        <div className='DesignCard-Overlay'>
                            <Card.Body>
                                {/* <Card.Title className='ProductCard-text'>{base.title}</Card.Title> */}
                                <Button
                                    variant=''
                                    style={{width: "45%", maxHeight: "15vw", fontSize: "calc(10px + 0.2vw)"}}
                                    className='mx-1 p-1 my-1 buttonStyle-accent cursorPointer'
                                    onClick={handleSelect}>
                                    {magicSVG} Use Design
                                </Button>
                                <Button
                                    variant=""
                                    style={{width: "45%", maxHeight: "15vw", fontSize: "calc(10px + 0.2vw)"}}
                                    className='mx-1 p-1 my-1 buttonStyle-accent cursorPointer'
                                    onClick={() => downloadImage(design.image)}>
                                    {stackSVG} Download
                                </Button>
                                <br />
                                {!showAlert && <Button onClick={() => setShowAlert(true)} variant="" className='my-1 buttonStyle-brown-solid' style={{fontSize: "calc(10px + 0.2vw)",width: "45%"}}>Delete</Button>}
                            </Card.Body>

                            <Alert show={showAlert} variant="danger" className='my-3 mx-2 ProductAlert'>
                                <Alert.Heading>Are you sure?</Alert.Heading>
                                {/* <p>
                                Deleting from your designs will also delete from your cart.
                                </p> */}
                                <hr />
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={handleRemove}
                                        size="sm"
                                        variant='outline-danger'
                                        className='my-2 mx-1 buttonStyle-lavender'>
                                        Yes, delete.
                                    </Button>
                                    <Button
                                        onClick={() => setShowAlert(false)}
                                        size="sm"
                                        variant="outline-light"
                                        className='my-2 mx-1 buttonStyle-lavender'>
                                        Cancel
                                    </Button>
                                </div>
                            </Alert>
                        </div>
                    </div>
                </Col>
            </Container>
        </>
    )
}