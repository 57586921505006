import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import './Sustainability.css';
import { TabNavItem, TabContent } from "../components/TabContent";
import Button from 'react-bootstrap/esm/Button';
import Figure from 'react-bootstrap/Figure';
import { certifiedSVG } from '../components/SVGs';
import MagnifyHover from '../components/MagnifyHover';
import Values from '../components/Values';

export default function Sustainability() {

    const [activeTab, setActiveTab] = useState("tab1");
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener')
    };

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">Sustainability</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">GOTS certified organic cotton {certifiedSVG} | Circular by Design</h2>
                <a href="#Circular"><Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent">Circular Supply Chain</Button></a>
            </div>
            <div className="Sustainability-tabs">
                <h2 className="display-5 my-3">PRINCIPLES</h2>
                <Values />
                <Row className='mx-4'>
                    <Col className='Sustainability-intro'>
                        <p>Our personalised print clothing and art prints are printed on demand with a low waste printing technology by our manufacturing partner Teemill. Our garments are constructed from organic cotton only, and are made to be remade.</p>
                    </Col>
                </Row>
                <Row>
                    <section className="SustainabilitySection" style={{backgroundImage: `url(${"/sustainability_circular_shirt.webp"}`}}>
                        <Row>
                            <Col xs={{span: 10, offset: 1}} className="my-4">
                                <div className="Sustainability-tabs-overlay Sustainability-bg-col">
                                    <div style={{height:"2vh"}} />
                                    <p className="Sustainability-heading">Organic</p>
                                    <div style={{height:"1vh"}} />
                                    <p className="Sustainability-tabs-overlay-text">Better for the environment and the people who grow and work with cotton. Instead of toxic pesticides and fertilisers, insect traps, co-planting and cow-poo are used. This supports biodiversity.</p>
                                </div>
                            </Col>

                            <Col xs={{span: 10, offset: 1}} className="my-4">
                                <div className="Sustainability-tabs-overlay Sustainability-bg-col">
                                    <div style={{height:"2vh"}} />
                                    <p className="Sustainability-heading">Circular</p>
                                    <div style={{height:"1vh"}} />
                                    <p className="Sustainability-tabs-overlay-text"> Recyclability is indispensable for a more sustainable supply chain. All our products have a QR code explaining how you can send back worn out items. Those will be remanufactured into new products.</p>
                                </div>
                            </Col>

                            <Col xs={{span: 10, offset: 1}} className="my-4">
                                <div className="Sustainability-tabs-overlay Sustainability-bg-col">
                                    <div style={{height:"2vh"}} />
                                    <p className="Sustainability-heading">On Demand</p>
                                    <div style={{height:"1vh"}} />
                                    <p className="Sustainability-tabs-overlay-text">Products are printed in real time, only when needed. This reduces waste as we do not keep stock of pre-printed items. We use water based inks and a digital printing technique.</p>
                                </div>
                            </Col>
                        </Row>
                    </section>
                </Row>
                <Button
                    size="md"
                    variant=''
                    className="my-5 buttonStyle-accent"
                    onClick={() => openInNewTab("https://teemill.com/the-journey/")}
                    >More Supply Chain Info
                </Button>
            </div>

            <div className="Sustainability-tabs">
                <h1 className="display-5 my-3">The nitty-gritty</h1>
                <ul className="Sustainability-nav">
                    <TabNavItem title="Our Manufacturing Partner" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="Our Products" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>

                <TabContent id="tab1" activeTab={activeTab}>
                    <div className="Sustainability-text">
                        <h1>Teemill</h1>
                        <p>OilOnShirt proudly partnered up with Teemill to make our proposition possible. Sustainable clothing shouldn’t be a luxury, only affordable by the few. It should be the standard for all.</p>
                        <h1>GOTS certified</h1>
                        <p>GOTS stands for Global Organic Textile Standard and certifies products made from organic textiles AND under ecologically and socially responsible conditions.</p>
                        <p>For your reference, click on the button below to find our manufacturing partner Teemill Tech Ltd. listed on the GOTS public database (License number: DK25920).</p>

                        <Button variant="" onClick={() => openInNewTab("https://global-standard.org/find-suppliers-shops-and-inputs/certified-suppliers/database/search_result/19000")}>
                            <img src="/GOTS_logo.png" style={{width:"calc(30px + 2vw", marginRight:"20px"}}/>
                            <Button size="md" variant='' className="my-3 buttonStyle-brown">
                                Teemill GOTS
                            </Button>
                        </Button>

                        <hr style={{height:"10px"}}/>

                        <a id="Circular">
                            <Figure>
                                <MagnifyHover image="/teemill_supply_chain.jpeg" imageAlt="Circular supply chain organic cotton clothing"/>
                                <Figure.Caption>Teemill Circular Supply Chain</Figure.Caption>
                            </Figure>
                        </a>
                    </div>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    <Row>
                        <Col md={6}>
                            <h1 className="Sustainability-heading-sm">Certified Organic Cotton Clothes</h1>
                            <div className="Sustainability-text">
                            <p>All our products are made from GOTS certified organic cotton. Some of them are made from 50% post-consumer organic cotton / 50% organic cotton. For example our Circular Economy Organic Cotton Sweater or our Circular Economy Organic T-Shirts. Wash cool, hang dry. GM free. Not tested on animals. Does not contain animal-derived products. Printed in the UK with low waste printing tech. Made in a renewable energy powered factory audited for a wide range of social and sustainability criteria.</p>
                            {/* <h1>Large Portrait Art Print</h1>
                            <p>29.7 x 42 cm (11.6 x 16.5 in) (A3) printed on 300gsm 100% recycled paper.</p> */}
                            </div>
                            <Button size="" variant="" className="my-3 buttonStyle-brown-solid" style={{color: "#ffeadb"}} as={Link} to="/collection/all">All Products</Button>
                        </Col>
                        <Col>
                            <Figure>
                                <Figure.Image
                                    className="Sustainability-img"
                                    src="/sustainability_packaging.webp"
                                    fluid
                                    />
                                <Figure.Caption>Everything ships in plastic-free paper packaging.</Figure.Caption>
                            </Figure>
                        </Col>
                    </Row>
                </TabContent>
            </div>
        </>
    )
}