import PageTitle from "@oos/core/components/PageTitle";

export default function Page(props) {
    const title = props.title || "CUSTOM ORGANIC AI ART CLOTHES";
    return (
        <div style={{marginTop: "calc(60px + 0.5vw)"}}>
            <PageTitle title={title}>
                {props.page}
            </PageTitle>
        </div>
    );
}