import React from "react";
import "./Gallery.css";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Gallery(props) {
    return (
        <Row className="About-tabs">
            {props.gallery.map((img) => (
            <Col xs={3} key={img.id}>
                <Link to="/studio" className="Gallery-container">
                    <img
                        // className="d-block w-100 Home-gallery-img Collection-tabs-img"
                        className="Collection-tabs-img my-2 Gallery-show"
                        style={{width: "100%"}}
                        src={img.image}
                        alt="Ai image generator for organic print on demand clothing"
                    />
                    <div className='Gallery-description Gallery-hide'>{img.desc}</div>
                </Link>
            </Col>
            ))}
        </Row>
    )
}
export function GalleryProducts(props) {
    return (
        <Row>
            {props.gallery.map((img) => (
            <Col xs={12} lg={6} key={img.id}>
                <Button size="" variant="" as={Link} to={`/product/${img.slug}`}>
                    <img
                        className="hover-zoom-light Collection-images"
                        src={img.image}
                        alt="Plain Organic Cotton Clothing"
                    />
                    <div className='Collection-catalogue-text'>
                        <p>{img.desc}</p>
                    </div>
                </Button>
            </Col>
            ))}
        </Row>
    )
}