import React from "react";
import './SelectProductQuantity.css';
import Button from 'react-bootstrap/esm/Button';
import { minusSVG, plusSVG, trashSVG } from "./SVGs";


export default function SelectProductQuantity(props) {
    const qty = props.qty;
    const onChange = props.onChange;

    const incrementQty = () => onChange(qty + 1);
    const decrementQty = () => onChange(qty - 1);

    return (
        <>
            <div className='ProductCard-text'>Quantity: {qty}</div>
            <Button variant='' onClick={decrementQty} className="mx-1 buttonStyle-brown" style={{border:"none"}} disabled={qty <= 1}>{minusSVG}</Button>
            <Button variant='' onClick={incrementQty} className="mx-1 buttonStyle-brown" style={{border:"none"}} >{plusSVG}</Button>
            <Button variant='' className="buttonStyle-brown" onClick={props.onRemove}>{trashSVG}</Button>
        </>
    )
}