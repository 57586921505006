import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Carousel } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { stackSVG, closeRoundSVG, arrowLeftSVG, arrowRightSVG, checkSVG, arrowCounterClockSVG, magicSVG, brushSVG } from '../components/SVGs';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from '@oos/core/components/ProgressBar';
import { shuffleArray } from '@oos/core/shuffle';
import ImageRadios from '@oos/core/components/ImageRadios';
import { useDesigns } from '@oos/core/components/DesignsContext';
import PaintingLoader from './PaintingLoader';
import { TabNavItem } from '../components/TabContent';
import { TabContent } from '../components/TabContent';
import AiOutput from './AiOutput';
import './AiTool.css';

export const STYLES = [
    {image: "/samples_7.webp", id: "continuousLine", title: "Lineart", desc: "sitting cat cleaning its paw"},
    {image: "/samples_4.webp", id: "organicLine", title: "Lineart Strong", desc: "woman's head seen from the side wearing a hat"},
    {image: "/style_lineart_framed.webp", id: "framedLine", title: "Lineart Framed", desc: "a hiker in an autumn landscape"},
    {image: "/samples_1.webp", id: "flowerCircle", title: "Flower Circle", desc: "cute cat face surrounded by flowers"},
    {image: "/samples_11.webp", id: "minimalistCol", title: "Colour Minimal", desc: "bull head with colourfull feathers"},
    {image: "/samples_10.webp", id: "vibrantCol", title: "Colour Vibrant", desc: "yosemite mountain"},
    {image: "/style_festiveOrnament.webp", id: "festiveOrnament", title: "Festive Ornament", desc: "skiing reindeer"},
    {image: "/style_winterLandscape.webp", id: "winterLandscape", title: "Winter Landscape", desc: "female hiker and her dog"},
    {image: "/style_winterKawaii.webp", id: "winterKawaii", title: "Winter Pastel", desc: "happy skiing bear"},
    {image: "/style_winterMinimalism.webp", id: "winterMinimalistic", title: "Minimalistic", desc: "snow covered mountain"},
];
export const PROMPTS = [
    {desc: "Cute space cat", id: "1"},
    {desc: "Close up lion face", id: "2"},
    {desc: "Roaring bear portrait", id: "3"},
    {desc: "A hiker in a beautiful autumn landscape, featuring trees and mountains", id: "4"},
    {desc: "Half Dome reflected in the Merced River", id: "5"},
    {desc: "Astronaut on a horse", id: "6"},
    {desc: "Mountain landscape", id: "7"},
    {desc: "Desert with cacti", id: "8"},
    {desc: "Cat with colourful feathers", id: "9"},
    {desc: "Snow covered mountain", id: "10"},
    {desc: "Skiing reindeer", id: "11"},
    {desc: "A female hiker and her dog", id: "12"},
    {desc: "Robot Santa decorating a Christmas tree", id: "13"},
    {desc: "A sea-turtle caught in a fishing net and surrounded by some ocean plastic", id: "14"},
    {desc: "A beautiful underwater ocean world featuring fish and a coral reef", id: "15"},
];

// export const SLOGANS = [
//     {desc: "Wanderlust", id: "1"},
//     {desc: "Seize the day", id: "2"},
//     {desc: "Safe the Oceans", id: "3"},
//     {desc: "Catfluencer", id: "4"},
//     {desc: "Nature is Calling", id: "5"},
// ]

export default function AiTool(props) {
    const {dispatch} = useDesigns();
    const [activeTab, setActiveTab] = useState("tab1");
    const [description, setDescription] = useState("");
    const [slogan, setSlogan] = useState("");
    const [style, setStyle] = useState(STYLES[0]);
    const [prompts, setPrompts] = useState();
    const [designs, setDesigns] = useState({
        available: [],
    });

    const max_length = props.max_length || 150;
    const max_length_slogan = props.max_length_slogan || 50;
    const blur = !designs.available.length && activeTab == "tab2";

    useEffect(() => {
        setPrompts({
            prompts: shuffleArray(PROMPTS).slice(0, 1),
            // slogans: shuffleArray(SLOGANS).slice(0, 6),
        })
    }, []);

    const resetDesigns = (loading, errorMsg) => setDesigns({ available: [], loading, errorMsg });

    const handleSelectStyle = (id) => {
        setStyle(STYLES.find(e => e.id === id));
        // setActiveTab("tab2");
        window.dataLayer.push({
            'event': 'SelectStyle',
        });
        console.log('SelectStyle');
    }

    const handleDesignError = (res) => {
        switch (res.status) {
            case 400:
                return "Sorry, something went wrong... please try another style."
            case 500:
                return "Sorry, something went wrong... please try again later."
            case 429:
                return "Sorry, you've reached your daily limit of free stories."
            case 503:
                return "Sorry, our total daily budget for free stories has run out..."
        }
        return DEFAULT_DESIGN_ERROR_MSG
    };

    const handleCreate = () => {
        setActiveTab("tab2");
        window.dataLayer.push({
            'event': 'GenerateNow',
        });
        if (!description || description.length < 1) {
            setDesigns({ ...designs, errorMsg: "Please describe your print" });
            return
        }
        resetDesigns(true);
        return axios({
            method: "post",
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/designs`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ content: description, style: style.id, slogan: slogan }),
        })
        .then((res) => {
            console.log(res);
            dispatch({ type: 'ADD_NEW_DESIGNS', payload: res.data });
            setDesigns({available: res.data});
        })
        .catch((err) => {
            console.log(err);
            const errorMsg = err.response ? handleDesignError(err.response) : DEFAULT_DESIGN_ERROR_MSG;
            resetDesigns(false, errorMsg);
        })
    };

    const handleReset = () => {
        resetDesigns();
        setActiveTab("tab1");
    }

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/studio");
    }

    return (
        <>
            <div className={blur ? "AiTool-overlay" : null}>

                <TabContent id="tab1" activeTab={activeTab}>

                    <div style={{textAlign: 'end'}}>
                        <Button variant="" className="p-2 mx-2 my-2 cursorPointer" onClick={handleBack}>{closeRoundSVG} Close</Button>
                    </div>

                    <Row>
                        <Col md={6} xs={12}>
                            <Accordion defaultActiveKey="0" style={{marginBottom:"2vw"}} flush>
                                <Accordion.Item eventKey="0" style={{backgroundColor:"transparent"}}>
                                    <Accordion.Header>
                                        <img src="/samples_7.webp" style={{width:"calc(15px + 2vw)", marginRight:"calc(5px + 2vw)"}}/>
                                        <span className="AiTool-style-name">Lineart</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ImageRadios inputs={(STYLES).slice(0,3)} value={style.id} onChange={handleSelectStyle} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1" style={{backgroundColor:"transparent"}}>
                                    <Accordion.Header>
                                        <img src="/samples_11.webp" style={{width:"calc(15px + 2vw)", marginRight:"calc(5px + 2vw)"}}/>
                                        <span className="AiTool-style-name">Colour</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ImageRadios inputs={(STYLES).slice(3,6)} value={style.id} onChange={handleSelectStyle} />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2" style={{backgroundColor:"transparent"}}>
                                    <Accordion.Header>
                                        <img src="/style_winterMinimalism.webp" style={{width:"calc(15px + 2vw)", marginRight:"calc(5px + 2vw)"}}/>
                                        <span className="AiTool-style-name">X-Mas</span>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <ImageRadios inputs={(STYLES).slice(6,10)} value={style.id} onChange={handleSelectStyle} />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={6} xs={12}>
                        <label htmlFor="printDesignText">
                            <textarea
                                className='AiTool-input'
                                id="printDesignText"
                                name="description"
                                value={description}
                                rows={4}
                                maxLength={max_length}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Describe what you want to see..."
                                autoFocus
                            />
                            <div className="" style={{textAlign: 'end'}}>{description.length}/{max_length}</div>
                        </label>
                        {!designs.loading && <>
                            {description &&
                            <>
                                <div style={{height:"1vh"}} />
                                <Button
                                    variant=""
                                    className="cursorPointer p-2 my-2 buttonStyle-accent"
                                    onClick={handleCreate}>
                                    Generate Now
                                </Button>
                            </>}
                            <div style={{ height: "1vh" }} />
                        </>}
                        { !description && prompts && !designs.errorMsg && <>
                            <AddPrompt text={prompts.prompts} onClick={setDescription} />
                        </>}
                        <div style={{height:"1vh"}} />
                        </Col>
                    </Row>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    { designs.errorMsg ? <>
                        <div style={{height:"25vh"}} />
                        <div>{designs.errorMsg}</div>
                        <Button
                        variant=""
                        className="cursorPointer p-1 mx-2 AiTool-text"
                        onClick={handleReset}
                        style={{width: "10vw", maxHeight: "20vw"}}
                        >{closeRoundSVG} Close
                        </Button>
                    </> : <>
                        {designs.loading && <div className='AiTool-overlay-content'>
                            <div style={{padding:"10px"}}>{brushSVG}</div>
                            <ProgressBar duration={16} tick={1000} />
                        </div>}
                    </>}
                    {designs.available.length ? <AiOutput designs={designs.available} onReset={handleReset} /> : null}
                    <div style={{height:"2vh"}} />
                </TabContent>
            </div>
        </>
    )
}

const DEFAULT_DESIGN_ERROR_MSG = "Sorry, something went wrong... please try again.";

function AddPrompt(props) {
    return (
        <Row>
            {props.text.map((p) => (
            <Col key={p.id} className="my-2">
                 <Button
                    style={{width:"25vw", fontSize: "2vmin"}}
                    className="cursorPointer p-2 buttonStyle-lavender"
                    variant=''
                    onClick = {() => props.onClick(p.desc)}
                    >{magicSVG} Surprise me
                </Button>
            </Col>
            ))}
        </Row>
    )
}

// function AddSlogan(props) {
//     return (
//         <Row className="Home-gallery Home-gallery-products">
//             {props.text.map((m) => (
//             <Col xs={4} key={m.id} className="my-2">
//                  <Button
//                     className="cursorPointer p-2 buttonStyle-black AiTool-text"
//                     onClick = {() => props.onClick(m.desc)}
//                     >{m.desc}
//                 </Button>
//             </Col>
//             ))}
//         </Row>
//     )
// }

