import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./DeliveryModal.css";
import { boxSVG } from "../../web/src/components/SVGs";

export default function DeliveryModal(props) {
    return(
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <div className="DeliveryModal-h1">Delivery</div>
            </Modal.Header>
            <Modal.Body className="DeliveryModal-text">
                <p className="DeliveryModal-h1">{boxSVG} FREE UK shipping for orders from £50</p>
                <h2 className="DeliveryModal-text">How fast are orders shipped?</h2>
                <p>Most orders are printed by our manufacturing partner Teemill on the same working day. Please see shipping options below.</p>
                <h2 className="DeliveryModal-text">Shipping costs & times</h2>
                <ul>
                    <li>UK Standard £4.00 (Delivery aim 2-3 days)</li>
                    <li>UK 1st Class £6.00 (Delivery aim 1-2 days)</li>
                    <li>UK Priority £9.50 (UK next day; ONLY for orders placed before 10 am GMT)</li>
                    <li>EU Standard £6.00 (Delivery aim 3-5 working days)</li>
                    <li>EU Tracked & Signed £15.00</li>
                    <li>International £7.00 (Delivery aim 6-7 working days)</li>
                    <li>International Tracked & Signed £15.50</li>
                </ul>
                <p>Once your order is being processed, you'll receive an email confirmation including an estimated delivery date. Public, bank or international holidays may affect shipping times.</p>
                <h2 className="DeliveryModal-text">Where do you ship to?</h2>
                <p>We deliver to the UK, most EU countries and the US. You can see the full list of countries that we ship to at checkout, just before you pay for your order.</p>
            </Modal.Body>
            <Modal.Footer className="DeliveryModal-text me-auto">
                <p>Contact us any time at hello@oilonshirt.com</p>
            </Modal.Footer>
        </Modal>
    )
}