import { GalleryProducts } from '../components/Gallery';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const PRODUCTGALLERY = [
    {image: "men_longline1.jpeg", desc:"Men's Long Line Organic T-shirt | from £22", slug: "mens-longline-t-shirt-100-organic-cotton", id: "1"},
    {image: "men_hoodie.jpeg", desc:"Men's Organic Cotton Hoodie | from £45", slug: "mens-organic-cotton-custom-hoodie", id: "2"},
    {image: "women_relaxedfit_tshirt.jpeg", desc:"Women's Organic Relaxed Fit T-Shirt | from £22", slug: "women-relaxed-fit-tee", id: "3"},
    {image: "women_oversized_sweater_plain.jpeg", desc:"Women's Organic Cotton Oversized Sweater | from £40", slug: "womens-organic-oversized-sweater-customisable", id: "4"},
]

export default function CollectionFeature() {

    return(
        <>
            <Container fluid className="Studio-options" style={{width:"95vw", margin:"auto"}}>
                <div className="display-5">Best Sellers</div>
                <div style={{height: "2vh"}} />
                <GalleryProducts gallery={PRODUCTGALLERY}/>
                <Button variant='' className="my-2 buttonStyle-accent" as={Link} to="/collection/all">Browse All</Button>
                <div style={{height: "2vh"}} />
                <div className="Home-highlight Home-move Home-tagline">FREE UK SHIPPING FROM £50</div>
            </Container>
        </>
    )
}
