import React from 'react';
import './App.css';
import Navigation from './components/Navigation.js';
import Footer from './components/Footer.js';
import { Outlet } from "react-router-dom";
import { DesignsProvider } from '@oos/core/components/DesignsContext';
import { StudioProvider } from './contexts/StudioContext.js';
import {IntlProvider} from 'react-intl';
import { ShopProvider } from './contexts/ShopContext.js';
import { LocaleProvider } from './contexts/LocaleContext.js';



function App() {
	return (
		<div className="App">
			{/* <IntlProvider locale='en'> */}
			<LocaleProvider>
				<DesignsProvider>
					<StudioProvider>
						<ShopProvider>
							<Navigation />
							<Outlet />
							<Footer />
						</ShopProvider>
					</StudioProvider>
				</DesignsProvider>
			</LocaleProvider>
			{/* </IntlProvider> */}
		</div>
	);
}


export default App;