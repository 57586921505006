import { useEffect } from "react";

export default function PageTitle(props) {
    const title = props.title

    useEffect (() => {
        document.title = title;
    }, [title]);

    return props.children;
}