import { Link } from 'react-router-dom';
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import './About.css';
import Image from "react-bootstrap/esm/Image";
import Button from 'react-bootstrap/esm/Button';
import EMailSignup from '../EmailSignup';
import BlogBanner from './BlogBanner';
import { Typewriter } from '../Typewriter';


const TYPEWRITER = [
    { text: "in line art", image: '/blog/sample_continuousLine.webp' },
    { text: "in minimalistic colours", image: '/blog/sample_winterMinimalistic.webp' },
    { text: "in a festive ornament", image: '/blog/sample_festiveOrnament.webp' },
    { text: "in a winter landscape", image: '/blog/sample_winterLandscape.webp' },
    { text: "in winter pastels", image: '/blog/sample_winterKawaii.webp' },
];

export default function HowToXMas() {

    const CHRISTMAS_PRINT = [
        {image: "/blog/sample_continuousLine.webp", id:"1", desc:"Lineart", alt:"custom Christmas sweater print with Skiing Santa in line art"},
        {image: "/blog/sample_festiveOrnament.webp", id:"2", desc:"Festive Ornament", alt:"custom Christmas sweater print with Skiing Santa in a festive ornament"},
        {image: "/blog/sample_minimalistCol.webp", id:"3", desc:"Colour Minimal", alt:"custom Christmas sweater print with Skiing Santa in minimalistic colours"},
        {image: "/blog/sample_winterKawaii.webp", id:"4", desc:"Winter Pastel", alt:"custom Christmas sweater print with Skiing Santa in Kawaii pastel"},
        {image: "/blog/sample_winterLandscape.webp", id:"5", desc:"Winter Landscape", alt:"custom Christmas sweater print with Skiing Santa in a winter landscape"},
        {image: "/blog/sample_winterMinimalistic.webp", id:"6", desc:"Minimalistic", alt:"custom Christmas sweater print with Skiing Santa in a minimalistic winter style"},
    ];

    return (
        <>
            <div className="Sustainability-tabs-overlay Sustainability-bg-col" style={{backgroundColor: "#dcd6f780"}}>
                <div style={{height:"2vh"}} />
                <h1 className="Sustainability-title">How to design my own Christmas Sweater</h1>
                <div style={{height:"1vh"}} />
                <h2 className="Sustainability-heading-sm">Text to image Ai Christmas Prints</h2>
                <Button style={{width:"30vw"}} variant="" className="my-3 buttonStyle-accent" as={Link} to="/studio/print">Design Now</Button>
            </div>

            <section id="HomeSection3" style={{backgroundImage: `url(${"/blog/oos_blog_christmas_sweater2.jpeg"}`}} role='img' title='Organic Cotton Christmas Sweater'>
                <Container className="my-4 Home-background-color">
                    <Row className="Home-column-height-l align-items-center">
                        <Col>
                            <div className="">
                                <div style={{height:"10vw"}} />
                                <Typewriter images = {TYPEWRITER} imageClass="Typewriter-img-xmas" textFix="Skiing robot santa"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div style={{height: "calc(15px + 10vh)"}} />

            <h1 className='About-heading' style={{fontFamily:"Yeseva One"}}>Design your own Christmas Sweater</h1>
            <h2 className='About-heading-sm'>in 3 Steps with Text to Image Ai</h2>

            <div style={{height:"7vh"}}/>

            <h4 className="About-heading">1. Describe your Christmas print</h4>
            <p className="About-text">We came up with some jolly Christmas styles for you 🧑🏼‍🎄 so you can create out of the box Christmas themed print designs! Of course, you can also use our standard lineart and colour styles to generate your custom Christmas sweater design.</p>

            <Link to="/studio">
                <Image src="/blog/oos_blog_xmas_styles.webp" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
            </Link>
            <p className="About-text">While keeping your text prompt the same, e.g. "Skiing robot santa", experiment with different styles to generate your desired output. Remember, each creation will be unique and no two design outputs will ever be the same!</p>

            <Link to="/studio">
                <Row style={{width:"90%", margin:"auto"}}>
                    {CHRISTMAS_PRINT.map((print) =>
                        <Col xs={4} xl={2} key={print.id}>
                            <Image src={print.image} alt={print.alt} className="my-2" fluid></Image>
                            <div>{print.desc}</div>
                        </Col>
                    )}
                </Row>
            </Link>
            <p className="About-text">Tip: This may sound trivial, but make sure to use your words very intentionally and only type in what you actually want to see in your design. Every word will be given special attention in the creation process 👩‍🎤</p>

            <div style={{height:"7vw"}}/>

            <h4 className="About-heading">2. Mix and Match Your Designs</h4>
            <p className="About-text">You can either describe whole scenes for your Christmas sweater design, or you might want to create unique assets that you can mix and match. For example you could create different Christmas baubles and decorate your own tree. Or you could go for an extravagant "Fireplace festively decorated for Christmas" in "Colour Vibrant".</p>

            <Link to="/favourites">
                <Row style={{width:"90%", margin:"auto"}}>
                    <Col xs={12} lg={6}>
                        <Image src="/blog/oos_blog_xmas_designs.webp" alt="ai generated designs for a christmas sweater" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Image src="/blog/oos_blog_xmas_sweater.webp" alt="organic cotton christmas sweater with back print" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                </Row>
                <div style={{fontSize:"calc(8px + 0.5vw)"}}>Style: Colour Vibrant, Men's Organic Cotton Sweater with back print</div>
            </Link>

            <p className="About-text">You can resize and mix and match the creations from your favourites library. Make sure you save your creations, so you can revisit and order them later on. If you want to save the same design on a different product, click "Save Copy". The maximum print area for front and back prints is 30cm x 40cm.</p>

            <div style={{height:"7vw"}}/>

            <h3 className="Sustainability-heading-sm">Prompt ideas to get you started</h3>

            <p className="About-text">
                <ul>Full Scenes
                    <li>Robot Santa decorating a Christmas tree</li>
                    <li>Close up portrait of a labrador wearing a Christmas hat</li>
                    <li>Beautifully decorated Christmas tree with pastel baubles</li>
                    <li>A cute cat sleeping in front of a fireplace festively decorated for Christmas</li>
                </ul><span style={{color:"#21565a"}}>Best styles: Minimalistic, Winter Pastel, Lineart</span>
            </p>
            <p className="About-text">
                <ul>Small Scenes
                    <li>Hiker with his dog</li>
                    <li>Happy skiing bear</li>
                    <li>Ski Chalet</li>
                </ul><span style={{color:"#21565a"}}>Best styles: Festive Ornament, Winter Landscape</span>
            </p>
            <p className="About-text">
                <ul>Assets
                    <li>Christmas bauble</li>
                    <li>Snow covered Nordmann fir Christmas tree</li>
                    <li>Beautifully wrapped Christmas gift</li>
                </ul><span style={{color:"#21565a"}}>Best styles: Minimalistic, Winter Pastel, Lineart</span>
            </p>

            <div style={{height:"7vw"}}/>

            <h4 className="About-heading">3. Order your organic cotton product</h4>
            <Link to="/favourites">
                <p className="About-text">All our products are made from GOTS certified organic cotton and are printed on demand in the UK as you order. You can order your creations directly within the studio or from your favourites section "My Products". Select "View" to open the product preview. Select your preferred colour and click "Add to Basket" to select your desired size and quantity.</p>
                <Row style={{width:"90%", margin:"auto"}}>
                    <Col xs={12} lg={6}>
                        <Image src="/blog/oos_blog_xmas_order.webp" alt="order organic cotton christmas sweater with back print" className="my-2 About-tabs-img" fluid></Image>
                    </Col>
                    <Col xs={12} lg={6}>
                        <Image src="/blog/oos_blog_xmas_preview.webp" alt="order men's organic cotton christmas sweater" className="my-2 About-tabs-img" style={{width:"90%"}} fluid></Image>
                    </Col>
                </Row>
            </Link>

            <div style={{height:"7vw"}}/>

            <div className="About-tabs">
                <h2 className='About-heading-sm Home-title'>Still wearing off the shelf?</h2>
                <h2 className='About-heading-sm'>Wear what you care about. Happy creating!</h2>
                <Button size="lg" variant='' className="mb-5 mt-5 buttonStyle-accent" as={Link} to="/studio">Design Now</Button>
            </div>

            <div className="About-tabs">
                <h1 className="display-5 my-3">JOIN THE AI ART COMMUNITY</h1>
                <Row>
                    <Col md={6}>
                        <div className='About-text' style={{marginBottom:"0px"}}>We continuously enhance our AI design tool for you. As we are in development stage, new stuff is happening on a daily basis. Want to get notified about the big leaps? Hit the subscribe button now.</div>
                        <EMailSignup />
                    </Col>
                    <Col>
                        <Image src="/samples_11.webp" style={{width:"50%"}} className="About-tabs-img" fluid></Image>
                    </Col>
                </Row>
            </div>
        </>
    )
}