import React from "react";
import { useState, useRef, useEffect } from "react";
import './HeroImage.css';
import {brushSVG} from '../components/SVGs.js';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/esm/Container';
import Image from 'react-bootstrap/esm/Image';
import TextEffect from './Typewriter';
import { Row, Col } from "react-bootstrap";
import Video from "./Video";

export function HeroImageVideo() {

    const videoMp4 = "./video_snippets1.mp4"; /* xy.mp4#t=60"; lets video start at 60 seconds */
    const videoWebm = "./video_lake.webm";
    const poster = "./nature_poster.webp";

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function checkMobile() {
            setIsMobile(window.innerWidth < 768); /* breakpoint medium */
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return (
        <section role="contentinfo" aria-label="Custom ai art clothing with nature prints" id="HomeSection1" style={{backgroundImage: `url(${"/yosemite_landscape_banner.webp"}`}}>
        <div style={{width:"100%", height:"40vh", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Button variant="">
                <img src="logo_sweater_opacity.png" alt="OilOnShirt Ai Art Clothing Logo" style={{height:"20vh"}} />
                <div style={{fontSize:"calc(15px + 0.5vw)", backgroundColor:"#ffeadb80", backdropFilter:"blur(5px)"}} className="buttonStyle-brown">Design Now</div>
            </Button>
        </div>
        </section>
        // <div className="HeroImage-video-container"> /* TODO replace img with video once scaled down */
        //     <Video videoMp4={videoMp4} videoWebm={videoWebm} poster={poster} alt="OilOnShirt video featuring nature scenes that inspire our ai art creation for print clothing"></Video>
        //     <div className="HeroImageVideo-overlay">
        //         <img src="logo_sweater_opacity.png" alt="OilOnShirt Ai Art Clothing Logo" style={{width:"25vw"}}/>
        //     </div>
        // </div>
    )
};

export function HeroImageMove() {

    const [ isIntersecting, setIsIntersecting ] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            // { rootMargin: "-900px" }
            { threshold: "0.6" }
        );
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    return (
        <Row ref={ref} style={{width:"100vw"}}>
            {/* <Row ref={ref} style={{width:"100vw"}}> */}
            {/* <div className={isIntersecting ? "HeroImageMove-overlayButtonDown" : "HeroImageMove-overlayButtonUp"}> /* TODO calibrate Y movement */}
            <div className={"HeroImageMove-overlayButtonDown"}>
                <Button variant="" className="HeroImage-design-button">
                    <span style={{fontSize:"calc(10px + 1vw)"}}>Design Now</span><br/><span style={{fontSize:"calc(10px + 0.5vw)"}}>with</span><br/>
                    <span className="" style={{fontFamily:"Aladin", fontSize:"calc(10px + 2vw)", color:"#8d6e63"}}>OilOnShirt</span>
                </Button>
            </div>
            <Col xs={4} md={3}>
                <div className="HomeScrollingContainer">
                    <div className="HomeScrollingImg0" style={{backgroundImage:`url(${"img_loop1.webp"}`}}></div>
                </div>
            </Col>
            <Col xs={4} md={3}>
                <div className="HomeScrollingContainer">
                    <div className="HomeScrollingImg1" style={{backgroundImage:`url(${"img_loop2.webp"}`}}></div>
                </div>
            </Col>
            <Col xs={4} md={3}>
                <div className="HomeScrollingContainer">
                    <div className="HomeScrollingImg2" style={{backgroundImage:`url(${"img_loop3.webp"}`}}></div>
                </div>
            </Col>
            <Col md={3} className='d-none d-md-block'>
                <div className="HomeScrollingContainer">
                    <div className="HomeScrollingImg1" style={{backgroundImage:`url(${"img_loop4.webp"}`}}></div>
                </div>
            </Col>
        </Row>
    )
}

//used in OilOnShirt 2022/2023 style transfer version
export function HeroImageBeatingHeart() {

    return (
        <Card className="bg-transparent text-white">
            <Container fluid className="HeroImage-container-img">
                <Card.Img src="Header5_whitebackground2.png" alt="background"/>
                <Card.ImgOverlay className="HeroImage-background-color">
                    <Card.Text className="display-6">Bring Your Memories to Life </Card.Text>
                    <Image className="HeroImage-heart-beat HeroImage-img" src="logo_2024.webp" />
                    <Container className="HeroImage-title">
                        <Card.Title className="HeroImage-title-text">Your Story</Card.Title>
                        <Card.Title className='HeroImage-title-text-sm'>Your Design</Card.Title>
                        <Card.Title>
                            Turn Your Photos Into Art Prints
                        </Card.Title>
                        <div style={{height:"2vh"}} />
                        <Button variant='outline' className="buttonStyle-lavender HeroImage-design-button" as={Link} to="/design">CREATE NOW {brushSVG}</Button>
                    </Container>
                </Card.ImgOverlay>
            </Container>
        </Card>
    )
};

//used in OilOnShirt 2023 black version
export function HeroImagePencil() {

    return (
        <>
            <section id="HomeSection2" style={{backgroundImage: `url(${"Header5_whitebackground2.png"}`}}>
                <Container className="Home-background-color">
                    <Row className="Home-column-height-m align-items-center">
                        <div className="HeroImage-title">
                            <Card.Title style={{fontFamily: 'Yeseva One'}}>Oil On Shirt</Card.Title>
                            <h1 className='HeroImage-title-text-sm Home-title-headline Home-title'>AI Art Clothes</h1>
                            <Button variant='' className="my-4 buttonStyle-lavender HeroImage-design-button" as={Link} to="/design">Design Now</Button>
                        </div>
                    </Row>
                </Container>
            </section>
            <section id="HomeSection2" style={{backgroundImage: `url(${"Header5_lines_tee.png"}`}}>
                <Container className="my-4 Home-background-color">
                    <Row className="Home-column-height-m align-items-center">
                        <Col md={{span: 8, offset: 2}} className="HeroImage-blur">
                            <div className="Sustainability-tabs-overlay Sustainability-bg-col">
                                <div style={{height:"4vh"}} />
                                <TextEffect />
                                <div style={{height:"1vh"}} />
                                <div className="Home-text">Wear Your Story!</div>
                                <Button size="lg" variant='' className="my-3 buttonStyle-lavender" as={Link} to="/design">Design Now</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <h1 className="HeroImage-text-inset">TEST</h1>
            </section>
        </>
    )
};