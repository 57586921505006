import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export default function SlugOutlet() {

    return (
        <Container fluid className=''>
            <Outlet />
        </Container>
    )
}