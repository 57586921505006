import React from "react";
import Button from "react-bootstrap/esm/Button";
import SizingModal from "./SizingModal";
import { sizingSVG } from "./SVGs";

export default function Sizing() {

    const [show, setShow] = React.useState(false);

    const handleClose = () => {
        setShow(false)
    };

    const handleShow = () => {
        setShow(true)
    };
    const handleShowSizing = () => handleShow();

    return (
        <div>
            <Button
                className='my-2 mx-2'
                variant=""
                onClick={handleShowSizing}>
                Size Guide {sizingSVG}
            </Button>
            <SizingModal show={show} onClose={handleClose}/>
        </div>
    )
}