import React from "react";
import "./PrivacyPolicy.css";

export default function TermsConditions() {
    return (
        <>
            <section className="PrivacyPolicy-tabs PrivacyPolicy-text">
                <h1 className="display-2">Terms and Conditions</h1>
                <h6>Last updated November 28, 2023</h6>
                <hr className="hr-light my-4 w-100" />

                <h2 className="display-6">Who We Are</h2>
                We are OilOnShirt, available at https://oilonshirt.com. We offer personalised, GOTS certified organic cotton print clothing and art prints. We can be contacted by email at <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a>.

                Any access to or use of oilonshirt.com, this includes all subpages and related webpages published by OilOnShirt, are herein collectively referred to as the "Sites". These Terms and Conditions published here for OilOnShirt ('Company', 'we', 'us', or 'our',) build the basis for using our services. By using our services you accept the following terms ("Terms and Conditions"). Terms of service might change over time. Please revisit our Ts&Cs regularly.

                <div style={{height:"35px"}} />

                <h2 className="display-6">Table of contents</h2>
                <div style={{height:"15px"}} />
                <div className="mx-4">
                    <a href="#Upload"><h4>1. LICENSE</h4></a>
                    <a href="#Upload"><h4>2. UPLOADING PHOTOS</h4></a>
                    <a href="#Save"><h4>3. SAVING STYLIZED IMAGES</h4></a>
                    <a href="#Returns"><h4>4. RETURNS & EXCHANGES</h4></a>
                    <a href="#ThirdParty"><h4>5. THIRD PARTY CONTENT</h4></a>
                    <a href="#Jurisdiction"><h4>6. JURISDICTION</h4></a>
                </div>

                <div style={{height:"30px"}} />

                <a id="Upload"><h4>1. LICENSE</h4></a>
                <section>
                    OilOnShirt owns the intellectual property rights to this site. All rights in the Sites and its products, services, contents, including trademarks, symbols and slogans, text, data, images, audio-visual content, graphics or layout, whether registered or unregistered, are owned either by OilOnShirt or by our third party licensors and are protected by applicable laws.
                    <div style={{height:"10px"}} />
                    You must not:
                    <ul>
                        <li>copy, reproduce, adapt, store, transmit to any third party, exploit or otherwise use any item contained on the Sites in any way other than normal web viewing as a user or potential user of OilOnShirt products or services or as a user of the services as offered by OilOnShirt;</li>
                        <li> remove, hide, modify or otherwise interfere with any copyright and authorship notices;</li>
                        <li>create any hypertext links, or any other links between the Sites and your site or with any third party site without OilOnShirt's written permission;</li>
                        <li>frame, “deep link”, “page scrape”, mirror and/or create a browser or border environment around any of the services, except as expressly permitted by OilOnShirt, in advance and in writing.</li>
                        <li>use any “robot”, “spider” or other automatic device, program, script, algorithm, or methodology, or any similar or equivalent manual process, to access, acquire, copy, or monitor any portion of the services (or its data), or in any way reproduce or circumvent the navigational structure or presentation of any of the services to obtain or attempt to obtain any materials, data, documents, services or other information through any means not purposely made available through the services;</li>
                        <li>reverse look-up, trace, or seek to trace another user of the services, or otherwise interfere with or violate any other user’s right to privacy or other rights, or harvest or collect personally identifiable information about visitors or users of the services and/or OilOnShirt without their express and informed consent;</li>
                        <li>disable, circumvent, bypass or otherwise avoid any measures used to prevent or restrict access to the services, the account of other users, or any other systems or networks connected to the services, by hacking, password mining, or other illegitimate or prohibited means;</li>
                        <li> probe, scan, or test the vulnerability of the services or any network connected to the services; disrupt or interfere with the any other user's use of the Sites, or otherwise abuse the Sites, or any services, security, system resources, accounts, servers, or networks connected to or accessible through the Sites or affiliated or linked websites;</li>
                        <li>upload to the services and/or the Sites (or otherwise use them to design, develop, distribute and/or otherwise transmit or execute) any virus, worm, Trojan Horse, time bomb, web bug, spyware, malware, or any other computer code, file, or program that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or component;</li>
                        <li>take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the services or our systems or networks, or otherwise interfere with or disrupt the operation of any of the services;</li>
                    </ul>
                </section>

                <div style={{height:"30px"}} />

                <a id="Upload"><h4>2. UPLOADING PHOTOS</h4></a>
                <section>
                    By uploading an image to our design tool, you confirm that you either own or have obtained all necessary rights to use this image. If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Uploaded images or photos via our proprietary tool are NOT saved on our servers. We do not hold any rights to uploaded photos, nor do we store them.
                </section>

                <div style={{height:"30px"}} />

                <a id="Save"><h4>3. SAVING STYLIZED IMAGES</h4></a>
                <section>
                   The generated stylized output derived from your text prompt is saved when you order a personalised print product with us. We also save stylised outputs for up to 30 days, which are accessible via your favourites. You can delete items from your favourites at any time.
                </section>

                <div style={{height:"30px"}} />

                <a id="Returns"><h4>4. RETURNS & EXCHANGES</h4></a>
                <section>
                If an order meets our returns policies, it can be returned at any time up until the later of 14 days after receiving the products or 28 days after we dispatch them for delivery. If the customer is in the UK we provide a Freepost returns address. For EU/ International orders the customer will need to pay to post the items back to us. <a href="#Returns">However, unless they are defective, we cannot accept returns of custom products or any products that have been washed or worn.</a>
                </section>

                <div style={{height:"30px"}} />

                <a id="ThirdParty"><h4>5. THIRD PARTY CONTENT</h4></a>
                <section>
                    OilOnShirt is not responsible for any third party websites or applications linked to from the Sites (including advertisers) and we exclude any and all liability for any content, advertising, products, services, advice, data use or any other matter arising out of accessing such sites or applications, including their processing of personal data, which may differ from our policies.
                </section>

                <div style={{height:"30px"}} />

                <a id="Jurisdiction"><h4>6. JURISDICTION</h4></a>
                <section>
                    These Terms of Use are governed by and construed in accordance with English law, and you agree that any dispute or claim related to the Sites will be subject to the exclusive jurisdiction of the courts of England, except that OilOnShirt can ask any court to protect its intellectual property rights or confidential information.
                </section>
            </section>
        </>
    );
};