import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import LocaleCurrency from 'locale-currency';

const Context = React.createContext();

const local = navigator.language;

function getCurrency(locale, rates) {
    const ccy = LocaleCurrency.getCurrency(locale);
    const rate = rates[ccy];
    return {
        time: rates.time,
        symbol: rate ? ccy : 'GBP',
        rate: rate || 1,
    }
}

export function LocaleProvider(props) {
    const [locale, setLocale] = useState(local);
    const [rates, setRates] = useState({});

    const currency = getCurrency(locale, rates);

    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/rates`, {credentials: 'include'})
            .then(res => res.json())
            .then(data => setRates(data))
    }, []);

    return (
        <Context.Provider value = {{currency, locale, setLocale}}>
            <IntlProvider locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
)   ;
}

export function useLocale() {
    const context = React.useContext(Context)
    if (context === undefined) {
        throw new Error('useLocale must be used within a LocaleProvider')
    }
    return context
}