import React from "react";
import Address from './Address';
import { Col, Row } from "react-bootstrap";
import './AddressSummary.css';

export default function AddressSummary(props) {
    const order = props.order;

    const address = order.address;
    const billing = address.billing;
    const shipping = address.shipping || billing;

    return (
        <div>
            <h5 className="my-2">ADDRESS DETAILS</h5>
                <div className="mx-2 AddressSummary-text">
                    <Row className="AddressSummary-align">
                        <div className="my-2 AddressSummary-text-accent">Order receipt will be sent to: {billing.email}</div>
                        <Col className="AddressSummary-frame">
                            <span className="AddressSummary-subheading">BILLING</span>
                            <Address address={billing}/>
                        </Col>
                        <Col className="AddressSummary-frame">
                            <span className="AddressSummary-subheading">SHIPPING</span>
                            <Address address={shipping}/>
                        </Col>
                    </Row>
                    <Row className="AddressSummary-align">
                        <Col className="AddressSummary-frame">
                            <span className="AddressSummary-text-accent">
                                Shipping Method: {order.shipping.method}
                            </span>
                        </Col>
                    </Row>
                </div>
        </div>
    )
}