import React, { useEffect } from "react";
import "./OrderConfirmation.css";
import { Row, Col } from "react-bootstrap";
import { useShop, clearCart } from "../contexts/ShopContext";


import axios from 'axios';

export default function OrderConfirmation(props) {
    const { dispatch } = useShop();
    const order = props.order;
    const details = order.details;
    const userEmail = details.address.billing.email;
    const orderNumber = order.id;

    useEffect(() => {
        window.dataLayer.push({
            'event': 'ConfirmOrder',
            'revenue_value': details.price,
            'currency': details.currency.symbol,
        });
    }, []);

    useEffect(() => {
        axios({
            method: "post",
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/orders/${order.id}/confirm`,
        })
            .then(() => clearCart(dispatch))
            .catch(e => console.log(e));
    }, []);

    return (
        <div className="pageHeight OrderConfirmation-tabs">
            <h1 className="display-4">Yeeh, your order has been placed!</h1>
            <h2 className="OrderConfirmation-subheading my-4">Thanks for choosing organic, circular and personal.</h2>
            <hr />
            <Row>
                <Col className="OrderConfirmation-Col OrderConfirmation-text">
                    <p>
                        We've received your order <span style={{color: "#21565a", fontWeight: "900"}}>{orderNumber}</span> and will start processing it shortly. An order confirmation will be sent to <span style={{color: "#21565a", fontWeight: "900"}}>{userEmail}</span>.
                    </p>
                    <p>
                        Once your personalised item is ready for dispatch, you will receive an email with shipping information. Should you have any questions regarding your order, feel free to contact us at <span style={{color: "#21565a", fontWeight: "900"}}>hello@oilonshirt.com</span>.
                    </p>
                    <p className="my-4 OrderConfirmation-subheading">
                        <p>Stay creative!</p>
                        <p>Yours,</p>
                        <p>OilOnShirt</p>
                    </p>
                </Col>
                <Col xs={12} md={6}>
                    <img style={{width: "20vw"}} src="logo_text.png" />
                </Col>
            </Row>
        </div>
    )
}