import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { BASES_BY_ID } from '../const.js';
import { stackSVG, closeRoundSVG, checkSVG, arrowCounterClockSVG, heartSVG } from '../components/SVGs';
import { Button, Carousel } from 'react-bootstrap';
import { downloadImage } from '../download.js';
import { Link } from 'react-router-dom';
import { EditInStudio } from './EditInStudio';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default function AiOutput(props) {
    const designs = props.designs;

    const [selected, setSelected] = useState(designs[0]);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function checkMobile() {
            setIsMobile(window.innerWidth < 768);
        }
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        function checkTablet() {
            setIsTablet(window.innerWidth < 992);
        }
        checkTablet();
        window.addEventListener('resize', checkTablet);
        return () => window.removeEventListener('resize', checkTablet);
    }, []);
    
    return (
        <Row>
            <Col lg={{span: 7, order: ""}} xs={12} style={{margin: "0", padding: "0"}}>
                <Button
                    variant=""
                    className="cursorPointer buttonStyle-lavender mx-2 px-2 my-1"
                    onClick={props.onReset}>
                    <div
                        className="cursorPointer"
                        style={{width: "auto", fontSize: "calc(10px + 1vmin)"}}
                        >{arrowCounterClockSVG} Create New
                    </div>
                </Button>
                { !isTablet &&
                <Link to="/favourites">
                    <Button
                        variant=""
                        className="cursorPointer buttonStyle-lavender mx-2 px-2 my-1"
                        onClick={props.onReset}>
                        <div
                            className="cursorPointer"
                            style={{width: "auto", fontSize: "calc(10px + 1vmin)"}}
                            >{heartSVG} My Designs
                        </div>
                    </Button>
                </Link>
                }
                <OutputGrid designs={designs} onSelect={setSelected} isMobile={isMobile}/>
                <Link to="/favourites" style={{textDecoration: "none"}}>
                    <div style={{ fontSize: "calc(10px + 0.6vmin)"}} className="nav-link">Autosaved to {heartSVG} My Designs</div>
                </Link>
            </Col>
            { !isMobile &&
            <Col lg={5} xs={{span: 12, order: ''}} style={{margin: "0", paddingRight: "30px"}}>
                <div style={{height: "4vh"}}></div>
                <EditInStudio design={selected} base={BASES_BY_ID.rna1} />
            </Col>}
        </Row>
    )
};

function OutputGrid(props) {
    const designs = props.designs;
    const isMobile = props.isMobile;

    return (
        <>
            <Row>
                {designs.map((d) =>
                    <Col className='my-4'>
                        <Button onClick={() => props.onSelect(d)} variant="">
                        <img
                            id="design"
                            className="hover-zoom-light AiTool-styles"
                            src={d.image}
                            alt="generative art"
                        />
                        </Button>
                        <OverlayTrigger
                            placement='top'
                            delay={{show: 205, hide: 400 }}
                            overlay={<Tooltip>Download Design</Tooltip>}>
                            <Button
                                variant=""
                                className="buttonStyle-lavender ImageUploadCloud"
                                onClick={() => downloadImage(d.image)}
                                >{stackSVG}
                            </Button>
                        </OverlayTrigger>
                        { isMobile &&
                        <EditInStudio design={d} base={BASES_BY_ID.rna1} />}
                    </Col>
                )}
            </Row>
        </>
    );
}

// function OutputCarousel(props) {
//     const designs = props.designs;
//     return (
//         <>
//         <Carousel interval={null} activeIndex={props.selected} onSelect={props.onSelect} variant="light">
//             {designs.map((d) =>
//                 <Carousel.Item key={d.id}>
//                     <img
//                     id="design"
//                     className="hover-zoom AiTool-styles"
//                     src={d.image}
//                     alt="generative art"
//                     />
//                     <Carousel.Caption>
//                         {/* <div className='Home-title'>{d.id}</div> */}
//                         <Button
//                             variant=""
//                             className="buttonStyle-white ImageUploadCloud"
//                             onClick={() => downloadImage(d.image)}
//                             >{stackSVG}
//                         </Button>
//                     </Carousel.Caption>
//                 </Carousel.Item>
//             )}
//         </Carousel>
//         </>
//     );
//   }