import Gallery from '../components/Gallery';
import { Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

export const GALLERY = [
    {image: "/samples_1.webp", desc:"cute cat face", id: "1"},
    {image: "/samples_4.webp", desc:"Women's face seen from the side wearing a hat", id: "4"},
    {image: "/samples_7.webp", desc:"Sitting cat cleaning its paw", id: "7"},
    {image: "/samples_10.webp", desc:"Yosemite mountain", id: "10"},
    // {image: "samples_11.webp", desc:"Bull with feathers", id: "11"},
    // {image: "samples1_womenhead_lineart.png", desc:"Women's face seen from the side wearing a hat", id: "2"},
    // {image: "samples_5.webp", desc:"Women's face seen from the side wearing a hat", id: "5"},
    // {image: "samples_3.png", desc:"Women's face seen from the side wearing a hat", id: "3"},
    // {image: "samples_6.png", desc:"Women's face seen from the side wearing a hat", id: "6"},
    // {image: "samples_8.png", desc:"Sitting cat cleaning its paw", id: "8"},
    // {image: "samples_9.png", desc:"Woman performing a Virabhadrasana 2 Yoga pose", id: "9"},
    // {image: "samples_9.png", desc:"Woman performing a Virabhadrasana 2 Yoga pose", id: "12"},
];

export const UPCOMING = [
    {image: "/slogans_blackline.png", desc:"Coming Soon", id: "1"},
    {image: "/slogans_bold.png", desc:"Coming Soon", id: "2"},
    {image: "/slogans_kawaii.png", desc:"Coming Soon", id: "3"},
    {image: "/slogans_oneline.png", desc:"Coming Soon", id: "4"},
];

export default function HomeArt() {
    return (
        <>
            <p className="display-5">How It Works</p>
            <p className="Home-text">Text to image | Your Story, Your Design.</p>
            {/* | Story Art Clothing | Turn Your Stories Into Art Prints */}
            <p className="Home-title">hover over prints to read the story</p>
            <Button size="lg" variant='' style={{width: "50vw"}}className="my-4 buttonStyle-accent" as={Link} to="/studio/AiTool">Create my own print</Button>
            <Gallery gallery={GALLERY}></Gallery>

            <div style={{height:"5vh"}} />

            <p className="display-5">PREVIEW</p>
            <p className="Home-title">Slogans Available Soon</p>
            <Gallery gallery={UPCOMING}></Gallery>

            <Button size="lg" variant='outline-light' className="my-2 buttonStyle-lavender Home-title" as={Link} to="/studio/AiTool">Sing Up for Updates</Button>

            <div style={{height:"4vh"}} />
        </>
    )
}