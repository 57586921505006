import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Row } from 'react-bootstrap';
import { heartSVG} from '../components/SVGs.js';
import { TabContent, TabNavItem } from '../components/TabContent';
import Offcanvas from 'react-bootstrap/Offcanvas';
import '../components/SelectProductType.css';
import CustomProducts from '../components/CustomProducts.js';
import Designs from './Designs.js';

export function FavouritesPopup(props) {
    const { show, setShow } = props;

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="" onClick={handleShow} className="my-2 mx-1 buttonStyle-lavender">{heartSVG} My Designs</Button>
            <Offcanvas show={show} onHide={handleClose} style={{width:"90vw"}}>
                <Offcanvas.Header closeButton style={{color: "#8d6e63"}}>
                    {heartSVG}
                    <Offcanvas.Title>My Designs</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{width:"90vw"}}>
                    <Favourites onSelect={handleClose} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export function Favourites(props) {
    const [activeTab, setActiveTab] = useState("designs");

    return (
        <>
            <div className='SelectProductType-text'>All your creations in one place.</div>
            <div className="SelectProductType-tabs">
                <ul className="SelectProductType-nav">
                    <TabNavItem title="Designs" id="designs" activeTab={activeTab} setActiveTab={setActiveTab}/>
                    <TabNavItem title="My Products" id="products" activeTab={activeTab} setActiveTab={setActiveTab}/>
                </ul>
                <div className='my-4'>
                    <TabContent id="designs" activeTab={activeTab}>
                        <Row>
                            <Designs onSelect={props.onSelect} />
                        </Row>
                    </TabContent>

                    <TabContent id="products" activeTab={activeTab}>
                        <Row>
                            <CustomProducts onSelect={props.onSelect}/>
                        </Row>
                    </TabContent>
                </div>
            </div>
     </>
    )
}

