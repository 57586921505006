import React, { useState, useEffect } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './Navigation.css';
import { Link } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import LocaleSelect from './LocaleSelect';
import { bagLargeSVG, heartSVG, instaSVG, navToggleSVG, boxSVG } from './SVGs';
import DeliveryModal from '@oos/core/components/DeliveryModal';
import ReturnsModal from './ReturnsModal';
import { useShop } from '../contexts/ShopContext';
import NavigationOffcanvas from './NavigationOffCanvas';

export default function Navigation() {
	const {shop} = useShop();
    const cart = shop.cart;
	const summary = cart && cart.reduce((acc, item) => ({qty: acc.qty + item.qty}), {qty: 0});
	const [showDelivery, setShowDelivery] = React.useState(false);

	const [showReturn, setShowReturn] = React.useState(false);

	return (
		<>
			{/* <span eventKey="social" href="https://www.instagram.com/oilonshirt">{instaSVG}</span> */}
			<Navbar className="Navigation NavigationBgGradient" collapseOnSelect fixed="top" expand="xl">
				<Navbar.Toggle aria-controls="responsive-navbar-nav" className='Navigation-toggle-container'>
					<div className='Navigation-toggle'>
						<img src="/logo.webp" alt="OilOnShirt Ai Art Clothing Logo" style={{width:"50px"}}/>
						{navToggleSVG}
						{summary && summary.qty > 0 &&
						<Badge className="buttonStyle-brown" as={Link} to="/basket" pill bg="">{summary.qty}</Badge>
						}
					</div>
				</Navbar.Toggle>
				<Navbar.Offcanvas id="responsive-navbar-nav" className="NavigationOffCanvas Navigation-font NavigationBgGradient">
					<Offcanvas.Header closeButton>
						<Offcanvas.Title id="offcanvasNavbarLabel-expand" style={{fontSize:"calc(10px + 0.3vw)"}}>FREE UK Shipping from £50</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<Nav className="Navigation-col1 justify-content-start flex-grow-1 mt-2">
							<Nav.Link eventKey="basics" as={Link} to="/" className="Navigation-item">Home</Nav.Link>
							<Nav.Link eventKey="basics" as={Link} to="/collection/all" className="Navigation-item">All Products</Nav.Link>
							<Nav.Link eventKey="studio" as={Link} to="/studio" className="Navigation-item">MyStudio</Nav.Link>
							<Nav.Link eventKey="favourites" as={Link} to="/favourites" className="Navigation-item">MyFavourites</Nav.Link>
						</Nav>
						<Nav.Link eventKey="home" as={Link} to="/" className='NavHide'><img src="/logo.webp" style={{width:"50px", marginRight:"20px"}}/></Nav.Link>
						<Nav className="Navigation-col3 justify-content-end flex-grow-1 mt-2">
							<Nav.Link eventKey="sustainability" as={Link} to="/sustainability" className="Navigation-item">Sustainability</Nav.Link>
							<Nav.Link eventKey="blog" as={Link} to="/blog" className="Navigation-item">Blog</Nav.Link>
							<Nav.Link eventKey="basket" as={Link} to="/basket">
								{bagLargeSVG}
								{ summary && summary.qty > 0 && <Badge className="mx-2 buttonStyle-brown" style={{fontSize:"calc(6px + 0.4vw)", fontWeight:"900"}} pill bg="">{summary.qty}</Badge> }
							</Nav.Link>
							{/* <LocaleSelect/> */}
						</Nav>
						<Nav className="Navigation-col1 justify-content-start flex-grow-1 mt-2 NavHideHome">
							<hr />
							<Nav.Link onClick={() => setShowReturn(true)}>Returns & Exchanges</Nav.Link>
							<Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
							<Nav.Link as={Link} to="/terms-and-conditions">Terms & Conditions</Nav.Link>
							<div style={{marginTop:"15px", fontSize:"calc(10px + 0.3vw)"}}>
								<div style={{textTransform:"uppercase"}}>Contact</div>
								<div>hello@oilonshirt.com</div>
							</div>
							<hr />
							<div className="Footer-text-copyright">&copy; 2024, OILONSHIRT, All Rights Reserved | Custom Organic AI Art Clothing.</div>
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>
			</Navbar>
			<div className="Navigation-tagnav NavigationBgGradient2">
				<NavigationOffcanvas />
			</div>
			<DeliveryModal show={showDelivery} onClose={() => setShowDelivery(false)}/>
			<ReturnsModal show={showReturn} onClose={() => setShowReturn(false)}></ReturnsModal>
		</>
	);
}