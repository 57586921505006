class Mask {
    constructor(dx, dy, width, height) {
        this.dx = dx;
        this.dy = dy;
        this.width = width;
        this.height = height;
        this.aspectRatio = this.width / this.height;
    }
}

export class RingCircle extends Mask {
    constructor(x, y, diam) {
        super(x-diam/2, y-diam/2, diam, diam)
        this.r = diam/2;
        this.x = x;
        this.y = y;
    }

    draw(ctx) {
        drawRingCircle(ctx, this.x, this.y, this.r);
    }
}

export class RoundedRectangle extends Mask {
    draw(ctx) {
        drawRoundedRect(ctx, this.dx, this.dy, this.width, this.height, 25);
    }
}

function drawCircle(ctx, x, y, r) {
    const circle = new Path2D();
    circle.arc(x, y, r, 0, 2 * Math.PI);
    ctx.fill(circle);
}

function drawRingCircle(ctx, x, y, r) {
    ctx.globalCompositeOperation = 'source-over';
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.fill();
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(x, y, r*0.99, 0, 2 * Math.PI);
    ctx.fill()
    ctx.globalCompositeOperation = 'source-over';
    ctx.beginPath();
    ctx.arc(x, y, r*0.98, 0, 2 * Math.PI);
    ctx.fill();
}

function drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x, y + radius);
    ctx.arcTo(x, y + height, x + radius, y + height, radius);
    ctx.arcTo(x + width, y + height, x + width, y + height - radius, radius);
    ctx.arcTo(x + width, y, x + width - radius, y, radius);
    ctx.arcTo(x, y, x, y + radius, radius);
    ctx.fill();
}