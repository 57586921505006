import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import FeedbackModal from './FeedbackModal';
import ContactModal from './ContactModal';
import React from 'react';
import SizingModal from './SizingModal';
import DeliveryModal from '@oos/core/components/DeliveryModal';
import ReturnsModal from './ReturnsModal';
import "./Footer.css";
import { EMailSignupPlain } from './EmailSignup';

export default function Footer(props) {
    const [showFeedback, setShowFeedback] = React.useState(false);
    const [showSizing, setShowSizing] = React.useState(false);
    const [showContact, setShowContact] = React.useState(false);
    const [showDelivery, setShowDelivery] = React.useState(false);
    const [showReturn, setShowReturn] = React.useState(false);

    return (
        <>
            <Row className="Footer mx-1">
                <hr className="hr-light w-100" style={{margin:"4px"}}/>
                <Col xs={12} sm="auto" align={'start'} className="Footer-menu">
                    <Nav.Link className="Home-title" as={Link} to="/studio">My Studio</Nav.Link>
                    <Nav.Link as={Link} to="/organic-cotton-basics">All Products</Nav.Link>
                    <Nav.Link as={Link} to="/blog">Blog</Nav.Link>
                    <Nav.Link onClick={() => setShowReturn(true)}>Returns & Exchanges</Nav.Link>
                    <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
                    <Nav.Link as={Link} to="/terms-and-conditions">Terms & Conditions</Nav.Link>
                </Col>
                <Col align={'start'} className="Footer-menu">
                    <Button
                        className="my-2 buttonStyle-brown"
                        variant=''
                        onClick={() => setShowDelivery(true)}>
                        Delivery
                    </Button>
                    <Button
                        className="mx-2 my-2 buttonStyle-brown"
                        variant=''
                        onClick={() => setShowContact(true)}>
                        Contact
                    </Button>
                    <Button
                        className="my-2 buttonStyle-brown"
                        variant=''
                        onClick={() => setShowFeedback(true)}>
                        Feedback
                    </Button>
                    <hr className="hr-light w-100" style={{marginTop:"20px"}}/>
                    <EMailSignupPlain />
                </Col>
                <Col xs={12} lg={4} align={'start'} className="Footer-text">
                    <div>Our products are made from GOTS certified organic cotton. They are printed on demand in a renewable energy powered factory in the UK.
                    </div>
                    <Row className='my-2'>
                        <Col xs={3}>
                            <img src="/GOTS_logo.png" style={{width:"calc(30px + 2vw"}}/>
                        </Col>
                        <Col>
                            <Button variant="" className='buttonStyle-brown-solid my-3' style={{width:"80%"}} as={Link} to="/sustainability">Find Out More</Button>
                        </Col>
                    </Row>
                </Col>
                <div className="Footer-text-copyright">&copy; 2024, OILONSHIRT, All Rights Reserved | Custom Organic AI Art Clothing.</div>
            </Row>
            <SizingModal show={showSizing} onClose={() => setShowSizing(false)}/>
            <FeedbackModal show={showFeedback} onHide={() => setShowFeedback(false)}/>
            <ContactModal show={showContact} onClose={() => setShowContact(false)}/>
            <DeliveryModal show={showDelivery} onClose={() => setShowDelivery(false)}/>
            <ReturnsModal show={showReturn} onClose={() => setShowReturn(false)}></ReturnsModal>
        </>
    );
  }