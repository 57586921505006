import './TShirt.css';
import React from 'react';

export default function TShirt(props) {
    const canvas = props.canvas;
    const image = props.image || canvas.image;
    const style = {
        top: `${canvas.dy * 100}%`,
        left: `${canvas.dx * 100}%`,
        width: `${(1-2*canvas.dx) * 100}%`,
    };
    return (
        <div className="TShirt">
            <img className="TShirt-blank" src={image} />
            <div className="TShirt-design" style={style}>{props.children}</div>
        </div>
    );
}