import React from "react";
import "./PrivacyPolicy.css";

export default function PrivacyPolicy() {
    return (
        <>
            <section className="PrivacyPolicy-tabs PrivacyPolicy-text">
            <h1 className="display-2">Privacy Policy</h1>
                <h6>Last updated November 28, 2023</h6>
                <hr className="hr-light my-4 w-100" />

                <h2 className="display-6">Who We Are</h2>
                We are OilOnShirt, available at https://oilonshirt.com. We offer personalised, GOTS certified organic cotton print clothing and art prints.

                This privacy notice for OilOnShirt ('Company', 'we', 'us', or 'our',), describes how and why we might collect, store, use, and/or share ('process') your information when you use our services ('Services'), such as when you:
                <div style={{height:"10px"}} />
                    <ul>
                        <li>Visit our website at https://oilonshirt.com, or any website of ours that links to this privacy notice</li>
                        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                    </ul>
                Reading this privacy notice will help you understand your privacy rights and choices. We promise there is nothing funny going on, but we have to put it out there for transparency reasons. Should you have any questions or concerns, please feel free to reach out to us at hello@oilonshirt.com. If you do not agree with our policies and practices, we kindly ask you not to use our Services.
                <div style={{height:"35px"}} />

                <h2 className="display-6">Table of contents</h2>
                <div style={{height:"15px"}} />
                <div className="mx-4">
                    <a href="#Info"><h4>1. WHAT INFORMATION DO WE COLLECT?</h4></a>
                    <a href="#Process"><h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4></a>
                    <a href="#Legal"><h4>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</h4></a>
                    <a href="#Share"><h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4></a>
                    <a href="#Tracking"><h4>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4></a>
                    <a href="#Transfer"><h4>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4></a>
                    <a href="#KeepInfo"><h4>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4></a>
                    <a href="#Safe"><h4>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4></a>
                    <a href="#Minors"><h4>9. DO WE COLLECT INFORMATION FROM MINORS?</h4></a>
                    <a href="#PrivacyRights"><h4>10. WHAT ARE YOUR PRIVACY RIGHTS?</h4></a>
                    <a href="#DNT"><h4>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4></a>
                    <a href="#CA"><h4>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4></a>
                    <a href="#Update"><h4>13. DO WE MAKE UPDATES TO THIS NOTICE?</h4></a>
                    <a href="#Contact"><h4>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4></a>
                    <a href="#Delete"><h4>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4></a>
                </div>

                <div style={{height:"35px"}} />

                <a id="Info"><h4>1. WHAT INFORMATION DO WE COLLECT?</h4></a>
                <h5>Personal information you disclose to us</h5>

                <h6>In Short: We collect personal information that you provide to us.</h6>
                We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

                The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
                <div style={{height:"10px"}} />
                <ul>
                    <li>names</li>
                    <li>email addresses</li>
                    <li>billing addresses</li>
                    <li>debit/credit card numbers</li>
                    <li>mailing addresses</li>
                </ul>

                We do not process sensitive information (e.g. health-realted or genetic data). We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here: https://stripe.com/gb/privacy. All personal information that you provide to us must be true, complete, and accurate.

                <div style={{height:"30px"}} />

                <a id="Process"><h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4></a>

                <h6>In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</h6>
                We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
                <div style={{height:"10px"}} />
                <ul>
                    <li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
                    <li>To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
                    <li>To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.</li>
                    <li>To fulfill and manage your orders. We may process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.</li>
                    <li>To save or protect an individual's vital interest. We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
                </ul>

                <div style={{height:"30px"}} />

                <a id="Legal"><h4>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</h4></a>

                <h6>In Short: We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.You can withdraw your consent at any time.</h6>
                If you are located in the EU or UK, this section applies to you. The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:

                <div style={{height:"10px"}} />
                <ul>
                    <li>Consent. We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose.</li>
                    <li>Performance of a Contract. We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.</li>
                </ul>

                If you are located in Canada, this section applies to you.

                We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent).

                In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                <ul>
                    <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                    <li>For investigations and fraud detection and prevention</li>
                    <li>For business transactions provided certain conditions are met</li>
                </ul>

                <div style={{height:"30px"}} />

                <a id="Share"><h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4></a>

                <h6>In Short: We may share information in specific situations described in this section and/or with the following third parties.</h6>
                Vendors, Consultants, and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors, or agents ('third parties') who perform services for us or on our behalf and require access to such information to do that work. We have contracts in place with our third parties, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organisation apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct. The third parties we may share personal information with are as follows:

                <div style={{height:"10px"}} />
                <ul>
                    <li>Advertising, Direct Marketing, and Lead Generation: Bing Ads</li>
                    <li>Cloud Computing Services: Amazon Web Services (AWS)</li>
                    <li>Invoice and Billing: Stripe and Teemill Tech Ltd</li>
                    <li>Web and Mobile Analytics: Google Analytics and Google Tag Manager</li>
                    <li>Website Hosting: Amazon Web Services (AWS)</li>
                    <li>Order Fulfillment: Teemill Tech Ltd</li>
                </ul>

                We also may need to share your personal information in the following situations: Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

                <div style={{height:"30px"}} />

                <a id="Tracking"><h4>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4></a>

                <h6>In Short: Yes, we may use cookies and other tracking technologies to collect and store your information.</h6>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Consent Banner.

                <div style={{height:"30px"}} />

                <a id="Transfer"><h4>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4></a>

                <h6>In Short: We may transfer, store, and process your information in countries other than your own.</h6>
                Our servers are located in the United Kingdom. If you are accessing our Services from outside the United Kingdom, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see <a href="#Share">'4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?'</a> above), in the United Kingdom, United States, and other countries.

                If you are a resident in the European Economic Area (EEA) or United Kingdom (UK), then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.

                <div style={{height:"30px"}} />

                <a id="KeepInfo"><h4>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h4></a>

                <h6>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</h6>

                We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).

                <div style={{height:"30px"}} />

                <a id="Safe"><h4>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4></a>

                <h6>In Short: We aim to protect your personal information through a system of organisational and technical security measures.</h6>

                We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.

                <div style={{height:"30px"}} />

                <a id="Minors"><h4>9. DO WE COLLECT INFORMATION FROM MINORS?</h4></a>

                <h6>In Short: We do not knowingly collect data from or market to children under 18 years of age.</h6>

                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at hello@oilonshirt.com.

                <div style={{height:"30px"}} />

                <a id="PrivacyRights"><h4>10. WHAT ARE YOUR PRIVACY RIGHTS?</h4></a>

                <h6>In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or delete your data at any time by contacting us at hello@oilonshirt.com.</h6>

                In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. We will consider and act upon any request in accordance with applicable data protection laws.
                <div style={{height:"10px"}} />

                If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">here</a>.

                If you are located in Switzerland, the contact details for the data protection authorities are available <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>.
                <div style={{height:"10px"}} />
                <ul>
                    <li><b>Withdrawing your consent</b></li>
                    If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time by emailing us at <a href="mailto:hello@oilonshirt.com">hello@oilonshirt</a>. However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
                    <li><b>Opting out of marketing and promotional communications</b></li>
                    You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send or email "UNSUBSCRIBE" to <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a>. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to respond to service requests, or for other non-marketing purposes.
                    <li><b>Cookies and similar technologies</b></li>
                    Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services (e.g. your stylised images won’t be saved in your Favourites section).
                </ul>
                <div style={{height:"30px"}} />

                <a id="DNT"><h4>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4></a>

                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.

                <div style={{height:"30px"}} />

                <a id="CA"><h4>12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4></a>

                <h6>In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</h6>

                California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request via email to <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a>.

                <div style={{height:"30px"}} />

                <a id="Update"><h4>13. DO WE MAKE UPDATES TO THIS NOTICE?</h4></a>

                <h6>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</h6>

                We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.

                <div style={{height:"30px"}} />

                <a id="Contact"><h4>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4></a>

                <h6>If you have questions or comments about this notice, you may email us at <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a>.</h6>

                <div style={{height:"30px"}} />

                <a id="Delete"><h4>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4></a>

                <h6>You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please email us at <a href="mailto:hello@oilonshirt.com">hello@oilonshirt.com</a>.</h6>

                <div dangerouslySetInnerHTML={{ __html: privacyHTML}} />
            </section>
        </>
    );
};

const privacyHTML = `
<div style="color: #8d6e63;font-size: 14px;padding-top:16px;">
This privacy policy was created using Termly's <a style="color: #21565a !important;" href="https://termly.io/products/privacy-policy-generator/">Privacy Policy Generator</a>.
</div>
`;