import './TabContent.css';

export function TabContent({id, activeTab, children}) {
    return (
        activeTab === id ? <div className="TabContent">
            {children}
        </div> : null
    );
}

export function TabNavItem({ id, title, activeTab, setActiveTab }) {
    const disabled = !id;
    const handleClick = () => !disabled && setActiveTab(id);
    const className = disabled ? "disabled" : (activeTab === id ? "active" : "");
    return (
        <li onClick={handleClick} className={className}>
            { title }
        </li>
    );
};