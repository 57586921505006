import React from 'react';
import ReactDOM from 'react-dom/client';
import { ScrollToTop } from '@oos/core/components/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import Basics from './pages/Basics';
// import DesignPage from './design/Page';
import NotFound from './pages/NotFound';
// import { makeServer } from "./mirage"
import Page from './pages/Page';
import HomePage from './pages/HomePage';
import PrivacyPolicy from './components/PrivacyPolicy';
import BasketPage from './pages/BasketPage';
import Sustainability from './pages/Sustainability';
import CheckoutPage from './pages/CheckoutPage';
// import OrderConfirmationPage from './pages/OrderConfirmationPage';
import TermsConditions from './components/TermsConditions';
import StudioMain from './studio/StudioMain';
import AiTool from './studio/AiTool';
import AiSlogan from './studio/AiSlogan';
import SlugOutlet from './pages/SlugOutlet';
import ProductDetailsPage from './pages/ProductDetails';
import CollectionPage from './pages/Collection';
import StudioPage from './pages/Studio';
import FavouritesPage from './pages/FavouritesPage';
import Blog from './pages/Blog';
import BlogContent from './components/blog/BlogContent';
import About from './components/blog/About';
import OrganicCotton from './components/blog/OrganicCotton';
import HowToPrint from './components/blog/HowToPrint';
import HowToXMas from './components/blog/HowtoXMas';

// if (process.env.NODE_ENV === "development") {
//     // makeServer({ environment: "development" })
//     const { worker } = require('./mocks/browser')
//     worker.start({
//         onUnhandledRequest: 'bypass',
//     })
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ScrollToTop />
                <Routes>
                    <Route path="/" element={<App />}>
                        <Route index element={<Page title="Print on Demand Organic Cotton Clothing | Ai Art Clothing | by OilOnShirt" page={<HomePage />}/>}/>
                        <Route path="organic-cotton-basics" element={<Page title="Organic Cotton Basics" page={<Basics />}/>}/>
                        <Route path="product/*" element={<SlugOutlet />}>
                            <Route path=":slug" element={<ProductDetailsPage />}/>
                        </Route>
                        <Route path="collection/*" element={<SlugOutlet />}>
                            <Route path=":slug" element={<CollectionPage/>}/>
                        </Route>
                        <Route path="studio/*" element={<Page title="Studio" page={<StudioPage/>}/>}>
                            <Route index element={<Page title="Studio" page={<StudioMain/>}/>} />
                            <Route path="AiTool" element={<AiTool />} />
                            <Route path="AiSlogan" element={<AiSlogan />} />
                        </Route>
                        <Route path="blog/*" element={<Page title="Blog" page={<Blog />}/>}>
                            <Route index element={<Page title="Blog" page={<BlogContent/>}/>}/>
                            <Route path="about-ai-print-on-demand" element={<About />}/>
                            <Route path="sustainability" element={<Sustainability/>}/>
                            <Route path="why-buy-organic-cotton-clothing" element={<OrganicCotton/>}/>
                            <Route path="how-to-design-my-own-ai-print-tshirt" element={<HowToPrint/>}/>
                            <Route path="custom-christmas-sweater-how-to-design-my-own-christmas-sweater-with-ai" element={<HowToXMas/>}/>
                        </Route>
                        <Route path="favourites" element={<Page title="Favourites" page={<FavouritesPage />}/>}/>
                        <Route path="basket" element={<Page title="Basket" page={<BasketPage/>}/>} />
                        <Route path="checkout" element={<Page title="Checkout" page={<CheckoutPage/>}/>}/>
                        {/* <Route path="order-confirmation" element={<Page title="Order Confirmation" page={<OrderConfirmationPage/>}/>}/> */}
                        <Route path="sustainability" element={<Page title="Sustainability" page={<Sustainability/>}/>}/>
                        <Route path="privacy-policy" element={<Page title="Privacy Policy" page={<PrivacyPolicy />}/>}/>
                        <Route path="terms-and-conditions" element={<Page title="Terms & Conditions" page={<TermsConditions />}/>}/>
                        <Route path="*" element={<Page title="Not Found" page={<NotFound />}/>}/>
                    </Route>
                </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();