import { useNavigate } from "react-router-dom";
import { Favourites } from "../components/Favourites";


export default function FavouritesPage() {
    const navigate = useNavigate();
    const handleSelect = () => navigate('/studio');

    return (
        <>
            <Favourites onSelect={handleSelect} />
        </>
    );
}