export function canvas_url(base_id, colour, back) {
    const col_str = colour.replace(/\s+/g, '-').toLowerCase();
    return `/canvas/${base_id}_${col_str}_${back ? 'back' : 'front'}.webp`;
}

export const design_url = (id) => `${process.env.REACT_APP_BACKEND_URL}/designs/${id}`;


export const CANVAS = {
    tshirt_front: {
        width: 1575,
        height: 2100,
        dx: 0.285,
        dy: 0.243,
    },
    tshirt_back: {
        width: 1575,
        height: 2100,
        dx: 0.285,
        dy: 0.243,
    }
}

export const COLOURS = [
    {code: "#DADADA", name: "Athletic Grey"},
    {code: "#2173D9", name: "Bright Blue"},
    {code: "#555555", name: "Dark Grey"},
    {code: "#1F424B", name: "Denim Blue"},
    {code: "#374723", name: "Moss Green"},
    {code: "#56643f", name: "Khaki"},
    {code: "#E2B03E", name: "Mustard"},
    {code: "#0D1B30", name: "Navy Blue"},
    {code: "#ffc0da", name: "Pink"},
    {code: "#4c2a4b", name: "Purple"},
    {code: "#C52020", name: "Red"},
    {code: "#702225", name: "Red Wine"},
    {code: "#B74d3B", name: "Rust"},
    {code: "#D2B48C", name: "Sand"},
    {code: "#D6E4ED", name: "Sky Blue"},
    {code: "#99B0BE", name: "Stone Blue"},
    {code: "#ffffff", name: "White"},
    {code: "#000000", name: "Black"},
    {code: "#D1B7C1", name: "Mauve"},
    {code: "#DED7C8", name: "Oat"},
    {code: "#E7F7DE", name: "Pastel Green"},
    {code: "#A5B798", name: "Sage"},
    {code: "#72827E", name: "Slate Grey"},
    {code: "#9A9BA1", name: "Light Heather"},
    {code: "#765229", name: "Brown"},
    {code: "#493B39", name: "Chocolate"},
    {code: "#CE785D", name: "Apricot"},
    {code: "#506886", name: "Solent"},
    {code: "#9B1F42", name: "Cherry"},
]

export const DARK_COLOURS = new Set([
    'Black', 'Denim Blue', 'Dark Grey', 'Navy Blue', 'Purple', 'Red Wine',
]);

export const COLOUR_CODE = Object.fromEntries(COLOURS.map(c => [c.name, c.code]))

export const mapColours = (cs) => cs.map(c => ({ name: c, code: COLOUR_CODE[c] })).filter(c => c.code);

export const BASES = [
    {
        id: 'rna1',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rna4',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rna7',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rna33',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnb14',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnb33',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnb36',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnb62',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnc1',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnc2',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnc3',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
    {
        id: 'rnc4',
        front: CANVAS.tshirt_front,
        back: CANVAS.tshirt_back,
    },
]

export const BASES_BY_ID = Object.fromEntries(BASES.map(p => [p.id, p]));