import React from 'react';

const DesignsContext = React.createContext()

function designsReducer(state, action) {
    console.log(`executing ${action.type}`);
    switch (action.type) {
        // case 'RESET': {
        //     const designs = action.payload;
        //     return { available: designs }
        // }
        case 'ADD_NEW_DESIGNS': {
            const designs = action.payload;
            return {
                ...state,
                available: [...designs, ...state.available],
            }
        }
        case 'ADD_MORE_DESIGNS': {
            // TODO consider handling duplicates
            const {designs, hasMore} = action.payload;
            return {
                ...state,
                available: [...state.available, ...designs],
                hasMore,  // TODO error-prone?
            }
        }
        case 'REMOVE_DESIGN': {
            const id = action.payload;
            return {
                ...state,
                available: state.available.filter((d) => d.id !== id)
            }
        }
        case 'SELECT_DESIGN': {
            const id = action.payload;
            return {
                ...state,
                selected: state.available.find(x => x.id === id)
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

export function DesignsProvider({children}) {
    const [state, dispatch] = React.useReducer(designsReducer, {
        hasMore: true,
        available: [],
    });

    // React.useEffect(() => {
    //     fetch(`${process.env.REACT_APP_BACKEND_URL}/designs`, {credentials: 'include'})
    //         .then(res => res.json())
    //         .then(designs => dispatch({type: 'RESET', payload: designs }));
    // }, []);

    const value = {designs: state, dispatch}
    return <DesignsContext.Provider value={value}>{children}</DesignsContext.Provider>
}

export function useDesigns() {
    const context = React.useContext(DesignsContext)
    if (context === undefined) {
        throw new Error('useDesigns must be used within a DesignsProvider')
    }
    return context
}