import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Carousel } from 'react-bootstrap';
import { stackSVG, closeRoundSVG, arrowLeftSVG, arrowRightSVG, checkSVG, arrowCounterClockSVG, magicSVG } from '../components/SVGs';
import { Row, Col } from 'react-bootstrap';
import ProgressBar from '@oos/core/components/ProgressBar';
import { shuffleArray } from '@oos/core/shuffle';
import ImageRadios from '@oos/core/components/ImageRadios';
import { useDesigns } from '@oos/core/components/DesignsContext';
import PaintingLoader from './PaintingLoader';
import { TabNavItem } from '../components/TabContent';
import { TabContent } from '../components/TabContent';
import AiOutput from './AiOutput';
import './AiTool.css';

export const SLOGANSTYLES = [
    {image: "/slogans_blackline.png", id: "BlackBold", title: "Black Bold"},
    {image: "/slogans_oneline.png", id: "BlackLine", title: "Black LineArt"},
    {image: "/slogans_bold.png", id: "ColourBold", title: "Colour"},
    {image: "/slogans_kawaii.png", id: "Pastel", title: "Pastel"},
];

export const SLOGANPROMPTS = [
    {desc: "Cute Space Cat", id: "1"},
    {desc: "Hiking", id: "2"},
    {desc: "Morning Coffee", id: "3"},
    {desc: "Life Itself", id: "4"},
    {desc: "Nature love", id: "5"},
    {desc: "Cats", id: "6"},
    {desc: "Sunset", id: "7"},
    {desc: "Climate Change", id: "8"},
    {desc: "Art", id: "9"},
    {desc: "Yoga", id: "10"},
];

export default function AiSlogan(props) {
    const {dispatch} = useDesigns();
    const [activeTab, setActiveTab] = useState("tab1");
    const [description, setDescription] = useState("");
    const [slogan, setSlogan] = useState("");
    const [style, setStyle] = useState("");
    const [prompts, setPrompts] = useState();
    const [designs, setDesigns] = useState({
        available: [],
    });

    const max_length = props.max_length || 150;
    const max_length_slogan = props.max_length_slogan || 40;
    const blur = !designs.available.length && activeTab == "tab2";

    useEffect(() => {
        setPrompts({
            prompts: shuffleArray(SLOGANPROMPTS).slice(0, 1),
            // slogans: shuffleArray(SLOGANS).slice(0, 6),
        })
    }, []);

    const resetDesigns = (loading, errorMsg) => setDesigns({ available: [], loading, errorMsg });

    const handleSelectStyle = (id) => {
        setStyle(SLOGANSTYLES.find(e => e.id === id));
        // setActiveTab("tab2");
        window.dataLayer.push({
            'event': 'SelectStyle',
        });
        console.log('SelectStyle');
    }

    const handleSelectStyleDefault = () => {
        setStyle("0");
        // setActiveTab("tab2");
        window.dataLayer.push({
            'event': 'SelectStyle',
        });
        console.log('SelectStyle');
    }

    const handleDesignError = (res) => {
        switch (res.status) {
            case 429:
                return "Sorry, you've reached your daily limit of 10 free stories."
            case 503:
                return "Sorry, our total daily budget for free stories has run out..."
        }
        return DEFAULT_DESIGN_ERROR_MSG
    };

    const handleCreate = () => {
        setActiveTab("tab2");
        window.dataLayer.push({
            'event': 'GenerateNow',
        });
        if (!description || description.length < 1) {
            setDesigns({ ...designs, errorMsg: "Please describe your print" });
            return
        }
        resetDesigns(true);
        return axios({
            method: "post",
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/designs`,
            headers: { "Content-Type": "application/json" },
            data: JSON.stringify({ content: description, style: style.id, slogan: slogan }),
        })
        .then((res) => {
            console.log(res);
            dispatch({ type: 'ADD_NEW_DESIGNS', payload: res.data });
            setDesigns({available: res.data});
        })
        .catch((err) => {
            console.log(err);
            const errorMsg = err.response ? handleDesignError(err.response) : DEFAULT_DESIGN_ERROR_MSG;
            resetDesigns(false, errorMsg);
        })
    };

    const handleReset = () => {
        resetDesigns();
        setActiveTab("tab1");
    }

    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/Studio");
    }

    return (
        <>
            <div className={blur ? "AiTool-overlay" : null}>

                <TabContent id="tab1" activeTab={activeTab}>

                    <div style={{textAlign: 'end'}}>
                        <Button variant="" className="p-2 mx-2 my-2 cursorPointer" onClick={handleBack}>{closeRoundSVG} Close</Button>
                    </div>

                    <Row>
                        <Col md={6} xs={10}>
                            <ImageRadios inputs={SLOGANSTYLES} value={style.id} onChange={handleSelectStyle} />
                        </Col>
                        <Col md={6} xs={10}>
                            <div style={{height:"1vh"}} />
                            <label htmlFor="sloganText">
                                <div className='AiTool-text'>Generate a Slogan for a Topic</div>
                                <div className='AiTool-text'>e.g. hiking, outdoor adventure, art, cats...</div>
                                <textarea
                                    className='AiTool-input'
                                    id="sloganText"
                                    name="slogan"
                                    value={slogan}
                                    rows={1}
                                    maxLength={max_length_slogan}
                                    onChange={(e) => setSlogan(e.target.value)}
                                    placeholder="Start typing..."
                                />
                                <div className="" style={{textAlign: 'end'}}>{slogan.length}/{max_length_slogan}</div>
                            </label>
                            {!designs.loading &&
                            <>
                            {slogan &&
                            <>
                                <Button
                                    variant=""
                                    className="cursorPointer p-2 my-2 buttonStyle-accent"
                                    onClick={handleCreate}>
                                    Generate Slogan
                                </Button>
                            </>}
                            <div style={{ height: "1vh" }} />
                        </>}
                        { !slogan && prompts && !designs.errorMsg && <>
                            <AddPrompt text={prompts.prompts} onClick={setSlogan} />
                        </>}
                        <div style={{height:"1vh"}} />
                        </Col>
                    </Row>
                </TabContent>

                <TabContent id="tab2" activeTab={activeTab}>
                    { designs.errorMsg ? <>
                        <div style={{height:"25vh"}} />
                        <div>{designs.errorMsg}</div>
                        <Button
                        variant=""
                        className="cursorPointer p-1 mx-2 AiTool-text"
                        onClick={handleReset}
                        style={{width: "10vw", maxHeight: "20vw"}}
                        >{closeRoundSVG} Close
                        </Button>
                    </> : <>
                        {designs.loading && <div className='AiTool-overlay-content'>
                            <PaintingLoader />
                            <ProgressBar duration={16} tick={1000} />
                        </div>}
                    </>}
                    {designs.available.length ? <AiOutput designs={designs.available} onReset={handleReset} /> : null}
                    <div style={{height:"2vh"}} />
                </TabContent>
            </div>
        </>
    )
}

const DEFAULT_DESIGN_ERROR_MSG = "Sorry, something went wrong... please try again";

function AddPrompt(props) {
    return (
        <Row>
            {props.text.map((p) => (
            <Col key={p.id} className="my-2">
                 <Button
                    style={{width:"25vw", fontSize: "2vmin"}}
                    className="cursorPointer p-2 buttonStyle-lavender"
                    variant=''
                    onClick = {() => props.onClick(p.desc)}
                    >{magicSVG} Surprise me
                </Button>
            </Col>
            ))}
        </Row>
    )
}

