import CartSummary from "../components/CartSummary";
import './BasketPage.css';
import Cart from "../components/Cart";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Container from "react-bootstrap/esm/Container";


export default function BasketPage() {

    return (
        <div className="BasketPage-width pageHeight">
            <Container fluid>
                <Row>
                    <Col xl={8} lg={6} md={7} xs={12}>
                        <Cart />
                    </Col>
                    <Col xl={4} lg={6} md={5}>
                        <CartSummary />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}