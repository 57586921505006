import Modal from 'react-bootstrap/Modal';
import './FeedbackModal.css';
import Button from 'react-bootstrap/esm/Button';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Resizer from "react-image-file-resizer";


export default function FeedbackModal(props) {
    const [showConfirmation, setShowConfirmation] = React.useState(false)

    const [feedback, setFeedback] = useState({});

    const changeFeedback = (field) => ({target:{value}}) => setFeedback({...feedback, [field]: value});

    const changeScreenshot = (e) => resizeScreenshot(e.target.files[0])
        .then((img) => setFeedback({...feedback, image: img}))
        .catch(e => console.log(e));

    const handleHide = () => {
        setShowConfirmation(false);
        props.onHide();
    }

    const handleSubmit = () => axios({
        method: "post",
        withCredentials: true,
        url: `${process.env.REACT_APP_BACKEND_URL}/feedback`,
        data: JSON.stringify(feedback),
        headers: { "Content-Type": "application/json" },
    })
        .then(() => {
            setShowConfirmation(true);
            setFeedback({});
        })
        .catch(e => console.log(e));

    // console.log(feedback);

    return (
        <Modal
            show={props.show}
            onHide={handleHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            { showConfirmation ?
            <div>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Feedback Received
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <div className='FeedbackModal-h2'>Thank you for your feedback! If you provided an email address to receive a response, we will come back to you as soon as possible.</div>
                </Modal.Body>
            </div>
            :
            <>
            <Modal.Header closeButton>
                <Modal.Title className="FeedbackModal-h1" id="contained-modal-title-vcenter">
                    Improve OilOnShirt
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 className="FeedbackModal-h2">Every single message will be read. Please share your feedback!</h2>

                <Form.Select
                    onChange={changeFeedback("type")}
                    value={feedback.type}>
                    <option value="">Select Feedback Type</option>
                    <option value="issue">Report Issue</option>
                    <option value="suggestion">Suggestion</option>
                    <option value="question">Question</option>
                </Form.Select>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <br />
                    <Form.Control
                        placeholder="Share your feedback here"
                        as="textarea"
                        rows={3}
                        onChange={changeFeedback("msg")}
                        value={feedback.msg}/>
                </Form.Group>

                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    onChange={changeFeedback("email")}
                    value={feedback.email}/>
                <Form.Text className="text-muted">
                Share your email address if you wish to receive a response. Otherwise leave blank.
                </Form.Text>

                <Form.Group controlId="formFileSm" className="mb-3">
                    <br />
                    <Form.Label className="FeedbackModal-h1">Add Screenshot</Form.Label>
                    <Form.Control type="file" size="sm" onChange={changeScreenshot}/>
                </Form.Group>

            </Modal.Body>

            <Modal.Footer>
                <Button size="" variant="" className='buttonStyle-lavender'
                        onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
            </>
            }
        </Modal>
    );
}

const SCREENSHOT_DIM = 1024;

const resizeScreenshot = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        SCREENSHOT_DIM,
        SCREENSHOT_DIM,
        "JPEG",
        97,
        0,
        (uri) => { resolve(uri); },
        "base64"
    );
});