import { Row, Col } from 'react-bootstrap';
import './FlipContainer.css';

export function FlipContainerRotate (props) {
    return (
        <Row>
            <div className='flip-container-layout'>
                <div className='flip-container'>
                    <div className="bg-transparent img-container">
                        <img className='before-flip flip-product-img rotate-animate' src={props.imageBefore} fluid/>
                        <img className='after-flip flip-product-img'src={props.imageAfter} fluid/>
                    </div>
                </div>
            </div>
        </Row>
    )
}

export function FlipContainerSwap (props) {
    return (
        <div className="swap-container">
            <img className="hover-zoom-light swap-before-img" src={props.imageBefore} />
            <img className="hover-zoom-light swap-after-img" src={props.imageAfter} />
        </div>
    )
}
