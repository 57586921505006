import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Button  } from 'react-bootstrap';
import TShirt from '@oos/core/components/TShirt';
import StudioCanvas, { THEME } from './StudioCanvas.js';
import { RingCircle } from '@oos/core/masks';
import { arrowCounterClockSVG, arrowClockSVG, addTextSVG, magicTshirtSVG, magicSVG, heartSVG, checkSVG, copySVG } from '../components/SVGs';
import ImageUpload from '../components/ImageUpload';
import SelectProductType from '../components/SelectProductType';
import AddToCartButton from '../components/AddToCartButton';
import { addToCart } from '../contexts/ShopContext.js';
import { useShop } from '../contexts/ShopContext.js';
import { useStudio } from '../contexts/StudioContext';
import { ColourPicker } from '../components/ColourPicker';
import { canvas_url, BASES_BY_ID, DARK_COLOURS } from '../const.js';
import _ from "lodash";
import axios from 'axios';
import { FavouritesPopup } from '../components/Favourites';
import { Link } from 'react-router-dom';

const TSHIRT_CANVAS = [1575, 2100]
const TSHIRT_MASK = new RingCircle(0.5 * TSHIRT_CANVAS[0], 0.36175 * TSHIRT_CANVAS[1], 0.738 * TSHIRT_CANVAS[0]);

const colourTheme = (colour) => DARK_COLOURS.has(colour) ? THEME.dark : THEME.light;

const SAVED = {
    unsaved: 'unsaved',
    saving: 'saving',
    saved: 'saved',
};

export default function StudioMain(props) {
    const { studio, dispatch } = useStudio();
    const shopContext = useShop();

    const base = BASES_BY_ID[studio.base];
    const baseProduct = shopContext.shop.bases[base.id];
    const colours = Object.keys(baseProduct.colours);
    const colour = studio.colour || colours[0];
    const backPrint = studio.backPrint;

    const [showAddToCart, setShowAddToCart] = useState(false);
    const [showSelectBase, setShowSelectBase] = useState(false);
    const [showFavourites, setShowFavourites] = useState(false);
    const [saved, setSaved] = useState(SAVED.unsaved);

    const navigate = useNavigate();

    const canvas = backPrint ? base.back : base.front;
    const canvasImage = canvas_url(base.id, colour, backPrint);
    const layers = backPrint ? studio.back : studio.front;
    const canvasRef = React.useRef(null);
    const noDesign = studio.back.length + studio.front.length == 0;
    const canvasActive = !(showAddToCart || showFavourites || showSelectBase);

    const handleTool = () => {
        navigate("AiTool");
        window.dataLayer.push({
            'event': 'OpenAiTool',
        });
        console.log('OpenAiTool');
    }

    const handleSlogan = () => {
        navigate("AiSlogan");
        window.dataLayer.push({
            'event': 'OpenAiSlogan',
        });
        console.log('OpenAiSlogan');
    }

    const handleMyDesigns = () => {
        navigate("/favourites");
        window.dataLayer.push({
            'event': 'VisitFavourites',
        });
        console.log('VisitFavourites');
    }

    const changeColour = (colour) => {
        dispatch({ type: 'CHANGE_COLOUR', payload: colour });
        setSaved(SAVED.unsaved);
    };

    const checkDuplicate = () => {
        const noId = !studio.id;
        const inCart = shopContext.shop.cart.findIndex((i) => i.id == studio.id) >= 0;
        return noId || inCart;
    };

    const createProduct = (duplicate) => {
        // TODO warn/stop when editing in-cart products?
        console.log("saving product")
        setSaved(SAVED.saving);
        const product = {
            base,
            colour,
            front: studio.front.map(l => _.omit(l, 'image')),
            back: studio.back.map(l => _.omit(l, 'image')),
        };
        const route = duplicate || checkDuplicate() ? "products" : `products/${studio.id}`;
        return axios({
            method: "post",
            withCredentials: true,
            url: `${process.env.REACT_APP_BACKEND_URL}/${route}`,
            data: JSON.stringify(product),
            headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
            const product = res.data;
            dispatch({ type: 'SET_ID', payload: product.id });
            shopContext.dispatch({ type: 'ADD_PRODUCT', payload: product });
            setSaved(SAVED.saved);
            return product.id;
        })
    };

    const saveProduct = () => createProduct(false).catch((e) => console.log(e));  // TODO handle errors?
    const dupeProduct = () => createProduct(true).catch((e) => console.log(e));  // TODO handle errors?

    const handleAddToCart = (size) => createProduct(false)
        .then(id => addToCart(shopContext, id, colour, size))
        .catch((e) => console.log(e));  // TODO handle errors?

    const flipSide = () => dispatch({ type: 'FLIP_SIDE' });

    const handleChange = (change) => {
        const side = backPrint ? 'back' : 'front';
        dispatch({ type: 'CHANGE_LAYER', payload: {...change, side} });
        setSaved(SAVED.unsaved);
    };

    const handleDelete = (layer) => {
        const side = backPrint ? 'back' : 'front';
        dispatch({ type: 'REMOVE_LAYER', payload: {side, layer} });
        setSaved(SAVED.unsaved);
    }

    const switchSave = () => {
        switch (saved) {
            case SAVED.unsaved: return "Save";
            case SAVED.saving: return "Saving...";
            case SAVED.saved: return "Saved";
        };
    };

    const switchDuplicate = () => {
        switch (saved) {
            case SAVED.saving: return "Saving...";
            default: return "Save Copy";
        };
    };

    return (
        <>
            <Row className='mx-2'>
                <Col xs={12} md={8}>
                    <TShirt image={canvasImage} canvas={canvas}>
                        {layers.length ?
                            <StudioCanvas layers={layers} canvas={canvas} theme={colourTheme(colour)}
                                canvasRef={canvasRef} active={canvasActive}
                                onChange={handleChange} onDelete={handleDelete} />
                            : <Button variant="" as={Link} to="/studio/AiTool">{magicTshirtSVG}</Button>
                        }
                    </TShirt>
                </Col>
                <Col className='Studio-options my-4'>
                    <Button variant="" className='mx-1 my-2 buttonStyle-accent' onClick={handleTool}>{magicSVG} Create AI Design</Button>
                    {/* <Button variant="" className='mx-2 my-2 buttonStyle-accent' onClick={handleSlogan}>{addTextSVG} Create Slogan</Button> */}
                    <FavouritesPopup show={showFavourites} setShow={setShowFavourites} />
                    {/* <ImageUpload /> */}
                    <div style={{height:"3vh"}}></div>
                    <SelectProductType show={showSelectBase} setShow={setShowSelectBase} />
                    <div className='Studio-product-title'>{baseProduct.title}</div>
                    <ColourPicker colours={colours} onSelect={changeColour} selected={colour}/>
                    <br />
                    <Button variant="" className='mx-1 my-2 buttonStyle-lavender' onClick={flipSide}>
                        {backPrint ? <>{arrowClockSVG} Front Print</> : <>{arrowCounterClockSVG} Back Print</>}
                    </Button>
                    <div style={{height:"3vh"}} />
                    { !noDesign && <>
                        <Button variant='' disabled={saved != SAVED.unsaved} onClick={saveProduct}
                            className="mx-1 my-2 buttonStyle-lavender">
                            {heartSVG} {switchSave()}
                        </Button>
                        <Button variant='' disabled={saved == SAVED.saving} onClick={dupeProduct}
                            className="mx-1 my-2 buttonStyle-lavender">
                            {copySVG} {switchDuplicate()}
                        </Button>
                        {baseProduct && <AddToCartButton product={baseProduct} colour={colour} onSave={handleAddToCart}
                            show={showAddToCart} setShow={setShowAddToCart} />}
                    </>}
                </Col>
            </Row>
        </>
    )
}